import { IQrcodeValueParsed } from '../models/IQrCode-Inventory.model';

export const stringParser = (data: string): IQrcodeValueParsed => {
  try {
    const capa = data.indexOf('Empresa:');
    if (capa === 0) {
      //Pegar valor da companhia
      const company = data.split('Empresa:');
      const valueCompany = company[1].split('Codigo:');
      const companyValue = valueCompany[0].trim();
      //Pegar valor do código
      const code = data.split('Codigo: ');
      const [codeValue] = code[1].split(' ');
      //Pegar descrição
      const description = data.split('Descricao: ');
      const [descriptionValue] = description[1].split('Matricula');
      //Pegar matricula
      const [, registration] = data.split('Matricula: ');
      const [registrationValue] = registration.split(' ');
      //Pegar lote
      const [, lot] = data.split('Lote: ');
      const [lotValue] = lot.split(' ');
      //Pegar acondicionamento
      const [, acond] = data.split('Acond: ');
      const [acondValue] = acond.split(' ');
      //Pegar quantidade
      const [, qunatity] = data.split('Quantidade: ');
      const [quantityValue] = qunatity.split(' ');
      //Pegar unidade
      const [, unity] = data.split('Unidade: ');
      const [unityValue] = unity.split(' ');
      //Pegar manual
      const [, manual] = data.split('Manual de Operacao: ');
      const [manualValue] = manual.split(' ');
      //Pegar ficha
      const [, ficha] = data.split('Ficha Tecnica: ');
      const obj = {
        productCode: codeValue,
        productDescription: descriptionValue.trim(),
        registration: registrationValue,
        lot: lotValue,
        packageId: acondValue,
        quantity: quantityValue.replace(',00', '').replace('.', ''),
        unity: unityValue,
        stickerNumber: ficha,
      };

      return removeLine(obj);
    } else {
      //Pegar valor do código
      const code = data.split('Codigo: ');
      const [codeValue] = code[1].split(' ');
      //Pegar descrição
      const description = data.split('Descricao: ');
      const [descriptionValue] = description[1].split('Produto');
      //Pegar produto
      const product = data.split('Produto: ');
      const [productValue] = product[1].split('Lote');
      //Pegar lote
      const lot = data.split('Lote: ');
      const [lotValue] = lot[1].split('OP');
      //Pegar OP
      const op = data.split('OP: ');
      const [opValue] = op[1].split('Quantidade');
      //Pegar quantidade
      const quantity = data.split('Quantidade: ');
      const [quantityValue] = quantity[1].split('Unidade');
      //Pegar unidade
      const unity = data.split('Unidade: ');
      const [unityValue] = unity[1].split(' ');

      const obj = {
        productCode: codeValue,
        productDescription: descriptionValue.trim(),
        product: productValue.trim(),
        lot: lotValue.trim(),
        op: opValue.trim(),
        quantity: quantityValue.trim(),
        unity: unityValue,
      };
      return removeLine(obj);
    }
  } catch (error) {
    throw new Error('Invalid qrcode value');
  }
};

export const removeLine = (obj: IQrcodeValueParsed): IQrcodeValueParsed => {
  for (const prop of Object.keys(obj)) {
    obj[prop] = obj[prop] === '-' ? null : obj[prop];
  }
  return obj;
};
