import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ProductModalComponent } from 'src/app/components/product-modal/product-modal.component';
import { ProductVelocityModalComponent } from 'src/app/components/product-velocity-modal/product-velocity-modal.component';
import { ProductsService } from 'src/app/services/products/products.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { SubstepsService } from 'src/app/services/substep/substep.service';
import { ParameterAssociateModalComponent } from '../../../../components/parameter-associate-modal/parameter-associate-modal.component';
import { MachineModel } from '../../../../models/machine.model';
import { ISubstep } from './../../../../models/ISubstep';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.page.html',
  styleUrls: ['./recipes.page.scss'],
})
export class RecipesPage {
  product: any;
  recipes: any;
  subSteps: ISubstep[];
  isLoading: boolean = true;
  machines: MachineModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public productsService: ProductsService,
    public modalController: ModalController,
    public alertController: AlertController,
    public toastController: ToastController,
    public substepsService: SubstepsService,
    public stepsService: StepsService,
  ) {
    route.params.subscribe((val) => {
      this.onInit();
    });
  }

  async onInit() {
    this.isLoading = true;
    await this.getProductById();
    await this.getProductRecipes();
    await this.getSubsteps();
    await this.getMachines();
    this.isLoading = false;
  }

  private getProductId() {
    return parseInt(this.route.snapshot.paramMap.get('id'));
  }

  async getProductById() {
    this.product = await this.productsService.getProductById(this.getProductId());
  }

  async getProductRecipes() {
    this.recipes = await this.productsService.getRecipes(this.getProductId());
  }
  async getSubsteps() {
    this.subSteps = await this.productsService.getSubsteps(this.getProductId());
  }

  async getMachines() {
    this.machines = await this.productsService.getProductMachines(this.getProductId());
  }
  openSubstepsProducts(productId: number, substepId: number) {
    this.router.navigate([`menu/config/steps/products/${productId}/substeps/${substepId}`]);
  }

  openProductsAvailable() {
    this.router.navigate([`menu/config/steps/products/${this.getProductId()}/newrecipe`]);
  }

  openAddNewProductSubstepsAvailable() {
    this.router.navigate([`menu/config/steps/products/${this.getProductId()}/substeps?available=true`]);
  }

  async openModal(component: any, breakPoint: number, props: any = {}) {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: component,
      initialBreakpoint: breakPoint,
      componentProps: props,
    });
    await modal.present();

    await modal.onDidDismiss();
    await this.getProductRecipes();
    await this.getSubsteps();
  }

  async openModalToEditProduct(productId: number) {
    const productsInformation: Product = await this.productsService.getProductById(productId);
    await this.openModal(ProductModalComponent, 0.9, { productsPayloadToSendEdit: productsInformation });
  }

  async openCreateVelocityModal() {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: ProductVelocityModalComponent,
      componentProps: {
        stepId: this.product.step.id,
        productId: this.product.id,
      },
    });
    await modal.present();

    const client = await modal.onDidDismiss();
    if (client.data === true) this.getProductRecipes();

    this.getMachines();

    return client;
  }

  async presentAlertRemoveOrDisassociate(id: number, parentProductId) {
    const alert = await this.alertController.create({
      header: 'Deseja deletar ou desassociar esse produto?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Remover',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.deleteProduct(id, parentProductId);
          },
        },
        {
          text: 'Desassociar',
          cssClass: 'alert-button-confirm',
          id: 'alert-button-confirm',
          handler: () => {
            this.disassociateProduct(id, parentProductId);
          },
        },
      ],
    });

    await alert.present();
  }
  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
    });
    toast.present();
  }

  async deleteProduct(id: number, parentProductId: number) {
    try {
      await this.productsService.deleteProducts(id, parentProductId);
      this.getProductRecipes();
      this.presentToast('Produto deletado com sucesso', 'success');
    } catch (err) {
      this.presentToast(err?.error?.message || 'Erro ao deletar produto', 'danger');
    }
  }

  async disassociateProduct(subProductId: number, productId: number) {
    try {
      await this.productsService.disassociateProducts(productId, subProductId);
      this.getProductRecipes();
      this.presentToast('Produto desassociado com sucesso', 'success');
    } catch (err) {
      this.presentToast(err?.error?.message || 'Erro ao desassociado produto', 'danger');
    }
  }

  async presentAlertExcluirResourceSpeed(productId: number, machineId: number) {
    const alert = await this.alertController.create({
      header: 'Deseja realmente excluir essa velocidade?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Excluir',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.deleteSpeed(productId, machineId);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteSpeed(productId: number, machineId: number) {
    try {
      await this.productsService.deleteSpeed(productId, machineId);
      this.getMachines();
      this.presentToast('Velocidade excluída com sucesso', 'success');
    } catch (err) {
      this.presentToast(err?.error?.message || 'Erro ao excluir velocidade', 'danger');
    }
  }

  async presentAlertRemoveOrDisassociateSubStep(productId: number, substepId: number) {
    const alert = await this.alertController.create({
      header: 'Deseja remover ou desassociar?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Remover',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.deleteSubSteps(substepId);
          },
        },
        {
          text: 'Desassociar',
          cssClass: 'alert-button-confirm',
          id: 'alert-button-confirm',
          handler: () => {
            this.disassociateSubSteps(productId, substepId);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteSubSteps(id: number) {
    try {
      await this.substepsService.deleteSubSteps(id);
      await this.getSubsteps();
      this.presentToast('Substep removida com sucesso', 'success');
    } catch (err) {
      this.presentToast(err?.error?.message || 'Erro ao remover substep', 'danger');
    }
  }

  async disassociateSubSteps(productId: number, substepId: number) {
    try {
      await this.stepsService.deleteProductSubSteps(productId, substepId);
      await this.getSubsteps();
      this.presentToast('Substep desassociada com sucesso', 'success');
    } catch (err) {
      this.presentToast(err?.error?.message || 'Erro ao desassociar substep', 'danger');
    }
  }

  async openModalToEditSubStep(substepId) {
    const productId = this.getProductId();
    await this.openModal(ParameterAssociateModalComponent, 0.9, { substepId: substepId, productId: productId });
  }
}
