import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { ProductModalComponent } from 'src/app/components/product-modal/product-modal.component';
import { Product } from 'src/app/models/product.model';
import { StepsService } from 'src/app/services/steps/steps.service';

@Component({
  selector: 'app-add-new-product',
  templateUrl: './add-new-product.page.html',
  styleUrls: ['./add-new-product.page.scss'],
})
export class AddNewProductPage {
  addNewProduct: boolean = true;
  products: Product[];
  productId: number;
  isLoading: boolean;
  newProducts: Product[];

  constructor(
    public stepsService: StepsService,
    public router: Router,
    public toastController: ToastController,
    public modalController: ModalController,
    private route: ActivatedRoute,
  ) {
    this.productId = Number(this.route.snapshot.paramMap.get('id'));
    route.params.subscribe((val) => {
      this.newProducts = [];
      this.onInit();
    });
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
    });
    toast.present();
  }

  async onInit() {
    this.isLoading = true;
    await this.loadProductsAvailable();
    this.isLoading = false;
  }

  AddProductOnListOfNewProduct(e) {
    if (e.add) {
      this.newProducts.push(e.product);
    } else {
      this.newProducts.splice(
        this.newProducts.indexOf(this.newProducts.find((product) => product.id == e.product.id)),
        1,
      );
    }
  }

  async patchProducts() {
    const arrayOfId: number[] = [];
    this.newProducts.forEach((product) => arrayOfId.push(product.id));
    const response: any = await this.stepsService.patchProductsAvailable(this.productId, arrayOfId);
    if (response.message === 'Produto associado com sucesso') {
      this.router.navigate([`menu/config/steps/products/${this.productId}`]);
      this.presentToast(response.message, 'success');
    } else {
      this.presentToast('Erro ao Associar Produto', 'danger');
    }
  }

  async loadProductsAvailable() {
    this.products = await this.stepsService.getProductsAvailable(this.productId);
  }

  async openModal(component: any, breakPoint: number, props: any = {}) {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: component,
      initialBreakpoint: breakPoint,
      componentProps: props,
    });
    await modal.present();

    const client = await modal.onDidDismiss();

    return client;
  }

  async openModalToCreateANewProduct() {
    await this.openModal(ProductModalComponent, 0.9, { parentId: this.productId });
  }
}
