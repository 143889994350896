import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BackendConfig } from '../config/backend-config';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  public postSubstepFile(file: File, fileName: string): Promise<{ link: string }> {
    const formData = new FormData();
    formData.append('file', file, fileName);

    return lastValueFrom(
      this.http
        .post<{ link: string }>(BackendConfig.postSubstepFile(), formData, {
          headers: new HttpHeaders({
            Authorization: BackendConfig.token(),
          }),
        })
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
}
