import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { AlertController } from '@ionic/angular';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';

@Component({
  selector: 'app-slice-form',
  templateUrl: './slice-form.page.html',
  styleUrls: ['./slice-form.page.scss'],
})
export class SliceFormPage implements OnInit {
  @Input() productionOrderId: any;
  sliceForm: FormGroup;
  productionOrderSlice: any;
  user: any;
  slicesRequestAmount: number;
  slicesTotalLength: number;
  slicesLength: number;
  slicesDisapproved: number;

  constructor(
    private modalController: ModalController,
    private productionOrdersService: ProductionOrdersService,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private authService: AuthenticateService,
  ) {
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.productionOrderSlice = {
        quantity: '1',
        userId: this.user.email,
      };
      this.sliceForm.controls['userId'].setValue(this.user.email);
      this.sliceForm.controls['quantity'].setValue(this.productionOrderSlice.quantity);
    });
  }

  ngOnInit() {
    this.sliceForm = this.formBuilder.group({
      quantity: new FormControl(null, [Validators.required]),
      userId: new FormControl(null, [Validators.required]),
    });

    this.productionOrdersService.get(this.productionOrderId).subscribe((productionOrder) => {
      this.slicesRequestAmount = productionOrder.slicesRequest.amount;
      this.slicesTotalLength = productionOrder.slices.total;
      this.slicesDisapproved = productionOrder.disapprovedDrops.length;
      const quantityValidation = this.sliceForm.get('quantity');

      quantityValidation.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(productionOrder.slices.pending),
      ]);
      quantityValidation.updateValueAndValidity();
    });
  }

  closeModal(productionOrderId) {
    this.modalController.dismiss(productionOrderId);
  }

  async messageModal(title: string, message: string, handle: any) {
    const messageAlert = await this.alertCtrl.create({
      header: title,
      message,
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            if (handle) {
              handle();
            }
          },
        },
      ],
    });
    await messageAlert.present();
  }

  submit(slice: any) {
    this.productionOrdersService.addSlice(this.productionOrderId, slice).subscribe(
      (data) => {
        this.closeModal(this.productionOrderId);
      },
      (error) => {
        this.messageModal('Erro!', error.error.message, null);
      },
    );
  }
}
