/* eslint-disable max-len */
export const dropLabelMockNotVivo = {
  drop: {
    createdOn: '2021-02-24T12:17:19.415Z',
    internalLot: 'DP-01',
    netWeight: 20.580000000000002,
    grossWeight: 21.707,
  },
  product: {
    anatelId: '03183-17-03221',
    qrcode:
      'MPT Fios e Cabos Especiais S.A.\nDescrição: CABO OPTICO DROP CFOAC-BLI-A/B-CM-01-AR-LSZH CZ VIVO (CX)\nCódigo SAP: 504010060400015800\nManual de Operação: https://bit.ly/37AKa5R\nFicha Técnica: https://bit.ly/3bMKCB0',
    model: 'CFOAC-BLI-A/B-CM-04-AR-LSZH',
    barcode: '7898920131347',
  },
  productionOrder: {
    id: 50,
    length: 1000,
    client: 'ALGAR TELECOM',
    clientSAPCode: '1234',
    externalCode: 'op-drop-11-lance',
    purchaseOrder: '123',
    SAPCode: '123',
  },
  pallet: {
    internalLot: 'pallet-lot-1',
  },
};

export const dropLabelMockNotVivoDisapproved = {
  drop: {
    createdOn: '2021-02-24T12:17:19.415Z',
    internalLot: 'DP-01',
    netWeight: 20.580000000000002,
    grossWeight: 21.707,
    disapproveObservation: 'reprovado',
  },
  product: {
    anatelId: '03183-17-03221',
    qrcode:
      'MPT Fios e Cabos Especiais S.A.\nDescrição: CABO OPTICO DROP CFOAC-BLI-A/B-CM-01-AR-LSZH CZ VIVO (CX)\nCódigo SAP: 504010060400015800\nManual de Operação: https://bit.ly/37AKa5R\nFicha Técnica: https://bit.ly/3bMKCB0',
    model: 'CFOAC-BLI-A/B-CM-04-AR-LSZH',
    barcode: '7898920131347',
  },
  productionOrder: {
    id: 50,
    length: 1000,
    client: 'ALGAR TELECOM',
    clientSAPCode: '1234',
    externalCode: 'op-drop-11-lance',
    purchaseOrder: '123',
    SAPCode: '123',
  },
  pallet: {
    internalLot: 'pallet-lot-1',
  },
};

export const dropLabelMockVivo = {
  drop: {
    createdOn: '2021-02-24T12:17:19.415Z',
    internalLot: 'DP-01',
    netWeight: 20.580000000000002,
    grossWeight: 21.707,
  },
  product: {
    anatelId: '03183-17-03221',
    qrcode:
      'MPT Fios e Cabos Especiais S.A.\nDescrição: CABO OPTICO DROP CFOAC-BLI-A/B-CM-01-AR-LSZH CZ VIVO (CX)\nCódigo SAP: 504010060400015800\nManual de Operação: https://bit.ly/37AKa5R\nFicha Técnica: https://bit.ly/3bMKCB0',
    model: 'CFOAC-BLI-A/B-CM-04-AR-LSZH',
    barcode: '7898920131347',
  },
  productionOrder: {
    id: 50,
    length: 1000,
    client: 'vivo',
    clientSAPCode: '1234',
    externalCode: 'op-drop-11-lance',
    purchaseOrder: '123',
    SAPCode: '123',
  },
  pallet: { internalLot: 'pallet-lot-1' },
};

export const dropLabelMockVivoDisapproved = {
  drop: {
    createdOn: '2021-02-24T12:17:19.415Z',
    internalLot: 'DP-01',
    netWeight: 20.580000000000002,
    grossWeight: 21.707,
    disapproveObservation: 'reprovado',
  },
  product: {
    anatelId: '03183-17-03221',
    qrcode:
      'MPT Fios e Cabos Especiais S.A.\nDescrição: CABO OPTICO DROP CFOAC-BLI-A/B-CM-01-AR-LSZH CZ VIVO (CX)\nCódigo SAP: 504010060400015800\nManual de Operação: https://bit.ly/37AKa5R\nFicha Técnica: https://bit.ly/3bMKCB0',
    model: 'CFOAC-BLI-A/B-CM-04-AR-LSZH',
    barcode: '7898920131347',
  },
  productionOrder: {
    id: 50,
    length: 1000,
    client: 'vivo',
    clientSAPCode: '1234',
    externalCode: 'op-drop-11-lance',
    purchaseOrder: '123',
    SAPCode: '123',
  },
  pallet: { internalLot: 'pallet-lot-1' },
};
