import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { MachinesService } from 'src/app/services/machines/machines.service';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MachineModel } from 'src/app/models/machine.model';

@Component({
  selector: 'app-paint-form',
  templateUrl: './paint-form.page.html',
  styleUrls: ['./paint-form.page.scss'],
})
export class PaintFormPage implements OnInit {
  machines: MachineModel[];
  @Input() fiber: any;
  productionOrderId;
  statusPaint: string;
  paintForm: FormGroup;
  user: any;

  constructor(
    public modalController: ModalController,
    public machinesService: MachinesService,
    private productionOrdersService: ProductionOrdersService,
    private alertCtrl: AlertController,
    private authService: AuthenticateService,
  ) {
    this.statusPaint = 'Aprovada';
  }

  async ngOnInit() {
    this.paintForm = new FormGroup({
      inkLot: new FormControl(null, [Validators.required]),
      paintingLot: new FormControl(null, [Validators.required]),
      otdrLength: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(this.fiber?.availableLength),
      ]),
      attenuation131: new FormControl(null, [Validators.required, Validators.min(0.3), Validators.max(0.37)]),
      attenuation155: new FormControl(null, [Validators.required, Validators.min(0.15), Validators.max(0.25)]),
      userId: new FormControl(null, [Validators.required]),
      machineId: new FormControl(null, [Validators.required]),
      disapproveObservation: new FormControl(''),
    });
    this.machines = await this.machinesService.list(1);
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.paintForm.controls['userId'].setValue(this.user.email);
    });
    this.paintForm.controls['machineId'].valueChanges.subscribe(async (value) => {
      if (value) {
        const automatic = await this.productionOrdersService.getPaintingAutomaticLot(
          this.productionOrderId,
          this.fiber.id,
          value,
        );
        this.paintForm.controls['paintingLot'].setValue(automatic.lot as any);
      }
    });
  }

  async duplicatedPaintingLotAlert() {
    const duplicatedPaintingLotAlert = await this.alertCtrl.create({
      header: 'Atençao!',
      message: 'Lote de pintura duplicado',
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          cssClass: 'secondary',
        },
      ],
    });
    await duplicatedPaintingLotAlert.present();
  }

  addPainting(fiber: any, paintDetails: any) {
    this.productionOrdersService.addPainting(this.productionOrderId, fiber, paintDetails).subscribe(
      (_) => {
        this.closeModal(paintDetails);
      },
      (error) => {
        if (error.status === 409) {
          this.duplicatedPaintingLotAlert();
        }
      },
    );
  }

  submit(paintDetails) {
    this.addPainting(this.fiber, paintDetails);
  }

  closeModal(paintDetails) {
    this.modalController.dismiss(paintDetails);
  }

  setDissaproveObservationValidation() {
    const disapproveObservation = this.paintForm.get('disapproveObservation');
    const inkLot = this.paintForm.get('inkLot');
    const paintingLot = this.paintForm.get('paintingLot');
    const attenuation131 = this.paintForm.get('attenuation131');
    const attenuation155 = this.paintForm.get('attenuation155');

    if (this.statusPaint === 'Aprovada') {
      disapproveObservation.setValidators(null);
    } else {
      this.statusPaint = 'Não Aprovada';

      disapproveObservation.setValidators([Validators.required]);
      inkLot.setValidators(null);
      paintingLot.setValidators(null);
      attenuation131.setValidators([Validators.min(0.3), Validators.max(0.37)]);
      attenuation155.setValidators([Validators.min(0.17), Validators.max(0.25)]);
    }

    disapproveObservation.updateValueAndValidity();
    inkLot.updateValueAndValidity();
    paintingLot.updateValueAndValidity();
    attenuation131.updateValueAndValidity();
    attenuation155.updateValueAndValidity();
  }
}
