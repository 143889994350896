import { Component, OnInit, Input } from '@angular/core';
import { FibersService } from 'src/app/services/fibers/fibers.service';
import { ModalController } from '@ionic/angular';
import { Item } from 'src/app/models/item.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fiber-list',
  templateUrl: './fiber-list.page.html',
  styleUrls: ['./fiber-list.page.scss'],
})
export class FiberListPage implements OnInit {
  fibers: any;
  productionOrderId: number;
  @Input() productionOrderType: any;
  fiberSelectedForPainting: Item[];
  isLoading: boolean;
  totalLengthSelectedFibers: number;

  constructor(private router: Router, private modalController: ModalController, public fiberService: FibersService) {
    this.isLoading = true;
    this.totalLengthSelectedFibers = 0;
  }

  ngOnInit() {
    this.fiberService.list(this.productionOrderType.id).subscribe((fibers) => (this.fibers = fibers));
    this.fiberSelectedForPainting = new Array<Item>();
    this.isLoading = false;
  }

  submit() {
    this.fiberService.add(this.fiberSelectedForPainting, this.productionOrderId).subscribe((_) => {
      this.closeModal(this.productionOrderId);
    });
  }

  closeModal(productionOrderId) {
    this.modalController.dismiss(productionOrderId);
  }

  changeToggle(fiber: Item) {
    let positionFiberToRemove = -1;
    this.fiberSelectedForPainting.filter((fiberSelected, index) => {
      if (fiberSelected.id === fiber.id && fiberSelected.length === fiber.length) {
        positionFiberToRemove = index;
        return true;
      }
      return false;
    });

    if (positionFiberToRemove > -1) {
      this.totalLengthSelectedFibers -= fiber.length;
      this.fiberSelectedForPainting.splice(positionFiberToRemove, 1);
    } else {
      this.totalLengthSelectedFibers += fiber.length;
      this.fiberSelectedForPainting.push(fiber);
    }
  }
}
