import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IProductStatus } from 'src/app/models/IProductStatus';
import { ISubstep } from 'src/app/models/ISubstep';
import { ISubstepProducts } from 'src/app/models/ISubstepsProducts';
import { MachineModel } from 'src/app/models/machine.model';
import { ISearchItem, ISearchReportDetails } from 'src/app/models/search-item';
import { IStepTypes } from 'src/app/pages/config/steps/step-modal/IStepTypes';
import { BackendConfig } from '../config/backend-config';
import { CreateStepMachineProps, IStep, IStepType } from './../../models/step.model';
import { Product } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class StepsService {
  constructor(private http: HttpClient) {}

  async createStep(step: IStep): Promise<any> {
    const stepResponse = await lastValueFrom(
      this.http
        .post(BackendConfig.createStep(), step, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return stepResponse;
  }
  async updateStep(step: IStep): Promise<any> {
    return await lastValueFrom(
      this.http
        .put(BackendConfig.updateStep(), step, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async deleteStep(stepId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete(BackendConfig.deleteStep(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async getStepDetails(stepId: number): Promise<IStep> {
    const step = await lastValueFrom(
      this.http
        .get(BackendConfig.getStepDetails(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return step as IStep;
  }

  async list(includeNaturalStep = false, isActive: boolean): Promise<IStep[]> {
    const steps = await lastValueFrom(
      this.http
        .get(BackendConfig.steps(includeNaturalStep, isActive), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return steps as IStep[];
  }

  async listTypes(): Promise<IStepTypes[]> {
    const types = await lastValueFrom(
      this.http
        .get(BackendConfig.listStepTypes(), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return types as IStepTypes[];
  }

  async listColors(): Promise<any> {
    const colors = await lastValueFrom(
      this.http.get(BackendConfig.colorsUrl(), BackendConfig.httpOptions()).pipe(catchError(BackendConfig.handleError)),
    );
    return colors as any;
  }
  async findItemByLot(stepId: string, lot: string): Promise<ISearchItem[]> {
    const items = await lastValueFrom(
      this.http.get(BackendConfig.stepItemByLot(stepId, lot), BackendConfig.httpOptions()).pipe(),
    );
    return items as ISearchItem[];
  }

  async findItemByInput(
    stepId: string,
    inputValue: string,
    inputId?: number,
    isReport: boolean = false,
  ): Promise<ISearchItem[] | ISearchReportDetails> {
    const items = await lastValueFrom(
      this.http
        .get<ISearchItem[] | ISearchReportDetails>(
          BackendConfig.stepItemByInput(stepId, inputValue, inputId, isReport),
          BackendConfig.httpOptions(),
        )
        .pipe(),
    );
    return items;
  }

  async getItemLot(stepId: string, item: string): Promise<any> {
    return await lastValueFrom(
      this.http.get(BackendConfig.getItemLot(stepId, item), BackendConfig.httpOptions()).pipe(),
    );
  }

  async getStepProducts(stepId: number): Promise<Product[]> {
    const product = await lastValueFrom(
      this.http
        .get(BackendConfig.getStepProducts(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return product as Product[];
  }

  async getProductStatus(productId: number): Promise<{ statuses: IProductStatus[] }> {
    const status = await lastValueFrom(
      this.http
        .get(BackendConfig.getProductStatus(productId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return status as { statuses: IProductStatus[] };
  }

  async getAllStepMachines(stepId: number): Promise<MachineModel[]> {
    const machine = await lastValueFrom(
      this.http
        .get(BackendConfig.getAllStepMachines(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return machine as MachineModel[];
  }

  async getProductsAvailable(productsId: number): Promise<Product[]> {
    const product: any = await lastValueFrom(
      this.http
        .get(BackendConfig.getProductsAvailable(productsId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return product;
  }

  async patchProductsAvailable(productsId: number, productsToPost: number[]): Promise<any> {
    return await lastValueFrom(
      this.http
        .patch<any>(BackendConfig.patchProductsAvailable(productsId), productsToPost, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async getProductsSubstepsById(productId: number, substepId: number): Promise<ISubstepProducts[]> {
    const substepProducts: ISubstepProducts[] = await lastValueFrom(
      this.http
        .get<ISubstepProducts[]>(
          BackendConfig.getProductsSubstepsById(productId, substepId),
          BackendConfig.httpOptions(),
        )
        .pipe(catchError(BackendConfig.handleError)),
    );
    return substepProducts;
  }
  async getSubstepsById(substepId: number): Promise<ISubstep> {
    const substep: ISubstep = await lastValueFrom(
      this.http
        .get<ISubstep>(BackendConfig.getSubstepsById(substepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return substep;
  }
  async getSubstepsInputById(productId: number, substepId: number, inputId: number): Promise<any> {
    const params = await lastValueFrom(
      this.http
        .get(BackendConfig.getSubstepsInputById(productId, substepId, inputId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return params as any;
  }
  async updateSubstepsInput(productId: number, substepId: number, inputId: number, payload: any): Promise<any> {
    return await lastValueFrom(
      this.http
        .put(BackendConfig.updateSubstepsInputs(productId, substepId, inputId), payload, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async getSubstepsAvailable(productId: number): Promise<IStepType[]> {
    const stepType = await lastValueFrom(
      this.http
        .get(BackendConfig.getSubstepAvailable(productId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return stepType as IStepType[];
  }

  async postSubsteps(productId: number, substepsId: number[]): Promise<any> {
    const stepResponse = await lastValueFrom(
      this.http
        .post(BackendConfig.postSubstep(productId), substepsId, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return stepResponse;
  }

  async deleteProductSubSteps(productId: number, substepId: number): Promise<any> {
    const deleteResponse = await lastValueFrom(
      this.http
        .delete(BackendConfig.deleteProductSubSteps(productId, substepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return deleteResponse;
  }

  async createStepMachine(data: CreateStepMachineProps): Promise<any> {
    const response = await lastValueFrom(
      this.http
        .post(BackendConfig.createStepMachine(), data, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return response;
  }

  async editStepMachine(id: number, data: MachineModel): Promise<any> {
    const response = await lastValueFrom(
      this.http
        .put(BackendConfig.editStepMachine(id), data, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return response;
  }
  async getStepInputs(stepId: number): Promise<any> {
    const response = await lastValueFrom(
      this.http
        .get(BackendConfig.getStepInputs(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return response;
  }
}
