import { RecipesPageModule } from './../recipes/recipes.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StepsPageRoutingModule } from './steps-routing.module';

import { StepsPage } from './steps.page';
import { StepModalPageModule } from '../step-modal/step-modal.module';
import { StepModalPage } from '../step-modal/step-modal.page';
import { StepDetailsPageModule } from '../step-details/step-details.module';
import { SubstepsPageModule } from './../substeps/substeps.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    StepsPageRoutingModule,
    StepModalPageModule,
    StepDetailsPageModule,
    RecipesPageModule,
    SubstepsPageModule,
  ],
  declarations: [StepsPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [StepModalPage],
})
export class StepsPageModule {}
