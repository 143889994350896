import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SubstepPageRoutingModule } from './substep-routing.module';

import { SubstepPage } from './substep.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, SubstepPageRoutingModule, ReactiveFormsModule, ComponentsModule],
  declarations: [SubstepPage],
})
export class SubstepPageModule {}
