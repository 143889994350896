import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { SubstepsService } from 'src/app/services/substep/substep.service';
import { StepsService } from '../../services/steps/steps.service';
import { IInputProps } from './substep-modal-model';

@Component({
  selector: 'app-substeps-modal',
  templateUrl: './substeps-modal.page.html',
  styleUrls: ['./substeps-modal.page.scss'],
})
export class SubstepsModalPage implements OnInit {
  input: IInputProps;
  inputForm: FormGroup;
  productId: number;
  substepId: number;
  inputId: number;
  title: string;

  constructor(
    private modalCtrl: ModalController,
    private stepsService: StepsService,
    public toastController: ToastController,
    private substepsService: SubstepsService,
  ) {}
  ngOnInit(): void {
    this.inputForm = new FormGroup({
      id: new FormControl(this.input?.id, [Validators.required]),
      description: new FormControl(this.input?.description, [Validators.required]),
      type: new FormControl(this.input?.type, [Validators.required]),
      unit: new FormControl(this.input?.unit, [Validators.required]),
      label: new FormControl(this.input?.label, [Validators.required]),
      placeholder: new FormControl(this.input?.placeholder, [Validators.required]),
      propertyName: new FormControl(this.input?.propertyName, [Validators.required]),
      config: new FormGroup(
        {
          isRequired: new FormControl(this.input?.config.isRequired, [Validators.required]),
          min: new FormControl(this.input?.config.min, [Validators.required]),
          max: new FormControl(this.input?.config.max, [Validators.required]),
          automaticvalueid: new FormControl(this.input?.config.automaticvalueid, [Validators.required]),
          isproductrecipeloop: new FormControl(this.input?.config.isproductrecipeloop, [Validators.required]),
          disapprovedrequired: new FormControl(this.input?.config.disapprovedrequired, [Validators.required]),
          x: new FormControl(this.input?.config.x, [Validators.required]),
          y: new FormControl(this.input?.config.y, [Validators.required]),
          isproductlength: new FormControl(this.input?.config.isproductlength, [Validators.required]),
          positioninlist: new FormControl(this.input?.config.positioninlist, [Validators.required]),
          isrequiredtrue: new FormControl(this.input?.config.isrequiredtrue, [Validators.required]),
          showinpredecessor: new FormControl(this.input?.config.showinpredecessor, [Validators.required]),
          mathematicalfunc: new FormControl(this.input?.config.mathematicalfunc, [Validators.required]),
          nonrequired500length: new FormControl(this.input?.config.nonrequired500length, [Validators.required]),
          nonrequiredwhenmorethan: new FormControl(this.input?.config.nonrequiredwhenmorethan, [Validators.required]),
          limittobeminmaxoverride: new FormControl(this.input?.config.limittobeminmaxoverride, [Validators.required]),
          minoverride: new FormControl(this.input?.config.minoverride, [Validators.required]),
          maxoverride: new FormControl(this.input?.config.maxoverride, [Validators.required]),
        },
        [Validators.required],
      ),
    });
  }
  async dismiss() {
    await this.modalCtrl.dismiss();
  }

  async submit() {
    try {
      if (this.input)
        await this.stepsService.updateSubstepsInput(this.productId, this.substepId, this.inputId, this.inputForm.value);

      if (!this.input) this.substepsService.postSubstepInput(this.productId, this.substepId, this.inputForm.value);

      this.openToast(`Input ${this.input ? 'atualizado' : 'criado'} com sucesso`, 'success');

      return this.dismiss();
    } catch (err) {
      this.openToast('Erro ao editar', 'danger');
    }
  }

  async openToast(message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      duration: 2000,
      position: 'top',
      message,
      color,
    });
    toast.present();
  }
}
