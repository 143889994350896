// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBdFEMC_0fmrTZbNn7NGkRgUIwU2zGf4DU',
    authDomain: 'mpt-princess.firebaseapp.com',
    databaseURL: 'https://mpt-princess.firebaseio.com',
    projectId: 'mpt-princess',
    storageBucket: 'mpt-princess.appspot.com',
    messagingSenderId: '737951282861',
  },
  ganon: {
    environment: 'development',
    ganonApp: 'rastreabilidade',
    ganonToken: '344e70084038f1945641c6c83043bfc7cfaa570ecb17045a788b3a240a7fd163',
    ganonUrl: 'https://ganondorf-stg.finderup.com.br',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export default environment;
