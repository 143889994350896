import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';
import { StockFibers } from 'src/app/models/stock-fiber.model';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  constructor(private http: HttpClient) {}

  list(fiberType: number, stepId: number): Observable<StockFibers> {
    if (stepId === 1) {
      return this.http
        .get<StockFibers>(BackendConfig.stockFiberUrl(fiberType), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError));
    } else if (stepId === 2) {
      return this.http
        .get<StockFibers>(BackendConfig.stockByStep(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError));
    }
  }

  get(colorId: number, fiberType: string): Observable<any> {
    return this.http
      .get<any>(BackendConfig.stockFiberDetailsUrl(colorId, fiberType), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getPaintingsAvailable(colorId: number, fiberType: string): Observable<any> {
    return this.http
      .get<any>(BackendConfig.stockPaintingsAvailableUrl(colorId, fiberType), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getGenericPaintingsAvailable(colorId: number, fiberType: string, sliceRequestLength: number): Observable<any> {
    return this.http
      .get<any>(
        BackendConfig.stockGenericPaintingsAvailableUrl(colorId, fiberType, sliceRequestLength),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }
  async removeOneFiber(fiberId: string): Promise<any> {
    return await this.http
      .delete(BackendConfig.removeOneFiberId(fiberId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError))
      .toPromise();
  }
}
