import { Directive, HostListener, Input } from '@angular/core';
@Directive({
  selector: 'button[appCustomPrint]',
})
export class CustomPrint {
  /**
   *
   *
   * @memberof CustomPrintDirective
   */

  @Input() printSectionId: string;
  @Input() printDescription: string;
  showSelectFiber = true;

  @HostListener('click')
  public print(): void {
    setTimeout(() => {
      if (
        this.printDescription.includes('Tubo Loose') ||
        this.printDescription.includes('Capa') ||
        this.printDescription.includes('SZ')
      ) {
        this.showSelectFiber = false;
      }
      const printContents = document.getElementById(this.printSectionId);
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
            <html>
                <head>
                <script type="module" src="https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.esm.js"></script>
                <script nomodule src="https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.js"></script>
                <style>
                    .no-print {
                        display: none !important;
                    }
                    .print-show {
                        display: show !important;
                    }
                    #logo {
                        width: 20%;
                        height: 20%;
                    }
                    #header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 10px;
                        text-align: center;
                        font-size: 20px;
                    }
                </style>
                </head>
                <body>
                <div id="header">
                    <img id="logo" src="../../assets/logo_print.png"/>
                    <p><strong>Ordem de produção </strong><br /> ${
                      this.showSelectFiber ? 'Seleção de Fibra Óptica' : ''
                    }</p>
                    <p><strong> Área </strong><br />${this.printDescription}</p>
                </div>
                ${printContents.innerHTML}
                <script defer>
                    function triggerPrint(event) {
                    window.removeEventListener('load', triggerPrint, false);
                    setTimeout(function() {
                        window.print();
                        setTimeout(function() { window.close(); }, 0);
                    }, 1000);
                    }
                    window.addEventListener('load', triggerPrint, false);
                </script>
                </body>
            </html>`);
      popupWin.document.close();
    }, 300);
  }
}
