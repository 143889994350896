import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ISubstepProducts } from 'src/app/models/ISubstepsProducts';
import { BackendConfig } from '../config/backend-config';

interface CreateSubstepsLinkWithProduct {
  id: number | null;
  description: string;
  index: number;
  isInitial: boolean;
  hasMachine: boolean;
  hasProductItems: Boolean;
  hasApproval: boolean;
  hasLoopCreation: boolean;
  productId: number;
  isNested: boolean;
  hasSliceCount: boolean;
  predecessorSubstepId: number;
}
@Injectable({ providedIn: 'root' })
export class ProductSubstepsParametersService {
  constructor(private http: HttpClient) {}

  async getProductsSubstepParameters(productId: number, substepId: number): Promise<ISubstepProducts[]> {
    const response: ISubstepProducts[] = await lastValueFrom(
      this.http
        .get<ISubstepProducts[]>(
          BackendConfig.getProductsSubstepsParameters(productId, substepId),
          BackendConfig.httpOptions(),
        )
        .pipe(catchError(BackendConfig.handleError)),
    );
    return response;
  }

  async getAvailableSubsteps(productId: number, substepId: number): Promise<ISubstepProducts[]> {
    const response: ISubstepProducts[] = await lastValueFrom(
      this.http
        .get<ISubstepProducts[]>(BackendConfig.getAvailableSubsteps(productId, substepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
    return response;
  }

  async createSubstepsLinkWithProduct(payload: CreateSubstepsLinkWithProduct): Promise<any> {
    const response: any = await lastValueFrom(
      this.http
        .post<any>(BackendConfig.createSubstepsLinkWithProduct(), payload, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return response;
  }

  async postProductsSubstepsParameters(
    productId: number,
    substepId: number,
    productSubstepsParametersSelected: Array<number>,
  ): Promise<any> {
    return await lastValueFrom(
      this.http
        .post<Array<number>>(
          BackendConfig.postProductsSubstepsParameters(productId, substepId),
          productSubstepsParametersSelected,
          BackendConfig.httpOptions(),
        )
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async getPredecessorSubsteps(id: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .get<any>(BackendConfig.getPredecessorSubsteps(id), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async getSubstepById(productId: number, substepId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .get<any>(BackendConfig.getSubstepById(productId, substepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async editSubstep(payload: any): Promise<any> {
    return await lastValueFrom(
      this.http
        .put<any>(BackendConfig.editSubstep(), payload, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async disassociateParameter(productId: number, substepId: number, inputId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete<any>(BackendConfig.disassociateParameter(productId, substepId, inputId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async removeParameter(inputId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete<any>(BackendConfig.removeParameter(inputId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
}
