import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-subproduct-range',
  templateUrl: './subproduct-range.component.html',
  styleUrls: ['./subproduct-range.component.scss'],
})
export class SubproductRangeComponent {
  rangeValues = { initial: 0, min: 50000, max: 55000 };
  payloadInfo = { min: 50000, max: 55000 };

  constructor(private modalController: ModalController) {}

  updateRange(event: any) {
    this.payloadInfo.min = event.target.value.lower;
    this.payloadInfo.max = event.target.value.upper;
  }

  submit() {
    return {
      rangeLengthFibers: {
        min: this.payloadInfo.min,
        max: this.payloadInfo.max,
      },
    };
  }

  closeModal(send: boolean) {
    if (send) {
      this.modalController.dismiss(this.submit()).catch((err) => {
        if (err.message === 'overlay does not exist trown') {
          return;
        }
      });
    } else {
      this.modalController.dismiss().catch((err) => {
        if (err.message === 'overlay does not exist trown') {
          return;
        }
      });
    }
  }
}
