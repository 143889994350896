import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryItem } from 'src/app/models/InventoryItem.model';
import { InventoryService } from 'src/app/services/inventory/invetory.service';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.page.html',
  styleUrls: ['./inventory-list.page.scss'],
})
export class InventoryListPage implements OnInit {
  inventoryOrder: Array<InventoryItem> = [];
  reload: boolean;

  constructor(private router: Router, private inventoryService: InventoryService) {}

  ngOnInit() {
    this.loadStockTakingOrders();
  }
  redirectToInventoryOrderPage() {
    this.router.navigate(['/menu/inventory-order']);
  }

  loadStockTakingOrders() {
    this.inventoryService.getInventoryList().subscribe((list) => {
      this.inventoryOrder = list;
    });
  }

  ionViewWillEnter() {
    this.loadStockTakingOrders();
  }
}
