import { AfterViewChecked, ApplicationRef, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { UploadService } from 'src/app/services/upload/upload.service';
import { NOT_ENOUGH_LENGTH } from 'src/app/utils/texts';
import { Substep } from '../../../models/substep-model';
import { AuthenticateService } from '../../../services/auth/authentication.service';
import { SubstepsService } from '../../../services/substep/substep.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-substep',
  templateUrl: './substep.page.html',
  styleUrls: ['./substep.page.scss'],
})
export class SubstepPage implements OnInit, AfterViewChecked {
  productionOrderId: number;
  substepId: number;
  productionOrderSubstepId: number;
  predecessorProductionOrderSubstep: number;
  showLoading = false;
  selectedPaintings: Array<any> = [];
  substepForm: FormGroup;
  substep: Substep;
  items: any;
  user: string;
  formKeys: Array<{
    propertyName: string;
    validators: Validators;
  }> = [];
  substepToPrint = false;
  disableSaveButton = false;
  activeTab = 0;
  productionOrder: any;
  substepToTag = false;
  substepToCapaTag = false;
  substepToDropTag = false;
  notEnoughLengthMsg = NOT_ENOUGH_LENGTH;
  machineId;
  urlFile: string;
  isLoading = false;
  isDuplicating = false;

  constructor(
    private modalController: ModalController,
    public alertCtrl: AlertController,
    private appRef: ApplicationRef,
    private productionOrderService: ProductionOrdersService,
    public substepsService: SubstepsService,
    private changeDetector: ChangeDetectorRef,
    afAuth: AuthenticateService,
    public toastController: ToastController,
    public uploadService: UploadService,
  ) {
    afAuth.getAuthUser().subscribe((user) => {
      this.user = user.email;
    });
  }

  ngOnInit() {
    if (this.predecessorProductionOrderSubstep) {
      this.productionOrderService
        .getSubstepNested(this.productionOrderId, this.substepId, this.predecessorProductionOrderSubstep)
        .subscribe(
          (substep) => {
            this.substep = substep;

            this.createForm();
            this.afterAction(false);
          },
          (error: any) => {
            console.error(error);
          },
        );
    } else {
      if (!this.substep) {
        this.productionOrderService.getSubstep(this.productionOrderId, this.substepId).subscribe(
          (substep) => {
            this.substep = substep;
            this.createForm();
            this.machineId = this.substep.inputs[0].reelMachineId;
          },
          (error: any) => {
            console.error(error);
          },
        );
      } else {
        this.substepsService
          .getOnlyLastMachine(this.productionOrderId, this.substepId, this.machineId)
          .subscribe((response) => {
            this.substep.inputs[0].value = response.value;
            this.appRef.tick();
            this.showLoading = false;
            this.disableSaveButton = false;
          });
      }
    }
  }

  closeModalEvent(event) {
    setTimeout(() => {
      this.closeModal(this.productionOrderId);
    }, 8000);
  }

  shouldShowDissaprovalInput() {
    const haveStatus = this.substepForm.value.hasOwnProperty('status');

    if (haveStatus) {
      if (this.substepForm.value['status'] === 'Aprovado') {
        this.formKeys.forEach((input) => {
          if (input.propertyName === 'dissaproval') {
            this.substepForm.patchValue({ dissaproval: null });
          }
          this.substepForm
            .get(input.propertyName)
            .setValidators([...this.validatorBuilder(input.validators, 'required')]);
          this.substepForm.get(input.propertyName).updateValueAndValidity();
        });
        return false;
      } else if (this.substepForm.value['status'] === 'Não aprovado') {
        this.formKeys.forEach((input) => {
          this.substepForm
            .get(input.propertyName)
            .setValidators([...this.validatorBuilder(input.validators, 'disapprovedRequired')]);
          this.substepForm.get(input.propertyName).updateValueAndValidity();
        });
        return true;
      }
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  showInputText() {
    const haveMachine = this.substepForm.value.hasOwnProperty('machineId');
    if (haveMachine) {
      return this.substepForm.value.machineId;
    } else {
      return true;
    }
  }

  async closeModal(productionOrderId: number) {
    await this.modalController.dismiss(productionOrderId).catch((error) => {
      if (error.message === 'overlay does not exist trown') {
        return;
      }
    });
  }

  createForm() {
    let inputs = this.substep.inputs;
    const isAutoValue = [];
    const inputWhitRows = this.substep.inputs.filter((input) => input?.rows);
    const inputWithGrids = this.substep.inputs.filter((input) => input?.grids);
    const columnsInputs = [];

    inputWithGrids.forEach((input) => input.grids.forEach((grid) => inputWhitRows.push(grid)));
    inputWhitRows.forEach((input) => {
      input.rows.forEach((row) => {
        row.columns.forEach((column) => {
          columnsInputs.push(column);
        });
      });
    });

    inputs = [...inputs, ...columnsInputs];
    inputs = inputs.filter((input) => input.propertyName);

    this.formKeys = inputs.map((input) => {
      if (input.automaticValueId) {
        isAutoValue.push({
          propertyName: input.propertyName,
          value: input.value,
          reelMachineId: 'input.reelMachineId',
        });
      }
      if (input.propertyName !== 'status') {
        isAutoValue.push({
          propertyName: input.propertyName,
          value: input.value || null,
        });
      }

      if (input.propertyName === 'status' && this.substep.description == 'Medições') {
        isAutoValue.push({
          propertyName: input.propertyName,
          value: input.value !== null ? (input.value ? 'Aprovado' : 'Não aprovado') : 'Aprovado',
        });
      }

      return {
        propertyName: input.propertyName,
        validators: input.validators,
      };
    });

    const formObject = {};
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < this.formKeys.length; index++) {
      formObject[this.formKeys[index].propertyName] = new FormControl(
        this.formKeys[index].propertyName === 'status' ? 'Aprovado' : null,
        [...this.validatorBuilder(this.formKeys[index].validators, 'required')],
      );
    }

    this.substepForm = new FormGroup({
      ...formObject,
    });

    isAutoValue.map((input) => {
      this.substepForm.patchValue({ [input.propertyName]: input.value, reelMachineId: 'input.reelMachineId' });
    });
  }

  validatorBuilder(validator: any, requiredPropertyName: string) {
    const validators = [];
    if (validator[requiredPropertyName]) {
      validators.push(Validators.required);
    }

    if (requiredPropertyName === 'disapprovedRequired') {
      return validators;
    }

    if (validator.max) {
      validators.push(Validators.max(validator.max));
    }

    if (validator.min) {
      validators.push(Validators.min(validator.min));
    }

    if (validator.requiredTrue) {
      validators.push(Validators.requiredTrue);
    }

    return validators;
  }

  async submit(isLoop = false) {
    this.disableSaveButton = true;

    try {
      const result = await lastValueFrom(
        this.substepsService.saveSubstep(
          this.serializePayload(),
          this.productionOrderId,
          this.substepId,
          this.isDuplicating,
        ),
      );

      if (this.substep.description === 'Medições') {
        this.productionOrderSubstepId = result.productionOrdersSubstepsId;
        this.printTag();
        this.openToast('Salvo com sucesso', 'success');
      } else {
        if (isLoop) {
          await this.ngOnInit();
        } else {
          await this.closeModal(this.productionOrderId);
        }
      }
    } catch (error) {
      this.disableSaveButton = false;

      if (error.status === 409) {
        this.openToast('Já existe bobina com o mesmo lote', 'danger');
      } else {
        this.openToast('Erro ao salvar', 'danger');
        console.log(error);
      }
    }
  }

  serializePayload() {
    const columnsInputs = [];
    const inputWhitRows = this.substep.inputs.filter((input) => input?.rows);
    const inputWithGrids = this.substep.inputs.filter((input) => input?.grids);

    const submit = {
      inputs: [],
      productItems: null,
      machineId: null,
      userId: this.user,
      predecessorProductionOrderSubstepId: null,
      isApproved: null,
    };

    inputWithGrids.forEach((input) => input.grids.forEach((grid) => inputWhitRows.push(grid)));
    inputWhitRows.forEach((input) => {
      input.rows.forEach((row) => {
        row.columns.forEach((column) => {
          columnsInputs.push(column);
        });
      });
    });

    const inputs = [...this.substep.inputs, ...columnsInputs];

    submit.inputs = inputs.map((input) => {
      if (
        input.propertyName !== 'machineId' &&
        input.propertyName !== 'paintings' &&
        input.propertyName !== 'semiFinishedTuboLoose' &&
        input.propertyName !== 'semiFinishedSZCore' &&
        input.propertyName !== 'subProductTabs'
      ) {
        const result = {
          inputId: input.id,
          propertyName: input.propertyName,
          value: '',
          productSpecsId: null,
        };

        if (input.propertyName === 'reelLot') {
          result['reelMachineId'] = input.reelMachineId;
        }

        if (input?.productSpecsId) {
          result.productSpecsId = input.productSpecsId;
        } else {
          delete result.productSpecsId;
        }

        return result;
      }
    });

    submit.inputs = submit.inputs.filter((input) => input !== undefined);

    const formKeys = Object.keys(this.substepForm.value);

    for (const key of formKeys) {
      submit.inputs.forEach((input) => {
        if (input.propertyName === key) {
          let value = this.substepForm.value[key];
          value = typeof value == 'boolean' ? String(value) : value;
          const identifyDecimals = /(^[.|,][0-9]+)/;
          if (identifyDecimals.test(this.substepForm.value[key])) {
            value = this.substepForm.value[key].replace(identifyDecimals, '0$1');
          }
          input.value = value;
        }
      });
    }

    submit.inputs = submit.inputs.filter((input) => input.propertyName !== 'status');

    submit.inputs = submit.inputs.map((input) => {
      delete input.propertyName;
      return input;
    });

    if (this.substepForm.value.machineId) {
      submit.machineId = this.substepForm.value.machineId;
    } else {
      delete submit.machineId;
    }

    if (this.selectedPaintings) {
      if (!this.substepForm.value.paintings) {
        this.substepForm.value.paintings = this.selectedPaintings;
      }

      submit.productItems = this.substepForm.value.paintings.map((item: any, index: any) => ({
        itemId: item.itemid,
        productId: item.productId,
        productSpecId: index,
      }));

      submit.productItems = submit.productItems.filter((item: any) => item);
    } else {
      delete submit.productItems;
    }

    submit.inputs = submit.inputs.filter((input) => input.value);

    if (this.predecessorProductionOrderSubstep) {
      submit.predecessorProductionOrderSubstepId = this.predecessorProductionOrderSubstep;
    } else {
      delete submit.predecessorProductionOrderSubstepId;
    }

    const haveStatusProperty = this.substepForm.value.hasOwnProperty('status');

    if (haveStatusProperty) {
      submit.isApproved = this.substepForm.value?.status === 'Aprovado' ? true : false;
    } else {
      delete submit.isApproved;
    }

    return submit;
  }

  async afterAction(onInputChange = true) {
    if (['Bobina'].includes(this.substep.description)) {
      this.machineId = this.substepForm.value.machineId;
      this.showLoading = true;
      this.substepsService
        .getLastMachine(this.productionOrderId, this.substepId, true, this.substepForm.value.machineId)
        .subscribe(
          (response) => {
            this.substep.inputs = response.inputs;
            this.createForm();
            this.appRef.tick();
            this.showLoading = false;
          },
          () => {
            this.clearValues();
            this.showLoading = false;
          },
        );
    }

    if (['Produção', 'Produção de enchimento'].includes(this.substep.description)) {
      const loadMachineLastSetup = await this.alertCtrl.create({
        header: 'Confirmar',
        message: `Deseja carregar a <strong>última produção</strong> da máquina selecionada?`,
        cssClass: 'lastMachineQuestion',
        buttons: [
          {
            text: 'Não',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.clearValues();
              this.appRef.tick();
            },
          },
          {
            text: 'Sim',
            handler: () => {
              this.getLastMachine();
              this.appRef.tick();
            },
          },
        ],
      });
      await loadMachineLastSetup?.present();
    } else if (['Medições'].includes(this.substep.description)) {
      if (onInputChange) return;

      const loadMeasurementLastSetup = await this.alertCtrl.create({
        header: 'Confirmar',
        message: `Deseja carregar a <strong>última Medição</strong>?`,
        cssClass: 'lastMachineQuestion',
        buttons: [
          {
            text: 'Não',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.appRef.tick();
            },
          },
          {
            text: 'Sim',
            handler: () => {
              this.getLastMeasurement();
              this.appRef.tick();
            },
          },
        ],
      });
      await loadMeasurementLastSetup?.present();
    }
  }

  getLastMachine() {
    this.clearValues();
    this.substepsService
      .getLastMachine(this.productionOrderId, this.substepId, true, this.substepForm.value.machineId)
      .subscribe(
        (lastMachineSetup) => {
          for (const input of lastMachineSetup.inputs) {
            if (input.value) {
              this.substepForm.patchValue({ [input.propertyName]: input.value });
            }
          }
          for (const input of lastMachineSetup.inputs) {
            if (input.isProductSelection) {
              for (const item of input.items) {
                for (const option of item.options) {
                  if (option.isSelected) {
                    this.selectedPaintings[item.product.productSpecId] = {
                      itemid: option.id,
                      productId: item.product.id,
                    };
                  }
                }
              }
            }
          }
          this.appRef.tick();
        },
        (_error) => {
          this.clearValues();
        },
      );
  }

  getLastMeasurement() {
    this.substepsService.getLastMachine(this.productionOrderId, this.substepId, true, 0).subscribe(
      (lastMachineSetup) => {
        this.substep.inputs = lastMachineSetup.inputs;
        this.createForm();
        this.appRef.tick();
        this.isDuplicating = true;
      },
      (_error) => {
        this.openToast(_error.error.message, 'danger', 4000);
        this.createForm();
      },
    );
  }

  clearValues() {
    this.selectedPaintings.length = 0;
    for (const input in this.substepForm.value) {
      if (input !== 'machineId') {
        this.substepForm.controls[input].setValue(null);
      }
    }
  }

  isPaintingsSelected() {
    if (this.substep.hasProductItems) {
      const totalSelected = this.selectedPaintings.filter((painting) => painting);
      const productItems = this.substep.inputs.filter((input) => input.isProductSelection);

      if (productItems.length) {
        return totalSelected.length === productItems[0].items.length;
      }

      return false;
    } else {
      return true;
    }
  }

  selectPainting(event: any, productId: number, productSpecId: number) {
    this.selectedPaintings[productSpecId] = { itemid: event.detail.value, productId };
    const inputWHitProducts = this.substep.inputs.filter((input) => input.isProductSelection);
    this.substepForm.patchValue({ [inputWHitProducts[0].propertyName]: this.selectedPaintings });
  }

  inputValidBackgroundFeedback(propertyName: string) {
    const haveStatusProperty = this.substepForm.value.hasOwnProperty('status');
    if (!haveStatusProperty) {
      return;
    }
    const status = this.substepForm.get(propertyName).status;

    if (status === 'VALID') {
      return '#99ff99';
    }

    if (status === 'INVALID') {
      return '#ff7f7f';
    }
  }

  async printTag() {
    const printDropAlert = await this.alertCtrl.create({
      header: `${this.substep.description} salvadas com sucesso.`,
      message: ' Deseja imprimir a etiqueta?',
      buttons: [
        {
          text: 'Sim',
          role: 'ok',
          cssClass: 'primary',
          handler: async () => {
            this.productionOrderService.get(this.productionOrderId).subscribe(async (op) => {
              this.productionOrder = op;
              let propName = '';
              if (this.productionOrder.step.id === 3) {
                propName = 'substepToPrint';
              } else if (this.productionOrder.step.id === 4) {
                propName = 'substepToCapaTag';
              } else if (this.productionOrder.step.id === 5) {
                propName = 'substepToTag';
              } else if (this.productionOrder.step.id === 6) {
                propName = 'substepToDropTag';
              } else {
                propName = 'substepToPrint';
              }
              this[propName] = true;
            });
          },
        },
        {
          text: 'Fechar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.closeModal(this.productionOrderId);
          },
        },
      ],
    });
    await printDropAlert.present();
  }

  @HostListener('window:afterprint')
  onafterprint() {
    this.closeModal(this.productionOrderId);
  }

  showTabGrid(productId: number) {
    if (!this.substepForm.value['subProductTabs']) {
      this.substepForm.patchValue({ subProductTabs: productId });
    }
    return this.substepForm.value['subProductTabs'] === productId;
  }

  changeTab(productId: number) {
    const tabs = this.substep.inputs.find((input) => input.propertyName === 'subProductTabs');
    this.activeTab = tabs.grids.findIndex((grid) => grid.productId === productId);
  }

  shouldDisableInputs() {
    return !this.substep.canCreate;
  }

  showFooter() {
    return this.substepForm.valid && this.isPaintingsSelected() && this.substep.canCreate;
  }

  showLoopButton(): boolean {
    return this.substep.hasLoopCreation;
  }
  async openToast(message: string, color: string, duration = 2000): Promise<void> {
    const toast = await this.toastController.create({
      duration,
      position: 'bottom',
      message,
      color,
    });
    toast.present();
  }

  handleClearUrl(inputPropertyName: string) {
    this.substepForm.get(inputPropertyName).patchValue(null);
  }

  async upload(event: any, inputPropertyName: string) {
    try {
      this.isLoading = true;
      const file = event.target.files[0];
      const lotBobina = this.substep.pageTitle.split(':')[1].trim() + '-' + new Date().getTime();
      const urlFile: string = (await this.uploadService.postSubstepFile(file, lotBobina)).link;
      this.substepForm.get(inputPropertyName).patchValue(urlFile);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  }
}
