import { Component, Input, OnChanges } from '@angular/core';
import { DateBrPipe } from '../../pipes/date/date-br.pipe';

@Component({
  selector: 'app-paiting-label',
  templateUrl: './paiting-label.component.html',
  styleUrls: ['./paiting-label.component.scss'],
  providers: [DateBrPipe],
})
export class PaitingLabelComponent implements OnChanges {
  @Input() painting: any;
  isTest = false;

  constructor(private dateBrPipe: DateBrPipe) {}

  ngOnChanges() {
    if (!this.isTest) {
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }

  getQrCodeInfo() {
    let qrCodeInfo = `${this.painting?.paintingLot}\n`;
    qrCodeInfo += `${this.painting?.product?.slug}\n`;
    qrCodeInfo += `${this.painting?.product?.type?.description}\n`;
    qrCodeInfo += `${this.painting?.product?.color?.description}\n`;
    qrCodeInfo += `${this.painting?.otdrLength}`;
    return qrCodeInfo;
  }

  dateBr(date) {
    return this.dateBrPipe.transform(date, false);
  }
}
