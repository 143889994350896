import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { InventoryOrderPageRoutingModule } from './inventory-order-routing.module';

import { InventoryOrderPage } from './inventory-order.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, InventoryOrderPageRoutingModule, ReactiveFormsModule],
  declarations: [InventoryOrderPage],
})
export class InventoryOrderPageModule {}
