import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { fiber, subProductList } from '../../models/subProductList';

@Component({
  selector: 'app-add-sub-product',
  templateUrl: './add-sub-product.page.html',
  styleUrls: ['./add-sub-product.page.scss'],
})
export class AddSubProductPage implements OnInit {
  @Input() productId: number;
  @Input() productSpecId: number;
  @Input() slicesRequestLength: number;
  @Input() productionOrderId: number;
  subProducts: subProductList;
  totalLengthSelectedSubProducts = 0;
  subProductsSelected: fiber[] = [];

  constructor(
    private modalController: ModalController,
    private productsService: ProductsService,
    private productionOrderService: ProductionOrdersService,
  ) {}

  ngOnInit() {
    this.productsService
      .listSubProductsAvaible(this.productId, this.slicesRequestLength)
      .subscribe((subProductsList) => {
        this.subProducts = subProductsList;
      });
  }

  changeToggle(subProduct: any) {
    subProduct.productspecid = this.productSpecId;
    let positionSubProductToRemove = -1;
    this.subProductsSelected.filter((subProductSelected, index) => {
      if (subProductSelected.id === subProduct.id) {
        positionSubProductToRemove = index;
        return true;
      }
      return false;
    });

    if (positionSubProductToRemove > -1) {
      this.totalLengthSelectedSubProducts -= Number(subProduct.length);
      this.subProductsSelected.splice(positionSubProductToRemove, 1);
    } else {
      this.totalLengthSelectedSubProducts += Number(subProduct.length);
      this.subProductsSelected.push(subProduct);
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  submit() {
    this.productionOrderService.saveSubProducts(this.productionOrderId, this.subProductsSelected).subscribe((res) => {
      this.modalController.dismiss();
    });
  }
}
