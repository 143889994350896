import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateBr',
})
export class DateBrPipe implements PipeTransform {
  transform(date: string, time = true, convertToLocalTimeZone = true, fullYear = true) {
    moment.locale('pt-BR');
    const formattedDate = convertToLocalTimeZone ? moment(date) : moment(date).utc();
    const dateFormat = fullYear ? 'DD/MM/YYYY' : 'DD/MM/YY';
    return time ? formattedDate.format(`${dateFormat} HH:mm`) : formattedDate.format(dateFormat);
  }
}
