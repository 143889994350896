import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthenticateService } from './services/auth/authentication.service';

import { FiberListPage } from './pages/fibers/fiber-list/fiber-list.page';
import { PaintFormPage } from './pages/fibers/paint/paint-form/paint-form.page';
import { PaintListPage } from './pages/fibers/paint/paint-list/paint-list.page';
import { DropsMeasurementPage } from './pages/production-orders/drops/drops-measurement/drops-measurement.page';
import { SubstepPageModule } from './pages/production-orders/substep/substep.module';
import { ProductionOrderPageModule } from './pages/production-orders/production-order/production-order.module';
import { AddSubProductPageModule } from './pages/add-sub-product/add-sub-product.module';
import { InventoryListPageModule } from './pages/inventory/inventory-list/inventory-list.module';
import { InventoryOrderPageModule } from './pages/inventory/inventory-order/inventory-order.module';
import { InventoryFormPageModule } from './pages/inventory/inventory-form/inventory-form.module';
import { StepsPageModule } from './pages/config/steps/steps/steps.module';

import { StepDetailsPageModule } from './pages/config/steps/step-details/step-details.module';
import { AddNewProductPageModule } from './pages/config/steps/add-new-product/add-new-product.module';
import { PalletFormPageModule } from './pages/production-orders/pallets/pallet-form/pallet-form.module';
import { SliceFormPageModule } from './pages/production-orders/slice-form/slice-form.module';
import { SetupFormPageModule } from './pages/production-orders/setups/setup-form/setup-form.module';
import { PaintListPageModule } from './pages/fibers/paint/paint-list/paint-list.module';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [AppComponent, FiberListPage, PaintFormPage, DropsMeasurementPage],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SubstepPageModule,
    ProductionOrderPageModule,
    AddSubProductPageModule,
    InventoryOrderPageModule,
    InventoryListPageModule,
    InventoryFormPageModule,
    StepsPageModule,
    StepDetailsPageModule,
    AddNewProductPageModule,
    PalletFormPageModule,
    SliceFormPageModule,
    SetupFormPageModule,
    PaintListPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthenticateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
