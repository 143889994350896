import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';

import { UserModel } from 'src/app/models/user-model';
import { SubstepPage } from 'src/app/pages/production-orders/substep/substep.page';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';
import { SubstepsService } from 'src/app/services/substep/substep.service';
import { Substep } from '../../models/substep-model';
import { DateBrPipe } from '../../pipes/date/date-br.pipe';
import { dropLabelMockVivo } from '../drop-label/drop-label-mock';
@Component({
  selector: 'app-substep',
  templateUrl: './substep.component.html',
  styleUrls: ['./substep.component.scss'],
  providers: [DateBrPipe],
})
export class SubstepComponent {
  @ViewChild('substepList') substepList;
  @Input() substep: Substep;
  @Input() productionOrderId: number;
  @Input() stepId: number;
  @Input() statusOP: string;
  @Output() reload = new EventEmitter<any>();
  @Input() isReadyToStart: boolean;
  substepToPrint: boolean;
  substepToTag: boolean;
  substepToCapaTag: boolean;
  substepIdToPrint: number;
  productionOrderSubstepIdToPrint: number;
  userType: string;
  dropToPrint = dropLabelMockVivo;
  substepToDropTag: boolean;
  pageNumber: number = 1;
  subtepToPrintIntermediaryTag: boolean;
  toggleChecked: boolean = true;
  inputValue: string = '';
  filteredList: any[] = [];
  noPendingMeasurements: boolean = false;
  noFinishedMeasurements: boolean = false;

  ngOnInit() {
    this.filterList();
  }

  constructor(
    private dateBrPipe: DateBrPipe,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private substepsService: SubstepsService,
    private navCtrl: NavController,
    private authService: AuthenticateService,
    private router: Router,
  ) {
    this.authService.getUser().subscribe((user: UserModel) => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        this.userType = user.type;
      }
    });
  }

  dateBr(date: string) {
    return this.dateBrPipe.transform(date, true);
  }

  shouldShowAddSubstepButton() {
    let shouldShowAddSubstepButton =
      ((this.substep.isInitial && !this.substep.list.length && this.userType === 'admin') || !this.substep.isInitial) &&
      this.statusOP !== 'Finalizada';

    if (this.substep.hasSliceCount) {
      shouldShowAddSubstepButton = !this.substep.sliceInfo.isDone && this.statusOP !== 'Finalizada';
    }

    return shouldShowAddSubstepButton;
  }
  async openModal(substepId: number, predecessorProductionOrderSubstep: number) {
    const infos = {
      productionOrderId: this.productionOrderId,
      substepId,
    };
    if (substepId === 3) {
      infos['predecessorProductionOrderSubstep'] = predecessorProductionOrderSubstep;
    }

    const substepModal = await this.modalCtrl.create({
      component: SubstepPage,
      componentProps: infos,
    });
    substepModal.onDidDismiss().then(() => {
      this.reload.emit();
    });
    substepModal.present();
  }

  async openDetailsPage(productionOrdersSubstepId: number) {
    const path = '/menu/substepDetails';
    const navigationExtras: NavigationExtras = {
      queryParams: {
        productionOrderId: this.productionOrderId,
        substepId: this.substep.id,
        productionOrdersSubstepId,
      },
    };
    this.navCtrl.navigateForward([path], navigationExtras);
    this.substepList.closeSlidingItems();
  }

  updateProductionOrder() {
    this.reload.emit();
  }

  async removeSubstepConfirmation(productionOrdersSubstepId: number) {
    const removeSetupAlert = await this.alertCtrl.create({
      header: 'Confirmar',
      message: `Deseja excluir essa <strong>${this.substep.description}?</strong>`,
      cssClass: 'deleteAskAlert',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.substepList.closeSlidingItems();
          },
        },
        {
          text: 'Excluir',
          handler: () => {
            this.substepList.closeSlidingItems();
            this.removeSubstep(productionOrdersSubstepId);
          },
        },
      ],
    });
    await removeSetupAlert.present();
  }

  removeSubstep(productionOrdersSubstepId: number) {
    this.substepsService
      .deleteSubstepSetup(this.productionOrderId, this.substep.id, productionOrdersSubstepId)
      .subscribe(
        () => {
          this.reload.emit(this.productionOrderId);
        },
        async (error) => {
          if (error.status === 403) {
            const alert = await this.alertCtrl.create({
              message: error.error.message,
              header: 'Semi-acabado em uso',
              buttons: [
                {
                  text: 'Ok',
                  handler: () => {
                    this.substepList.closeSlidingItems();
                  },
                },
              ],
            });

            await alert.present();
          }
        },
      );
  }

  print(substepItem: any) {
    let propName = '';

    this.productionOrderSubstepIdToPrint = substepItem.nestedSubstep.id;
    this.substepIdToPrint = substepItem.nestedSubstep.substepId;

    if (substepItem.nestedSubstep?.inputs.length === 0) {
      this.productionOrderSubstepIdToPrint = substepItem.id;
      this.substepIdToPrint = substepItem.nestedSubstep.id;
      propName = 'subtepToPrintIntermediaryTag';
    } else {
      if (this.stepId === 3) {
        propName = 'substepToPrint';
      } else if (this.stepId === 4) {
        propName = 'substepToCapaTag';
      } else if (this.stepId === 5) {
        propName = 'substepToTag';
      } else if (this.stepId === 6) {
        propName = 'substepToDropTag';
      } else if (this.stepId >= 7) {
        propName = 'substepToPrint';
      } else {
        return;
      }
    }

    this.substepList?.closeSlidingItems();
    this[propName] = true;

    setTimeout(() => {
      this[propName] = false;
    }, 4000);
  }

  showSucessIcon(): boolean {
    return this.substep.sliceInfo?.isDone;
  }

  getToggleLabel(): string {
    return this.toggleChecked ? 'Pendentes' : 'Finalizados';
  }

  filterList() {
    if (this.substep?.list) {
      if (this.toggleChecked) {
        this.filteredList = this.substep.list.filter((item) => item.isApproved === null);
        this.noPendingMeasurements = this.filteredList.length === 0;
      } else {
        this.filteredList = this.substep.list.filter((item) => item.isApproved !== null);
        this.noFinishedMeasurements = this.filteredList.length === 0;
      }
    }
  }

  onToggleChange() {
    this.filterList();
  }
}
