import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';
import { MachineModel } from 'src/app/models/machine.model';
import { Setup } from 'src/app/models/setup.model';

interface MachineProps {
  id: number;
  name: string;
}
interface PayloadProps {
  machine: MachineProps;
  metersPerMinute: number;
}
@Injectable({
  providedIn: 'root',
})
export class MachinesService {
  constructor(private http: HttpClient) {}

  async list(stepId): Promise<MachineModel[]> {
    return await lastValueFrom(
      this.http
        .get<MachineModel[]>(BackendConfig.machinesUrl(stepId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async createVelocity(productId: number, payload: PayloadProps) {
    return await lastValueFrom(
      this.http
        .post(BackendConfig.createVelocityUrl(productId), payload, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  getLastMachineSetup(machineId: number, productionOrderId: number): Observable<Setup> {
    return this.http
      .get<Setup>(BackendConfig.lastMachinesSetupUrl(machineId, productionOrderId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  async deleteMachine(machineId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete(BackendConfig.deleteMachine(machineId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
}
