import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsModule } from '../../../components/components.module';

import { IonicModule } from '@ionic/angular';
import { NgxBarcodeModule } from 'ngx-barcode';

import { ProductionOrderPage } from './production-order.page';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { CustomPrint } from 'src/app/directives/custom-print.directive';

const routes: Routes = [
  {
    path: '',
    component: ProductionOrderPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    PipesModule,
    RouterModule.forChild(routes),
    NgxBarcodeModule,
  ],
  declarations: [ProductionOrderPage, CustomPrint],
  providers: [ProductionOrdersService],
})
export class ProductionOrderPageModule {}
