import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { subProductList } from 'src/app/models/subProductList';
import { MachineModel } from '../../models/machine.model';
import { Product } from '../../models/product.model';
import { BackendConfig } from '../config/backend-config';
import { ISubstep } from './../../models/ISubstep';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  list(stepId, typeId): Observable<Product[]> {
    return this.http
      .get<Product[]>(`${BackendConfig.productsUrl()}?typeId=${typeId}&stepId=${stepId}`, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  listSubProductsAvaible(productId: number, sliceRequestLength: number): Observable<subProductList> {
    return this.http
      .get<subProductList>(
        `${BackendConfig.getSubProducts(productId, sliceRequestLength)}`,
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  async getProductById(productId: number): Promise<Product> {
    const product = await lastValueFrom(
      this.http
        .get<Product>(`${BackendConfig.getProductById(productId)}`, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return product as Product;
  }

  async getRecipes(productId: number): Promise<Product> {
    const product = await lastValueFrom(
      this.http
        .get<Product>(`${BackendConfig.getRecipes(productId)}`, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return product as Product;
  }

  async getSubsteps(productId: number): Promise<ISubstep[]> {
    const subStep = await lastValueFrom(
      this.http
        .get<Product>(`${BackendConfig.getSubsteps(productId)}`, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return subStep as unknown as ISubstep[];
  }

  async postProducts(product: any): Promise<any> {
    return await lastValueFrom(
      this.http
        .post<any>(BackendConfig.postProducts(), product, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async putProducts(product: any, id): Promise<any> {
    return await lastValueFrom(
      this.http
        .put<any>(BackendConfig.putProducts(id), product, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async duplicateProduct(product: any, id): Promise<any> {
    return await lastValueFrom(
      this.http
        .post<any>(BackendConfig.duplicateProduct(id), product, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async deleteProducts(id: number, parentProductId: number = null): Promise<any> {
    if (parentProductId) {
      const returned = `${id}?parentProductId=${parentProductId}`;
      return await lastValueFrom(
        this.http
          .delete<any>(BackendConfig.deleteProducts(returned), BackendConfig.httpOptions())
          .pipe(catchError(BackendConfig.handleError)),
      );
    } else {
      return await lastValueFrom(
        this.http
          .delete<any>(BackendConfig.deleteProducts(id), BackendConfig.httpOptions())
          .pipe(catchError(BackendConfig.handleError)),
      );
    }
  }

  async disassociateProducts(productId: number, subProductId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete<any>(BackendConfig.disassociateProducts(productId, subProductId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async getProductMachines(productId: number): Promise<MachineModel[]> {
    return await lastValueFrom(
      this.http
        .get<MachineModel[]>(BackendConfig.getProductMachines(productId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
  async getMachinesById(machineId: number): Promise<MachineModel[]> {
    const machine = await lastValueFrom(
      this.http
        .get<MachineModel[]>(`${BackendConfig.getMachineById(machineId)}`, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );

    return machine as MachineModel[];
  }

  async deleteSpeed(productId: number, machineId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete<any>(BackendConfig.deleteSpeed(productId, machineId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
}
