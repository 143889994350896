import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcodeModule } from 'ngx-barcode';

import { NgxPaginationModule } from 'ngx-pagination';
import { AddButtonComponent } from './add-button/add-button.component';
import { AddItemMethodComponent } from './add-item-method/add-item-method.component';
import { AddItemComponent } from './add-item/add-item.component';
import { AreaFormComponent } from './area-form/area-form.component';
import { CapaTagGenericComponent } from './capa-tag-generic/capa-tag-generic.component';
import { CheckListComponent } from './check-list/check-list.component';
import { ConfigHeaderComponent } from './config-header/config-header.component';
import { ConfirmDisassociateRemoveModalComponent } from './confirm-disassociate-remove-modal/confirm-disassociate-remove-modal.component';
import { CreateUnitComponent } from './create-unit/create-unit.component';
import { DropLabelComponent } from './drop-label/drop-label.component';
import { ExcludeModalComponent } from './exclude-modal/exclude-modal.component';
import { MachineListItemComponent } from './machine-list-item/machine-list-item.component';
import { NestedSubstepComponent } from './nested-substep/nested-substep.component';
import { PaintingProductionOrderItemComponent } from './painting-production-order-item/painting-production-order-item.component';
import { PaintingsReservedComponent } from './paintings-reserved/paintings-reserved.component';
import { PaitingLabelComponent } from './paiting-label/paiting-label.component';
import { ParameterAssociateModalComponent } from './parameter-associate-modal/parameter-associate-modal.component';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { ProductModalComponent } from './product-modal/product-modal.component';
import { ProductVelocityModalComponent } from './product-velocity-modal/product-velocity-modal.component';
import { QrcodeReaderComponent } from './qrcode-reader/qrcode-reader.component';
import { SearchComponent } from './search-component/search.component';
import { StepsMachineModalComponent } from './steps-machine-modal/steps-machine-modal.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { SubproductRangeComponent } from './subproduct-range/subproduct-range.component';
import { SubstepComponent } from './substep/substep.component';
import { TagGenericIntermediaryComponent } from './tag-generic-intermediary/tag-generic-intermediary.component';
import { TagGenericComponent } from './tag-generic/tag-generic.component';
import { TuboLooseTagComponent } from './tubo-loose-tag/tubo-loose-tag.component';
import { UpdateSlicesComponent } from './update-slices/update-slices.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    PaintingProductionOrderItemComponent,
    PaitingLabelComponent,
    DropLabelComponent,
    TagGenericIntermediaryComponent,
    PaintingsReservedComponent,
    SubstepComponent,
    NestedSubstepComponent,
    SearchComponent,
    AddItemComponent,
    QrcodeReaderComponent,
    TagGenericComponent,
    CapaTagGenericComponent,
    SubproductRangeComponent,
    UpdateSlicesComponent,
    TuboLooseTagComponent,
    CreateUnitComponent,
    AreaFormComponent,
    AddItemMethodComponent,
    ProductListItemComponent,
    ProductModalComponent,
    ProductVelocityModalComponent,
    ParameterAssociateModalComponent,
    ConfigHeaderComponent,
    CheckListComponent,
    AddButtonComponent,
    ConfirmDisassociateRemoveModalComponent,
    StepsMachineModalComponent,
    SubmitButtonComponent,
    ExcludeModalComponent,
    MachineListItemComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgxBarcodeModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    NgxPaginationModule,
  ],
  exports: [
    PaintingProductionOrderItemComponent,
    PaitingLabelComponent,
    DropLabelComponent,
    TagGenericIntermediaryComponent,
    PaintingsReservedComponent,
    SubstepComponent,
    NestedSubstepComponent,
    SearchComponent,
    AddItemComponent,
    TagGenericComponent,
    CapaTagGenericComponent,
    SubproductRangeComponent,
    UpdateSlicesComponent,
    TuboLooseTagComponent,
    CreateUnitComponent,
    AreaFormComponent,
    AddItemMethodComponent,
    ProductListItemComponent,
    ProductModalComponent,
    ProductVelocityModalComponent,
    ParameterAssociateModalComponent,
    ConfigHeaderComponent,
    CheckListComponent,
    AddButtonComponent,
    ConfirmDisassociateRemoveModalComponent,
    StepsMachineModalComponent,
    SubmitButtonComponent,
    MachineListItemComponent,
  ],
})
export class ComponentsModule {}
