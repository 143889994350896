import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { genericPrint } from 'src/app/models/genericPrint.model';
import { Substep, SubstepToSave } from '../../models/substep-model';
import { BackendConfig } from '../config/backend-config';
@Injectable({
  providedIn: 'root',
})
export class SubstepsService {
  constructor(private http: HttpClient) {}

  public getProductionOrderSubstep(
    productionOrderId: number,
    substepId: number,
    productionOrdersSubstepId: number,
  ): Observable<any> {
    return this.http
      .get<any>(
        BackendConfig.productionOrderSubstepDetails(productionOrderId, substepId, productionOrdersSubstepId),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  public saveSubstep(
    substep: SubstepToSave,
    productionOrderId: number,
    substepId: number,
    isDuplicated: boolean = false,
  ): Observable<any> {
    return this.http
      .post<Substep>(BackendConfig.productionOrderSubstep(productionOrderId, substepId), substep, {
        ...BackendConfig.httpOptions(),
        params: { isDuplicated: isDuplicated },
      })
      .pipe(catchError(BackendConfig.handleError));
  }

  public getLastMachine(
    productionOrderId: number,
    substepId: number,
    latest: boolean,
    machineId: number,
  ): Observable<any> {
    return this.http
      .get(
        BackendConfig.getSubstepLastMachine(productionOrderId, substepId, latest, machineId),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }
  public getOnlyLastMachine(productionOrderId: number, substepId: number, machineId: number): Observable<any> {
    return this.http
      .get(BackendConfig.getOnlyLastMachine(productionOrderId, substepId, machineId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  public deleteSubstepSetup(
    productionOrderId: number,
    substepId: number,
    productionOrdersSubstepId: number,
  ): Observable<any> {
    return this.http.delete<Substep>(
      BackendConfig.deleteSubstepSetup(productionOrderId, substepId, productionOrdersSubstepId),
      BackendConfig.httpOptions(),
    );
  }

  public getPrint(
    productionOrderId: number,
    substepId: number,
    productionOrdersSubstepId: number,
  ): Observable<genericPrint> {
    return this.http
      .get<genericPrint>(
        BackendConfig.printGenericProductionOrder(productionOrderId, substepId, productionOrdersSubstepId),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  public listPredecessors(productionOrderId: number, productionOrdersSubstepId: number): Observable<any> {
    return this.http
      .get<any>(
        BackendConfig.listPredecessors(productionOrderId, productionOrdersSubstepId),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  public updateSubstepLink(productionOrderId, productionOrderSubstepId, predecessorProductionOrderSubstepId) {
    return this.http
      .patch(
        BackendConfig.updateSubstepLink(
          productionOrderId,
          productionOrderSubstepId,
          predecessorProductionOrderSubstepId,
        ),
        {},
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  async deleteSubSteps(id: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete<any>(BackendConfig.deleteSubSteps(id), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  public postSubstepInput(productId: number, substepId: number, payload: any) {
    return lastValueFrom(
      this.http
        .post(BackendConfig.postSubstepInput(productId, substepId), payload, BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
}
