import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { ISubstep } from 'src/app/models/ISubstep';
import { ISubstepProducts } from 'src/app/models/ISubstepsProducts';
import { ProductsService } from 'src/app/services/products/products.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { ConfirmDisassociateRemoveModalComponent } from '../../../../components/confirm-disassociate-remove-modal/confirm-disassociate-remove-modal.component';
import { SubstepsModalPage } from '../../../../components/substeps-modal/substeps-modal.page';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-substeps',
  templateUrl: './substeps.page.html',
  styleUrls: ['./substeps.page.scss'],
})
export class SubstepsPage {
  product: Product;
  productsSubsteps: ISubstepProducts[];
  substeps: ISubstep;
  constructor(
    private stepsService: StepsService,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private modalCtrl: ModalController,
    private toastController: ToastController,
  ) {
    route.params.subscribe((val) => {
      this.onInit();
    });
  }

  private getProductId() {
    return parseInt(this.route.snapshot.paramMap.get('productid'));
  }
  private getSubstepId() {
    return parseInt(this.route.snapshot.paramMap.get('substepid'));
  }

  async getSubstepsById(): Promise<void> {
    this.substeps = await this.stepsService.getSubstepsById(this.getSubstepId());
  }

  async getProductsSubstepsById(): Promise<void> {
    this.productsSubsteps = await this.stepsService.getProductsSubstepsById(this.getProductId(), this.getSubstepId());
  }
  async getProductById() {
    this.product = await this.productsService.getProductById(this.getProductId());
  }

  async openEditModal(inputId: number) {
    const productId = this.getProductId();
    const substepId = this.getSubstepId();

    if (inputId && productId && substepId) {
      const input = await this.stepsService.getSubstepsInputById(productId, substepId, inputId);

      const modal = await this.modalCtrl.create({
        component: SubstepsModalPage,
        componentProps: {
          productId: productId,
          substepId: substepId,
          inputId: inputId,
          title: 'Edição de parâmetros',
          input: input,
        },
      });

      modal.onDidDismiss().then(() => {
        this.getProductsSubstepsById();
      });

      return await modal.present();
    }
  }

  openSubstepsParameters() {
    this.router.navigate([
      `menu/config/steps/products/${this.getProductId()}/substeps/${this.getSubstepId()}/parameters`,
    ]);
  }

  onInit() {
    this.getProductsSubstepsById();
    this.getSubstepsById();
    this.getProductById();
  }

  async openToast(message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      duration: 2000,
      position: 'bottom',
      message,
      color,
    });
    toast.present();
  }

  async openDisassociateRemoveModal(inputId: number) {
    const modal = await this.modalCtrl.create({
      component: ConfirmDisassociateRemoveModalComponent,
      cssClass: 'confirm-disassociate-remove-modal',
      componentProps: {
        productId: this.getProductId(),
        substepId: this.getSubstepId(),
        inputId: inputId,
      },
    });

    await modal.present();
    const afterModalDismiss = (await modal.onDidDismiss()).data;
    this.openToast(afterModalDismiss.message, afterModalDismiss.color);
    this.getProductsSubstepsById();
    return;
  }
}
