import { ApplicationRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';

import { StockTakingOrder } from 'src/app/models/stockTakingOrder-model';
import { DateBrPipe } from 'src/app/pipes/date/date-br.pipe';
import { DepositModel } from 'src/app/models/deposit.model';
import { InventoryService } from 'src/app/services/inventory/invetory.service';
import { SearchComponent } from 'src/app/components/search-component/search.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddItemMethodComponent } from 'src/app/components/add-item-method/add-item-method.component';
import { AddItemComponent } from 'src/app/components/add-item/add-item.component';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';
import { UserType } from 'src/app/models/user-model';

@Component({
  selector: 'app-inventory-form',
  templateUrl: './inventory-form.page.html',
  styleUrls: ['./inventory-form.page.scss'],
})
export class InventoryFormPage implements OnInit {
  stockTakingOrder: StockTakingOrder;
  selectedDeposit: DepositModel;
  deposits: DepositModel[] = [];
  title = '';
  tabSelected = 0;
  stockTakingOrderId: number;
  showQrCode = false;
  showButtonReportInventory = false;
  canFinished = false;
  inventoryOrderForm: FormGroup = null;
  userType: UserType;
  minDeliveryDate: string = new Date().toISOString().substring(0, 10);
  maxDeliveryDate = new Date().getFullYear() + 1 + '-12-31';

  constructor(
    private alertController: AlertController,
    public toastController: ToastController,
    public modalCtrl: ModalController,
    private router: Router,
    private appRef: ApplicationRef,
    private activatedRoute: ActivatedRoute,
    private dateBrPipe: DateBrPipe,
    private inventoryService: InventoryService,
    public loadingController: LoadingController,
    private authService: AuthenticateService,
  ) {}

  ngOnInit(): void {
    this.authService.getUser().subscribe((user) => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        this.userType = user;
      }
    });

    this.inventoryOrderForm = new FormGroup({
      name: new FormControl('', Validators.required),
      from: new FormControl('', [Validators.required]),
      to: new FormControl('', [Validators.required]),
      stickerInitialNumber: new FormControl('', [Validators.required, Validators.min(1)]),
      stickerFinalNumber: new FormControl('', [Validators.required, Validators.min(2)]),
      countingRounds: new FormControl('', [Validators.required, Validators.min(1), Validators.max(2)]),
    });

    this.stockTakingOrderId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    this.getDeposits();
    this.getStockTakingOrderById(this.stockTakingOrderId);
  }

  showButtonCanFinished(): boolean {
    return this.stockTakingOrder?.status?.name === 'Pronto Para Finalizar' && !this.stockTakingOrder.isFinished;
  }

  showButtonGenerateReportInventory(): boolean {
    const status = this.stockTakingOrder?.status?.name;
    const statusTrue = ['Em Andamento', 'Pronto Para Finalizar', 'Finalizado'];
    if (statusTrue.includes(status)) {
      return true;
    }
    return false;
  }

  async presentLoading(): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingController.create({
      spinner: 'lines',
      translucent: true,
      backdropDismiss: false,
    });

    await loading.present();

    return loading;
  }

  async finish(): Promise<void> {
    const toast = await this.toastController.create({
      header: 'Sucesso!',
      message: this.stockTakingOrder.isFinished ? 'Ordem de Inventário reaberta!' : 'Ordem de Inventário finalizada!',
      position: 'top',
      cssClass: 'updateMessage',
      duration: 2000,
      color: 'success',
      animated: true,
    });
    await toast.present();

    this.inventoryService
      .editStatusFinished(this.stockTakingOrderId, !this.stockTakingOrder.isFinished)
      .subscribe((res) => {
        this.getStockTakingOrderById(this.stockTakingOrderId);
      });
  }

  getReport(id: number): void {
    this.inventoryService.getReportStock(id).subscribe(
      (res) => {
        const file = new Blob([res], {
          type: res.type,
        });
        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = `report-${this.stockTakingOrder.name}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(blob);
        link.remove();
        return true;
      },
      (err) => {
        console.error(err);
      },
    );
  }

  showAdd(quantity: number): boolean {
    if (quantity || quantity === 0) {
      return false;
    }
    return true;
  }

  backToInventoryList(): void {
    this.router.navigate(['/menu/inventory-list']);
  }

  dateBr(date: string): string {
    return this.dateBrPipe.transform(date, false);
  }

  loadForm(stockTakingOrder): void {
    this.inventoryOrderForm.patchValue({
      name: stockTakingOrder.name,
      from: stockTakingOrder.from,
      to: stockTakingOrder.to,
      stickerInitialNumber: stockTakingOrder.stickerInitialNumber,
      stickerFinalNumber: stockTakingOrder.stickerFinalNumber,
      countingRounds: stockTakingOrder.countingRounds,
    });
  }

  async getStockTakingOrderById(id: number) {
    const loading = await this.presentLoading();
    this.inventoryService.getStockTakingOrderById(id).subscribe(async (stockTakingOrder) => {
      this.stockTakingOrder = stockTakingOrder;
      this.title = stockTakingOrder.name;
      this.selectedDeposit = stockTakingOrder.deposit;
      this.showButtonReportInventory = this.showButtonGenerateReportInventory();
      this.canFinished = this.showButtonCanFinished();
      this.loadForm(this.stockTakingOrder);
      this.appRef.tick();
      await loading.dismiss();
    });
  }

  getDeposits(): void {
    this.inventoryService.list().subscribe((deposits) => {
      this.deposits = deposits;
    });
  }

  createPayload(): StockTakingOrder {
    const payload = {
      id: this.stockTakingOrder.id,
      name: this.inventoryOrderForm.value.name,
      from: this.inventoryOrderForm.value.from,
      to: this.inventoryOrderForm.value.to,
      stickerInitialNumber: this.inventoryOrderForm.value.stickerInitialNumber,
      stickerFinalNumber: this.inventoryOrderForm.value.stickerFinalNumber,
      countingRounds: this.inventoryOrderForm.value.countingRounds,
      isFinished: this.stockTakingOrder.isFinished,
      deposit: { id: this.selectedDeposit.id },
    };
    return payload;
  }

  updateStockTakingOrder(): void {
    if (this.inventoryOrderForm.status === 'INVALID') {
      return;
    }
    const payload = this.createPayload();
    this.inventoryService.updateStockTakingOrder(payload).subscribe(() => {
      this.getStockTakingOrderById(this.stockTakingOrder.id);
      this.openToast('Sucesso!', 'Ordem de Inventário atualizada!', 'success');
    });
  }

  async openToast(header: string, message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      header,
      message,
      color,
      position: 'top',
      cssClass: 'updateMessage',
      duration: 2000,
      animated: true,
    });

    await toast.present();
  }

  async openSearchComponent(): Promise<void> {
    const loading = await this.presentLoading();
    const searchModal = await this.modalCtrl.create({
      component: SearchComponent,
      componentProps: {
        options: this.deposits,
        propertyToSearch: 'fullDescription',
        itemToSelect: 'Depósito',
      },
    });
    searchModal.onDidDismiss().then(async (deposit) => {
      if (!deposit.data?.id) {
        return;
      }
      this.stockTakingOrder.deposit.id = deposit.data.id;
      this.selectedDeposit = deposit.data;
    });

    await loading.dismiss();
    searchModal.present();
  }

  changeTab(roundNumber: number): void {
    this.tabSelected = roundNumber - 1;
  }

  async openDeleteModal(itemId: number, roundNumber: number): Promise<void> {
    const deleteMessage = this.getDeleteMessage();
    const alert = await this.alertController.create({
      header: 'Excluir item',
      message: deleteMessage,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: async () => {
            await alert.dismiss();
          },
        },
        {
          text: 'Excluir item',
          handler: () => {
            this.inventoryService.deleteItem(this.stockTakingOrderId, itemId, roundNumber).subscribe(() => {
              this.getStockTakingOrderById(this.stockTakingOrderId);
            });
          },
        },
      ],
    });

    await alert.present();
  }

  async addItem(itemId: number, round: number = 1): Promise<void> {
    const from: number = new Date(this.inventoryOrderForm.value.from).getTime();
    const to: number = new Date(this.inventoryOrderForm.value.to).setHours(23, 59, 59);
    const today: number = new Date().getTime();

    if (from < today && today < to) {
      const items = this.stockTakingOrder.rounds[0].stockTakingOrderItems;
      const addItemComponent = await this.modalCtrl.create({
        component: AddItemMethodComponent,
        componentProps: {
          stockTakingOrderId: this.stockTakingOrderId,
          itemId,
          round,
          stickerInitialNumber: this.inventoryOrderForm.value.stickerInitialNumber,
          stickerFinalNumber: this.inventoryOrderForm.value.stickerFinalNumber,
          items,
        },
        cssClass: 'selectReadMethod',
      });
      await addItemComponent?.present();

      addItemComponent?.onDidDismiss().then(() => {
        this.getStockTakingOrderById(this.stockTakingOrderId);
      });
    } else {
      const toast = await this.toastController.create({
        message: 'Esta leitura está fora do período de inventário',
        position: 'top',
        cssClass: 'updateMessage',
        duration: 2000,
        color: 'warning',
        animated: true,
      });
      toast?.present();
    }
  }

  getDeleteMessage(): string {
    const roundNumber = this.stockTakingOrder.rounds[this.tabSelected].number;
    switch (roundNumber) {
      case 1:
        return 'O item será removido das contagens seguintes e removido da Ordem de Inventário. Deseja continuar?';
      case 2:
        return 'Ao excluir o item na contagem 2, o mesmo também será removido da contagem 3. Deseja continuar?';
      case 3:
        return 'O item será excluído da contagem 3. Deseja continuar?';
      default:
        return ' ';
    }
  }

  async openFinishingInventoryOrderAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Finalizar inventário',
      message: 'Deseja finalizar esta ordem de inventário?',
      cssClass: 'finishDialog',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: async () => {
            await alert.dismiss();
          },
        },
        {
          text: 'Finalizar',
          handler: async () => {
            await this.finish();
          },
        },
      ],
    });

    await alert.present();
  }

  async updateQuantity(itemId: number, round: number = 1) {
    const addItemComponent = await this.modalCtrl.create({
      component: AddItemComponent,
      componentProps: {
        stockTakingOrderId: this.stockTakingOrderId,
        itemId,
        round,
      },
    });

    await addItemComponent.present();

    addItemComponent.onDidDismiss().then(() => {
      this.getStockTakingOrderById(this.stockTakingOrderId);
    });
  }

  async reopenInventoryOrderAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Reabrir inventário',
      message: 'Deseja abrir novamente esta ordem de inventário?',
      cssClass: 'reopenDialog',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: async () => {
            await alert.dismiss();
          },
        },
        {
          text: 'Abrir',
          handler: async () => {
            await this.finish();
          },
        },
      ],
    });

    await alert.present();
  }

  userIsOp(): boolean {
    return this.userType?.inventoryType === '1';
  }

  async cancelSticker(): Promise<void> {
    const value = '';
    const alert = await this.alertController.create({
      header: 'Cancelar ficha',
      message: 'Digite a ficha que você deseja cancelar',
      cssClass: 'cancelModal',
      inputs: [
        {
          name: 'sticker',
          type: 'text',
          placeholder: 'Insira a ficha',
          cssClass: 'stickerInput',
          value,
          attributes: {
            autoComplete: 'off',
            autocomplete: 'off',
          },
        },
      ],
      buttons: [
        {
          text: 'Fechar',
          cssClass: 'closeButton',
          handler: () => {
            alert.dismiss();
          },
        },
        {
          text: 'Cancelar',
          cssClass: 'cancelButton',
          handler: (value) => {
            this.inventoryService.cancelSticker(this.stockTakingOrderId, value.sticker).subscribe(
              (res) => {
                this.openToast('Cancelamento de ficha', res.message, 'success');
                this.getStockTakingOrderById(this.stockTakingOrderId);
              },
              (err) => {
                this.openToast('Erro ao cancelar a ficha', err.message, 'danger');
              },
            );
          },
        },
      ],
    });

    await alert.present();
  }
}
