import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SubstepsPageRoutingModule } from './substeps-routing.module';

import { SubstepsPage } from './substeps.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { SubstepsModalPageModule } from '../../../../components/substeps-modal/substeps-modal.module';
import { SubstepsModalPage } from '../../../../components/substeps-modal/substeps-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SubstepsPageRoutingModule,
    SubstepsModalPageModule,
    HttpClientModule,
    ComponentsModule,
    SubstepsModalPageModule,
  ],
  declarations: [SubstepsPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [SubstepsModalPage],
})
export class SubstepsPageModule {}
