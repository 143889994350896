import { IStep } from './../../../../models/step.model';
import { IStepTypes } from './IStepTypes';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { StepsService } from 'src/app/services/steps/steps.service';
import { FiberTypesService } from 'src/app/services/fibersTypes/fiber-types.service';

@Component({
  selector: 'app-step-modal',
  templateUrl: './step-modal.page.html',
  styleUrls: ['./step-modal.page.scss'],
})
export class StepModalPage implements OnInit {
  types: IStepTypes[];
  step: IStep = {
    id: 0,
    description: '',
    isFinal: false,
    isActive: false,
    type: {
      id: 0,
      description: '',
    },
  };
  product: any = { description: '', hasRecipe: false };
  resultStep: Object;
  stepId: number;
  productId: number;

  constructor(
    private modalCtrl: ModalController,
    private stepsService: StepsService,
    public toastController: ToastController,
    private fiberTypesService: FiberTypesService,
  ) {}

  async openToast(header: string, message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      header,
      message,
      color,
      position: 'bottom',
      cssClass: 'updateMessage',
      duration: 900,
      animated: true,
    });

    await toast.present();
  }

  bildStep() {
    this.step.description = this.step.description;
    this.step.isFinal = this.step.isFinal;
    this.step.isActive = this.step.isActive;
    this.step.type.id = this.step.type.id;
    this.step.type.description = this.step.type.description;

    return this.step;
  }

  buildProduct() {
    this.product.description = this.product.description;
    this.product.hasRecipe = this.product.hasRecipe;

    return this.product;
  }

  async submit() {
    this.bildStep();

    if (this.stepId) {
      const payload: IStep = {
        id: this.stepId,
        description: this.step.description,
        isFinal: this.step.isFinal,
        isActive: this.step.isActive,
        type: {
          id: this.step.type.id,
          description: this.step.type.description,
        },
      };
      this.stepsService.updateStep(payload).then(
        () => {
          this.modalCtrl.dismiss();
        },
        (error) => {
          console.error(error);
          this.openToast('Ocorreu um Erro', JSON.stringify(error.error.message), 'danger');
        },
      );
      return this.openToast('Sucesso', 'Setor editado com Sucesso', 'success');
    } else {
      this.stepsService.createStep(this.step).then(
        () => {
          this.modalCtrl.dismiss();
        },
        (error) => {
          console.error(error);
          this.openToast('Ocorreu um Erro', JSON.stringify(error.error.message), 'danger');
        },
      );
      this.openToast('Sucesso', 'Setor Cadastrado com Sucesso', 'success');
    }
  }

  async productSubmit() {
    this.buildProduct();
    if (this.productId) {
      const payload: any = {
        id: this.productId,
        description: this.product.description,
        hasRecipe: this.product.hasRecipe,
      };
      this.fiberTypesService.update(payload).then(
        (e) => {
          this.openToast('Sucesso', 'Tipo de produto editado com Sucesso!', 'success');
          this.modalCtrl.dismiss();
        },
        (error) => {
          if (error.status === 400) {
            return this.openToast('Não é possível cadastrar com campos vazios', null, 'danger');
          }
          return this.openToast('Tipo de produto já cadastrado', null, 'danger');
        },
      );
    } else {
      this.fiberTypesService
        .create(this.product)
        .then(() => {
          this.openToast('Sucesso', 'Tipo de produto criado com Sucesso!', 'success');
          this.modalCtrl.dismiss();
        })
        .catch((error) => {
          if (error.status === 400) {
            return this.openToast('Não é possível cadastrar com campos vazios', null, 'danger');
          }
          return this.openToast('Tipo de produto já cadastrado', null, 'danger');
        });
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async ngOnInit() {
    this.types = await this.stepsService.listTypes();
    if (this.stepId) {
      this.step = await this.stepsService.getStepDetails(this.stepId);
    }
    if (this.productId) {
      this.fiberTypesService.getFiberDetails(this.productId).then((res: any) => {
        this.product.description = res.types[0].description;
        this.product.hasRecipe = res.types[0].hasrecipe;
      });
    }
    if (this.step.type.id >= 3) {
      this.step.type = {
        id: 3,
        description: 'Genérico',
      };
    }
  }
}
