import { Component, OnInit } from '@angular/core';
import { StepsService } from 'src/app/services/steps/steps.service';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { StepModalPage } from '../step-modal/step-modal.page';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.page.html',
  styleUrls: ['./steps.page.scss'],
})
export class StepsPage implements OnInit {
  stepsList: Object;

  constructor(
    private stepsService: StepsService,
    private modalController: ModalController,
    private router: Router,
    public toastController: ToastController,
    public alertController: AlertController,
  ) {}

  async openToast(header: string, message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      header,
      message,
      color,
      position: 'bottom',
      cssClass: 'updateMessage',
      duration: 1000,
      animated: true,
    });

    await toast.present();
  }
  async listSteps() {
    this.stepsList = await this.stepsService.list(false, false);
  }

  async deleteSteps(id: number) {
    try {
      await this.stepsService.deleteStep(id);
      this.openToast('Sucesso', 'Setor deletado com sucesso', 'success');
      this.listSteps();
    } catch (err) {
      this.openToast('Erro', err?.error?.message || 'Erro ao deletar setor', 'danger');
    }
  }

  openStepDetails(stepId: number) {
    this.router.navigate([`menu/config/steps/${stepId}`]);
  }

  async openStepsModal(id?: number) {
    const modal = await this.modalController.create({
      component: StepModalPage,
      componentProps: {
        stepId: id,
        dialog: true,
      },
    });

    modal.onDidDismiss().then(() => {
      this.listSteps();
    });

    return await modal.present();
  }

  async presentAlert(id: number) {
    const alert = await this.alertController.create({
      header: 'Deseja deletar esse setor?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Não',
          cssClass: 'alert-button-cancel',
          role: 'cancel',
        },
        {
          text: 'Sim',
          cssClass: 'alert-button-confirm',
          id: 'alert-button-confirm',
          handler: () => {
            this.deleteSteps(id);
          },
        },
      ],
    });

    await alert.present();
  }

  ngOnInit() {
    this.listSteps();
  }
}
