import { ApplicationRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Product } from '../../models/product.model';
import { DepositModel } from '../../models/deposit.model';

type Option = Product | DepositModel;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  options: Array<Option> = [];
  filteredOptions: Array<Option> = [];
  selectedOption: Option;
  propertyToSearch = 'fullDescription';
  itemToSelect = '';

  constructor(private modalController: ModalController, private appRef: ApplicationRef) {}

  ngOnInit() {
    this.filteredOptions = this.options;
  }

  async closeModal() {
    await this.modalController.dismiss().catch((error) => {
      if (error.message === 'overlay does not exist trown') {
        return;
      }
    });
  }

  filter(event: any) {
    this.filteredOptions = this.options;
    const textToFilter = event.target.value;
    if (textToFilter && textToFilter.trim() !== '' && Array.isArray(this.filteredOptions)) {
      this.filteredOptions = this.filteredOptions.filter(
        (option) => option[this.propertyToSearch].toLowerCase().indexOf(textToFilter.toLowerCase()) > -1,
      );
    }
    this.appRef.tick();
  }

  async setSelectedProduct(option: Option) {
    this.selectedOption = option;
    await this.modalController.dismiss(this.selectedOption).catch((error) => {
      if (error.message === 'overlay does not exist trown') {
        return;
      }
    });
  }
}
