import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { ProductionOrder } from '../../models/production-orders.model';
import { DateBrPipe } from '../../pipes/date/date-br.pipe';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { IStep } from '../../models/step.model';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { SubproductRangeComponent } from '../subproduct-range/subproduct-range.component';
import { payloadSubProduct } from 'src/app/models/payloadSubproduct.model';

@Component({
  selector: 'app-painting-production-order-item',
  templateUrl: './painting-production-order-item.component.html',
  styleUrls: ['./painting-production-order-item.component.scss'],
  providers: [DateBrPipe],
})
export class PaintingProductionOrderItemComponent implements OnInit, OnChanges {
  @Input() productionOrder: ProductionOrder;
  @Input() userType: any;
  @Input() step: IStep;
  @Input() groupBy: any;
  @Input() all: boolean;
  @Input() inShareMode: boolean;
  @Input() parentProductionOrderId: number;
  @Input() parentProductionOrderFiberType: number;

  @Output() delete = new EventEmitter<number>();
  @Output() turnShareModeOn = new EventEmitter<number>();
  @Output() getParentProductionOrderFiberType = new EventEmitter<number>();
  @Output() pushOpToHaveSameParentId = new EventEmitter<number>();
  @Output() popOfSameParentIdList = new EventEmitter<number>();
  @ViewChild('slidingOptions') slidingOptions;

  selectShareMode: boolean;
  isParentOp: boolean;
  isSameParentOpType: boolean;

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private dateBrPipe: DateBrPipe,
    private alertCtrl: AlertController,
    public productionOrderService: ProductionOrdersService,
  ) {}

  ngOnInit() {
    this.isParentOp = this.parentProductionOrderId === this.productionOrder.id;
    this.isSameParentOpType = this.parentProductionOrderFiberType === this.productionOrder.product.type.id;
  }

  ngOnChanges() {
    this.isParentOp = this.parentProductionOrderId === this.productionOrder.id;
    this.isSameParentOpType = this.parentProductionOrderFiberType === this.productionOrder.product.type.id;
  }

  setSelectMode() {
    this.selectShareMode = true;
  }

  dateBr(date, time: boolean, convertToLocalTimeZone: boolean) {
    return this.dateBrPipe.transform(date, time, convertToLocalTimeZone);
  }

  createMsgCoilsAndLength(productionOrder: any) {
    if (productionOrder.step === 1) {
      return productionOrder.totalLength;
    } else {
      const plural = productionOrder.slicesRequest.amount > 1 ? 's' : '';
      return productionOrder.slicesRequest.amount
        ? productionOrder.slicesRequest.amount +
            ' lance' +
            plural +
            '  de ' +
            productionOrder.slicesRequest.length +
            'm'
        : 'nenhum lance informado';
    }
  }

  redirectToDetailPage(productionOrderId: number) {
    if (this.step.id === 1 || this.step.id === 2) {
      this.router.navigate([`/menu/productionOrders/${productionOrderId}`]);
    } else {
      this.router.navigate([`/menu/genericProductionOrders/${productionOrderId}`]);
    }
  }

  expand(externalCode: string) {
    this.router.navigate(
      [`/menu/steps/${this.step.id}/externalCode/${encodeURIComponent(externalCode)}/productionOrders`],
      {
        queryParams: { groupBy: false, all: this.all },
      },
    );
  }

  shoulShowFiberType() {
    return ![3, 4, 5, 6].includes(this.step.id);
  }

  shouldShowSlug() {
    return [2, 3, 4, 5, 6].includes(this.step.id);
  }

  async deleteConfirmation(productionOrderId: number) {
    const removeProductionOrderAlert = await this.alertCtrl.create({
      header: 'Confirmar',
      message: `Deseja <strong>excluir</strong> essa ordem de produção de ${this.step.description}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.delete.emit(0);
          },
        },
        {
          text: 'Excluir',
          handler: () => {
            this.delete.emit(productionOrderId);
          },
        },
      ],
    });
    await removeProductionOrderAlert.present();
  }

  async openModalToCreateSubProduct() {
    const modal = await this.modalCtrl.create({
      component: SubproductRangeComponent,
      cssClass: 'subProductRangePage',
    });

    await modal.present();

    await modal
      .onDidDismiss()
      .then((res) => {
        if (res.data) {
          this.createOpOfSubProducts(res.data);
        }
      })
      .catch((err) => {
        if (err.message === 'overlay does not exist trown') {
          return;
        }
      });
  }

  createOpOfSubProducts(data: payloadSubProduct) {
    this.productionOrderService.createOpOfSubProducts(this.productionOrder.id, data).subscribe(async () => {
      const subProductCreatedAlert = await this.alertCtrl.create({
        header: 'Confirmar',
        message: `Semiacabados da ordem de produção ${this.productionOrder.externalCode} foram abertas com sucesso!`,
        cssClass: '.createdSubProductsAlert',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => {
              this.slidingOptions.closeSlidingItems();
            },
          },
        ],
      });
      await subProductCreatedAlert.present();
    });
  }

  toggleOpToParent(isSelected): void {
    if (isSelected) {
      this.pushOpToHaveSameParentId.emit(this.productionOrder.id);
    } else {
      this.popOfSameParentIdList.emit(this.productionOrder.id);
    }
  }

  beginShareMode() {
    this.turnShareModeOn.emit(this.productionOrder.parentProductionOrderId);
    this.getParentProductionOrderFiberType.emit(this.productionOrder.product.type.id);
    this.slidingOptions.closeSlidingItems();
  }

  verifyOpIsSelected() {
    return this.productionOrder.parentProductionOrderId === this.parentProductionOrderId;
  }
}
