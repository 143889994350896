import { ApplicationRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryItem } from 'src/app/models/InventoryItem.model';
import { DateBrPipe } from 'src/app/pipes/date/date-br.pipe';
import { InventoryService } from 'src/app/services/inventory/invetory.service';

@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.scss'],
})
export class InventoryItemComponent {
  @Input() item: InventoryItem;
  @Output() reload = new EventEmitter<any>();
  constructor(private dateBrPipe: DateBrPipe, private router: Router, public stockTakingService: InventoryService) {}

  dateBr(date: string) {
    return this.dateBrPipe.transform(date, false, true, false);
  }

  redirectToDetailPage() {
    this.router.navigate([`/menu/stockTakingOrder/${this.item.id}`]);
  }

  deleteStockTakingOrder(id: number) {
    this.stockTakingService.deleteStockTakingOrder(id).subscribe(() => {
      this.reload.emit();
    });
  }
}
