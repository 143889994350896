import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { AddItemComponent } from '../add-item/add-item.component';

@Component({
  selector: 'app-qrcode-reader',
  templateUrl: './qrcode-reader.component.html',
  styleUrls: ['./qrcode-reader.component.scss'],
})
export class QrcodeReaderComponent implements OnInit {
  @Input() stockTakingOrderId: number;
  showLigthBtn = false;
  showCameraScanner: boolean;
  value: any;
  read = false;

  constructor(public toastController: ToastController, private modalCtrl: ModalController) {}

  ngOnInit() {
    // correção para um fix do type PermissionName
    const permissionName = 'camera' as PermissionName;
    navigator.permissions.query({ name: permissionName }).then((res) => {
      if (res.state === 'denied') {
        this.showCameraScanner = false;
      } else {
        this.camerasFoundHandler();
      }
    });
  }

  async presentToast(message: string, color = 'primary', duration = 2000) {
    const toast = await this.toastController.create({
      message,
      duration,
      color,
    });
    toast.present();
  }

  async scanSuccessHandler(event: any) {
    this.value = this.valueParser(event);
    await this.presentToast('Leitura feita com sucesso!', 'success', 1000);
    if (!this.read) {
      this.read = true;
      await this.openManual();
    }
  }

  async scanErrorHandler() {
    await this.presentToast('Erro na Leitura', 'danger');
  }

  async closeModal() {
    await this.modalCtrl.dismiss(this.value).catch((error) => {
      if (error.message === 'overlay does not exist trown') {
        return;
      }
    });
  }

  camerasFoundHandler() {
    this.showCameraScanner = true;
  }

  async openManual() {
    const modal = await this.modalCtrl.create({
      component: AddItemComponent,
      componentProps: {
        anotherComponentInfo: this.value,
        stockTakingOrderId: this.stockTakingOrderId,
      },
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.closeModal();
    });
  }

  valueParser(value: string) {
    const properties = value.split('\n');
    const obj = {
      Codigo: 'productCode',
      Descricao: 'productDescription',
      Matricula: 'registration',
      Lote: 'lot',
      Acond: 'packageId',
      Quantidade: 'quantity',
      Unidade: 'unity',
      'Manual de Operacao': '',
      'Ficha Tecnica': 'stickerNumber',
    };
    const item = {};
    properties.forEach((info, index) => {
      if (index !== 0) {
        const [key, value] = info.split(':');
        if (obj[key] !== '') {
          const valueClean = value.trim();
          item[obj[key]] = valueClean === '-' ? '' : valueClean;
        }
      }
    });

    return item;
  }
}
