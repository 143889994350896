import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FiberType } from '../../models/fiberType.model';
import { lastValueFrom, Observable } from 'rxjs';
import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FiberTypesService {
  constructor(private http: HttpClient) {}

  list(): Observable<FiberType[]> {
    return this.http
      .get<FiberType[]>(BackendConfig.fiberTypesUrl(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  async listByStepId(stepId: number): Promise<FiberType[]> {
    const fiberTypesObservable = this.http
      .get<FiberType[]>(BackendConfig.fiberTypesByStepUrl(stepId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
    return await lastValueFrom(fiberTypesObservable);
  }

  async create(productId: number): Promise<FiberType> {
    const fiberTypeObservable = this.http
      .post<FiberType>(BackendConfig.fiberTypesUrl(), productId, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
    return await lastValueFrom(fiberTypeObservable);
  }

  async update(fiberType: any): Promise<FiberType> {
    const fiberTypeObservable = this.http
      .put<FiberType>(BackendConfig.updateFyberType(fiberType.id), fiberType, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
    return await lastValueFrom(fiberTypeObservable);
  }

  async deleteFiberType(TypeId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .delete(BackendConfig.deleteFiberType(TypeId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }

  async getFiberDetails(fiberTypeId: number): Promise<FiberType> {
    const fiberTypeObservable = this.http
      .get<FiberType>(BackendConfig.getFiberTypeDetails(fiberTypeId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
    return await lastValueFrom(fiberTypeObservable);
  }
}
