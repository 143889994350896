import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import moment from 'moment';

import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { DateBrPipe } from 'src/app/pipes/date/date-br.pipe';
import { AddSubProductPage } from '../../add-sub-product/add-sub-product.page';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';
import { UpdateSlicesComponent } from 'src/app/components/update-slices/update-slices.component';
import { UserModel } from 'src/app/models/user-model';
import { PackagesService } from 'src/app/services/packages/packages.service';

@Component({
  selector: 'app-production-order',
  templateUrl: './production-order.page.html',
  styleUrls: ['./production-order.page.scss'],
  providers: [DateBrPipe],
})
export class ProductionOrderPage implements OnInit {
  productionOrder: any;
  showPaintings: boolean;
  reload: boolean;
  showSubProducts = false;
  initialSubstepInputs: any;
  wholeLineInitialSubstepInputs: Array<any> = [];
  userType: string;
  minDeliveryDate: string = new Date().toISOString().substring(0, 10);
  maxDeliveryDate = new Date().getFullYear() + 1 + '-12-31';
  packages: any;

  constructor(
    private dateBrPipe: DateBrPipe,
    private activatedRoute: ActivatedRoute,
    private productionOrdersService: ProductionOrdersService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private authService: AuthenticateService,
    private router: Router,
    private packagesService: PackagesService,
    private navCtrl: NavController,
  ) {
    this.authService.getUser().subscribe((user: UserModel) => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        this.userType = user.type;
      }
    });
  }

  ngOnInit() {
    this.loadPackages();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.reload = params['reload'];
    });
    const productionOrderId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    this.loadProductionOrder(productionOrderId, false);
  }

  ionViewWillEnter() {
    if (this.reload) {
      this.loadProductionOrder(this.productionOrder.id);
    }
  }

  loadPackages() {
    this.packagesService.list().subscribe((packages) => (this.packages = packages));
  }

  loadProductionOrder(id: number, showReserved?: boolean) {
    this.productionOrder = null;
    this.productionOrdersService.get(id).subscribe(
      (productionOrder) => {
        if (productionOrder) {
          moment.locale('pt-BR');
          productionOrder.dueDate = moment.utc(productionOrder.dueDate).format('YYYY-MM-DD');
          this.productionOrder = productionOrder;
          this.setInitialSubstepInputs();
          if (showReserved) {
            this.openReservedPaintings();
          } else {
            this.showPaintings = false;
          }
        }
      },
      (error) => {
        this.router.navigate(['/']);
      },
    );
  }

  loadProductionOrderWhitShowPaintings(id: any) {
    this.loadProductionOrder(id, true);
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'Em andamento':
        return 'warning';
      case 'Aguardando Seleção':
        return 'primary';
      case 'Pendente':
        return 'danger';
      case 'Finalizada':
        return 'success';
      default:
        return 'light';
    }
  }

  dateBr(date: string) {
    return this.dateBrPipe.transform(date, false);
  }

  openReservedPaintings() {
    this.showPaintings = true;
  }

  hasPaintingsOnRecipe() {
    return this.productionOrder.product.hasPaintings;
  }

  toggleReservedSubProducts() {
    this.showSubProducts = !this.showSubProducts;
  }

  setInitialSubstepInputs() {
    this.initialSubstepInputs = this.productionOrder?.product.substeps
      .find((substep) => substep.isInitial)
      ?.list[0]?.inputs.filter((input) => input.label !== 'Data' && input.value.length < 30);
    this.setWholeLineInitialSubstepInputs();
  }

  setWholeLineInitialSubstepInputs() {
    this.wholeLineInitialSubstepInputs = this.productionOrder?.product.substeps
      .find((substep) => substep.isInitial)
      ?.list[0]?.inputs.filter((input) => input.value.length > 30);
  }

  async removeSubProduct(subProduct: any) {
    const removeSubProductAlert = await this.alertCtrl.create({
      header: 'Confirmar',
      message: 'Deseja <strong>excluir</strong> este produto semi acabado?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.showSubProducts = false;
          },
        },
        {
          text: 'Excluir',
          handler: () => {
            this.showSubProducts = false;
            this.productionOrdersService.removeItem(this.productionOrder.id, subProduct.id).subscribe((_) => {
              this.loadProductionOrder(this.productionOrder.id);
            });
          },
        },
      ],
    });
    await removeSubProductAlert.present();
  }

  async addSubProduct(productId: number, productSpecId: number) {
    const subProductModal = await this.modalCtrl.create({
      component: AddSubProductPage,
      componentProps: {
        productId,
        slicesRequestLength: this.productionOrder.slicesRequest.length,
        productionOrderId: this.productionOrder.id,
        productSpecId,
      },
    });

    subProductModal.onDidDismiss().then(() => {
      this.loadProductionOrder(this.productionOrder.id, false);
    });

    subProductModal.present();
  }

  async openSliceUpdate() {
    if (this.userType === 'admin') {
      const slices = {
        length: this.productionOrder.slicesRequest.length,
        amount: this.productionOrder.slicesRequest.amount,
      };
      const sliceModal = await this.modalCtrl.create({
        component: UpdateSlicesComponent,
        componentProps: {
          slices,
          id: this.productionOrder.id,
        },
      });

      await sliceModal.present();
      await sliceModal.onDidDismiss();
      this.loadProductionOrder(this.productionOrder.id, false);
    }
  }

  private isFinal(): boolean {
    return this.productionOrder.step.isFinal;
  }

  isSaveButtonEnabled(): boolean {
    return this.productionOrder?.externalCode?.length >= 3;
  }

  update() {
    if (!this.productionOrder?.package?.id || isNaN(this.productionOrder.package.id)) {
      this.productionOrder.package.id = undefined;
    } else {
      this.productionOrder.package.id = Number(this.productionOrder.package.id);
    }

    const currentStepId = this.productionOrder?.step?.id;

    this.productionOrdersService.update(this.productionOrder).subscribe((updatedOrder) => {
      this.productionOrder = updatedOrder;

      const stepId = this.productionOrder?.step?.id || currentStepId;

      this.navCtrl.navigateRoot([`/menu/steps/${stepId}/productionOrders`]);
    });
  }
}
