import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { ProductSubstepsParametersService } from '../../services/product-substep-parameters/product-substep-parameters.service';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';
import { lastValueFrom } from 'rxjs';

interface FormProps {
  id: number;
  description: string;
  index: number;
  isInitial: boolean;
  hasMachine: boolean;
  hasProductItems: boolean;
  hasApproval: boolean;
  hasLoopCreation: boolean;
  isNested: boolean;
  hasSliceCount: boolean;
  productId: number;
  predecessorSubstepId: number | null;
}
@Component({
  selector: 'app-parameter-associate-modal',
  templateUrl: './parameter-associate-modal.component.html',
  styleUrls: ['./parameter-associate-modal.component.scss'],
})
export class ParameterAssociateModalComponent implements OnInit {
  productId: number;
  substepId: number;
  predecessorSubsteps = [];
  formPayload: FormGroup;
  constructor(
    public modalController: ModalController,
    public productService: ProductSubstepsParametersService,
    public toastController: ToastController,
    private router: Router,
    private auth: AuthenticateService,
  ) {}

  async closeModal() {
    await this.modalController.dismiss();
  }
  async redirectToProductPage() {
    this.router.navigate([`/menu/config/steps/products/${this.productId}`]);
  }

  async submitCreate() {
    try {
      await this.productService.createSubstepsLinkWithProduct(this.formPayload.value);
      this.openToast('Substep criado com sucesso', 'success');
      this.closeModal();
      this.redirectToProductPage();
    } catch (e) {
      this.openToast('Erro ao criar substep', 'danger');
      this.closeModal();
    }
  }
  async submitEdit() {
    this.auth.getUser().subscribe(async (data: any) => {
      await this.productService
        .editSubstep({ ...this.formPayload.value, user: data.email })
        .then(() => {
          this.openToast('Substep editado com sucesso', 'success');
          this.closeModal();
          this.redirectToProductPage();
        })
        .catch(() => {
          this.openToast('Erro ao editar substep', 'danger');
          this.closeModal();
        });
    });
  }

  //Made this function because the ion-select return strings, and cant work with null values
  async setSelectValue(value: string) {
    const formattedValue = value ? (value === '' ? null : parseInt(value)) : null;

    this.formPayload.patchValue({ predecessorSubstepId: formattedValue });
  }
  async openToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
      position: 'top',
    });
    toast.present();
  }
  async createForm(data?: FormProps) {
    //If data is undefined, it means that the modal is being used to create a new substep
    this.formPayload = new FormGroup({
      id: new FormControl(data?.id ?? null),
      description: new FormControl(data?.description ?? ''),
      index: new FormControl(data?.index ?? 0),
      isInitial: new FormControl(data?.isInitial ?? false),
      hasMachine: new FormControl(data?.hasMachine ?? false),
      hasProductItems: new FormControl(data?.hasProductItems ?? false),
      hasApproval: new FormControl(data?.hasApproval ?? false),
      hasLoopCreation: new FormControl(data?.hasLoopCreation ?? false),
      isNested: new FormControl(data?.isNested ?? false),
      hasSliceCount: new FormControl(data?.hasSliceCount ?? false),
      productId: new FormControl(this.productId),
      predecessorSubstepId: new FormControl(data?.predecessorSubstepId ?? null),
    });
  }
  async getPredecessorSubsteps() {
    await this.productService
      .getPredecessorSubsteps(this.substepId ?? 0)
      .then((res) => {
        this.predecessorSubsteps = [{ id: null, description: 'Nenhum' }, ...res];
      })
      .catch(() => {
        this.openToast('Erro ao buscar substeps', 'danger');
      });
  }
  async getSubstepsInfosToEdit() {
    await this.productService
      .getSubstepById(this.productId, this.substepId)
      .then((res) => {
        this.createForm(res);
      })
      .catch(() => {
        this.openToast('Erro ao buscar substep', 'danger');
      });
  }

  async ngOnInit() {
    await this.getPredecessorSubsteps();
    if (!this.substepId) {
      await this.createForm();
    } else {
      await this.getSubstepsInfosToEdit();
    }
  }
}
