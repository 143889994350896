import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-config-header',
  templateUrl: './config-header.component.html',
  styleUrls: ['./config-header.component.scss'],
})
export class ConfigHeaderComponent {
  @Input() title: string;
  @Input() modal: boolean = false;

  constructor(public modalController: ModalController) {}

  closeModal(reload = false) {
    this.modalController.dismiss(reload);
  }
}
