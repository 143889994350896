import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Item } from '../../models/item.model';
import { FiberModel } from '../../models/fiber.model';
import { Observable } from 'rxjs';
import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FibersService {
  constructor(private http: HttpClient) {}

  list(productionOrderType: string): Observable<Item[]> {
    let url = BackendConfig.stockUrl();
    if (productionOrderType) {
      url += `?type=${productionOrderType}`;
    }
    return this.http.get<Item[]>(url, BackendConfig.httpOptions()).pipe(catchError(BackendConfig.handleError));
  }

  add(fibers: Item[], productionOrderId): Observable<Item> {
    return this.http
      .patch<Item>(
        BackendConfig.productionOrdersFibersUrl(productionOrderId),
        this.serialize(fibers),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  listPaintings(fiberId: string) {
    return this.http
      .get(BackendConfig.fibersId(fiberId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  deletePainting(paintingId: number) {
    return this.http.delete(BackendConfig.paintings(paintingId), BackendConfig.httpOptions());
  }

  addNewFibers(fibers: FiberModel[]) {
    return this.http
      .post(BackendConfig.fibers(), { fibers }, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  private serialize(fibers: Item[]) {
    const fiberObjects = [];
    for (const fiber of fibers) {
      const fiberObject = {
        id: fiber.id,
      };
      fiberObjects.push(fiberObject);
    }

    return { fibers: fiberObjects };
  }
}
