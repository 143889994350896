import { Component, OnInit, Input, ChangeDetectorRef, ApplicationRef, AfterViewChecked } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { MachinesService } from 'src/app/services/machines/machines.service';
import { MachineModel } from 'src/app/models/machine.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';

@Component({
  selector: 'app-setup-form',
  templateUrl: './setup-form.page.html',
  styleUrls: ['./setup-form.page.scss'],
})
export class SetupFormPage implements OnInit, AfterViewChecked {
  @Input() productionOrderId: any;
  @Input() product: any;
  isLoading: boolean;
  totalSelected: number;
  paintings: any;
  paintingsSelected: Array<any>;
  machines: MachineModel[];
  user: any;
  setupForm: FormGroup;
  productionOrderSetup: any;
  selectedPaintings: Array<any> = [];
  items: any;

  constructor(
    private modalController: ModalController,
    private productionOrdersService: ProductionOrdersService,
    private machinesService: MachinesService,
    private formBuilder: FormBuilder,
    private authService: AuthenticateService,
    private alertCtrl: AlertController,
    private changeDetector: ChangeDetectorRef,
    private appRef: ApplicationRef,
  ) {
    this.isLoading = true;
    this.totalSelected = 0;
    this.setupForm = this.formBuilder.group({
      machineId: new FormControl(null, [Validators.required]),
      thermoplasticLot: new FormControl(null, [Validators.required]),
      steelWire40Lot: new FormControl(null, [Validators.required]),
      steelWire120Lot: new FormControl(null, [Validators.required]),
      internalLot: new FormControl(null, [Validators.required]),
      userId: new FormControl(null, [Validators.required]),
      slidingLot: new FormControl(null),
      masterLot: new FormControl(null),
    });
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.productionOrderSetup = {
        machineId: null,
        thermoplasticLot: null,
        steelWire40Lot: null,
        steelWire120Lot: null,
        internalLot: null,
        userId: this.user.email,
        paintings: [],
        slidingLot: '',
        masterLot: '',
      };
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  async ngOnInit() {
    this.machines = await this.machinesService.list(2);
    this.productionOrdersService.get(this.productionOrderId).subscribe((productionOrder) => {
      this.items = productionOrder.items;
      productionOrder.product.colors.forEach((color) => (this.selectedPaintings[color.id] = 0));
    });

    this.paintingsSelected = new Array<any>();
    this.isLoading = false;
  }

  closeModal(productionOrderId) {
    this.modalController.dismiss(productionOrderId);
  }

  selectPainting(event: any, colorId: number) {
    this.selectedPaintings[colorId] = event.detail.value;
  }

  submit(setup) {
    const paintings = [];
    this.selectedPaintings.forEach((painting) => paintings.push({ id: painting }));
    setup.paintings = paintings;
    this.productionOrdersService.addSetup(this.productionOrderId, setup).subscribe((_) => {
      this.closeModal(this.productionOrderId);
    });
  }

  async changeMachine(event: any) {
    const selectedMachineId = event.detail.value;
    const loadMachineLastSetup = await this.alertCtrl.create({
      header: 'Confirmar',
      message: `Deseja carregar a <strong>última produção</strong> da máquina selecionada?`,
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.clearValues();
          },
        },
        {
          text: 'Sim',
          handler: () => {
            this.machinesService.getLastMachineSetup(selectedMachineId, this.productionOrderId).subscribe(
              (lastMachineSetup) => {
                this.productionOrderSetup.internalLot = lastMachineSetup.internalLot;
                this.productionOrderSetup.thermoplasticLot = lastMachineSetup.thermoplasticLot;
                this.productionOrderSetup.steelWire40Lot = lastMachineSetup.steelWire40Lot;
                this.productionOrderSetup.steelWire120Lot = lastMachineSetup.steelWire120Lot;
                this.productionOrderSetup.slidingLot = lastMachineSetup.slidingLot;
                this.productionOrderSetup.masterLot = lastMachineSetup.masterLot;
                this.appRef.tick();
                if (lastMachineSetup.paintings.length > 0) {
                  lastMachineSetup.paintings.forEach(
                    (painting) => (this.selectedPaintings[painting.color.id] = painting.id),
                  );
                }
              },
              (_error) => {
                this.clearValues();
              },
            );
          },
        },
      ],
    });
    await loadMachineLastSetup.present();
  }

  clearValues() {
    this.productionOrderSetup.internalLot = null;
    this.productionOrderSetup.thermoplasticLot = null;
    this.productionOrderSetup.steelWire40Lot = null;
    this.productionOrderSetup.steelWire120Lot = null;
    this.productionOrderSetup.slidingLot = '';
    this.productionOrderSetup.masterLot = '';
  }

  isPaintingsSelected() {
    const totalSelected = this.selectedPaintings.filter((painting) => painting > 0);
    return totalSelected.length === this.items.length;
  }

  steelWireDescription() {
    if (this.product.slug.indexOf('D01') >= 0 && this.product.description === 'Drop de 1 fibra - CO') {
      return 'Lote fio de aço 1,00 mm';
    }
    return 'Lote fio de aço 1,20 mm';
  }
}
