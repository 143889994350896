import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';

@Component({
  selector: 'app-update-slices',
  templateUrl: './update-slices.component.html',
  styleUrls: ['./update-slices.component.scss'],
})
export class UpdateSlicesComponent implements OnInit {
  @Input() id: number;
  @Input() slices: {
    amount: number;
    length: number;
  };

  sliceForm: FormGroup;

  constructor(private modalController: ModalController, private productionOrderService: ProductionOrdersService) {}

  ngOnInit() {
    this.sliceForm = new FormGroup({
      amount: new FormControl(this.slices.amount, [Validators.required]),
      length: new FormControl(this.slices.length, [Validators.required]),
    });
  }

  closeModal() {
    this.modalController.dismiss().catch((err) => {
      if (err.message === 'overlay does not exist trown') {
        return;
      }
    });
  }

  submit() {
    return {
      slices: {
        amount: this.sliceForm.value.amount,
        length: this.sliceForm.value.length,
      },
    };
  }

  updateSlice() {
    this.productionOrderService.updateSliceOp(this.submit(), this.id).subscribe(() => this.closeModal());
  }
}
