import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';

@Component({
  selector: 'app-pallet-form',
  templateUrl: './pallet-form.page.html',
  styleUrls: ['./pallet-form.page.scss'],
})
export class PalletFormPage implements OnInit {
  @Input() productionOrderId: any;
  palletForm: FormGroup;
  productionOrderPallet: any;
  user: any;

  constructor(
    private modalController: ModalController,
    private productionOrdersService: ProductionOrdersService,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private authService: AuthenticateService,
  ) {
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.productionOrderPallet = {
        id: 0,
        internalLot: '',
        userId: this.user.email,
      };
      this.palletForm.patchValue({
        userId: this.user.email,
      });
    });
  }

  ngOnInit() {
    this.palletForm = this.formBuilder.group({
      internalLot: new FormControl('', [Validators.required]),
      userId: new FormControl(null, [Validators.required]),
    });
  }

  closeModal(productionOrderId) {
    this.modalController.dismiss(productionOrderId);
  }

  async duplicatedPalletLotAlert() {
    const duplicatedPalletLotAlert = await this.alertCtrl.create({
      header: 'Atençao!',
      message: 'Lote de palete duplicado',
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          cssClass: 'secondary',
        },
      ],
    });
    await duplicatedPalletLotAlert.present();
  }

  submit(pallet) {
    this.productionOrdersService.addPallet(this.productionOrderId, pallet).subscribe(
      (_) => {
        this.closeModal(this.productionOrderId);
      },
      (error) => {
        if (error.status === 409) {
          this.duplicatedPalletLotAlert();
        }
      },
    );
  }
}
