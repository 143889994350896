import { Component, OnInit, Input, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { DropsService } from '../../../../services/drops/drops.service';
import { Measurements } from 'src/app/models/measurements.model';
import { AuthenticateService } from '../../../../services/auth/authentication.service';

@Component({
  selector: 'app-drops-measurement',
  templateUrl: './drops-measurement.page.html',
  styleUrls: ['./drops-measurement.page.scss'],
})
export class DropsMeasurementPage implements OnInit, AfterViewChecked {
  @Input() drop: any;
  @Input() minSliceLength: number;
  user: any;
  dropMeasurements: Measurements;
  dropMeasurementForm: FormGroup;
  loading = false;
  selectedColorId: number;

  constructor(
    private modalController: ModalController,
    private dropsService: DropsService,
    private alertController: AlertController,
    private afAuth: AuthenticateService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.dropMeasurementForm = new FormGroup({
      colorId: new FormControl(null, [Validators.required]),
      otdrLength: new FormControl(null, [Validators.required]),
      attenuation131: new FormControl(null),
      attenuation155: new FormControl(null),
      userId: new FormControl(null, [Validators.required]),
    });
    if (this.minSliceLength >= 1000) {
      this.dropMeasurementForm.controls['attenuation131'].setValidators([Validators.required]);
      this.dropMeasurementForm.controls['attenuation155'].setValidators([Validators.required]);
    }
    this.afAuth.getAuthUser().subscribe((user) => {
      this.dropMeasurementForm.get('userId').setValue(user.email);
      this.load();
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  load() {
    this.loading = true;
    this.dropsService.getMeasurements(this.drop.id).subscribe((measurements) => {
      this.dropMeasurements = measurements;

      if (this.dropMeasurements.pending.length === 0) {
        this.closeModal();
      } else {
        this.selectedColorId = this.dropMeasurements.pending[0].id;
        this.dropMeasurementForm.get('otdrLength').setValue(null);
        this.dropMeasurementForm.get('attenuation131').setValue(null);
        this.dropMeasurementForm.get('attenuation155').setValue(null);
        this.dropMeasurementForm.updateValueAndValidity();
      }
      this.loading = false;
    });
  }

  async invalidMeasureAlert(measure: any, sliceRequestLength: number) {
    const invalidMeasureAlert = await this.alertController.create({
      header: 'Atenção!',
      message: `As medições desta fibra foram salvas mas este cabo deve ser <strong>reprovado</strong>.`,
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
          cssClass: 'primary',
        },
      ],
    });
    await invalidMeasureAlert.present();
  }

  addMeasure(measure: any) {
    this.loading = true;
    this.dropsService.addMeasure(this.drop.id, measure).subscribe(
      (measureResponse) => {
        this.load();
        if (!measureResponse['valid']) {
          this.invalidMeasureAlert(measure, measureResponse['sliceRequestLength']);
        }
        this.loading = false;
      },
      (error) => {
        if (error.status === 412) {
          this.newWarningAlert(error.error.message);
        }
        this.loading = false;
      },
    );
  }

  async newWarningAlert(message: any) {
    const warningAlert = await this.alertController.create({
      header: 'Atençao!',
      message,
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          cssClass: 'secondary',
        },
      ],
    });
    await warningAlert.present();
  }

  validateOtdrLength(measure) {
    return new Promise((resolve, reject) => {
      if (
        measure.otdrLength < this.minSliceLength ||
        ((measure.attenuation131 < this.drop.limits.attenuation131.min ||
          measure.attenuation131 > this.drop.limits.attenuation131.max ||
          measure.attenuation155 < this.drop.limits.attenuation155.min ||
          measure.attenuation155 > this.drop.limits.attenuation155.max) &&
          measure.otdrLength > 1000)
      ) {
        this.alertController
          .create({
            header: 'Atenção!',
            message: `Esta fibra possuí medições inválidas. <br/>Se você confirmar este cabo deverá ser reprovado.`,
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  reject();
                },
              },
              {
                text: 'Confirmar',
                role: 'ok',
                cssClass: 'primary',
                handler: () => {
                  resolve(null);
                },
              },
            ],
          })
          .then((alert) => alert.present());
      } else {
        resolve(null);
      }
    });
  }
  submit(measure) {
    this.validateOtdrLength(measure)
      .then(() => this.addMeasure(measure))
      .catch((e) => console.error(e));
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
