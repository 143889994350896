import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';
import { PackageModel } from 'src/app/models/package.model';

@Injectable({
  providedIn: 'root',
})
export class PackagesService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get<PackageModel[]>(BackendConfig.packagesUrl(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }
}
