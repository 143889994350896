import { Component, Input, OnInit } from '@angular/core';
import { SubstepsService } from 'src/app/services/substep/substep.service';

@Component({
  selector: 'app-tag-generic-intermediary',
  templateUrl: './tag-generic-intermediary.component.html',
  styleUrls: ['./tag-generic-intermediary.component.scss'],
})
export class TagGenericIntermediaryComponent implements OnInit {
  @Input() productionOrderId: number;
  @Input() substepId: number;
  @Input() productionOrdersSubstepId: number;
  qrCodeData: string;
  isTest = false;
  shouldShowLabel = false;
  productionOrder: any;
  pckgs: any;
  intermediaryTagData: any;

  constructor(private substepService: SubstepsService) {}

  ngOnInit() {
    this.loadInfoToPrint();
  }

  generateQRCodeData() {
    this.qrCodeData = this.intermediaryTagData.rows[6].columns[1].value;
    this.shouldShowLabel = true;
  }

  print() {
    if (!this.isTest) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  loadInfoToPrint() {
    this.substepService
      .getPrint(this.productionOrderId, this.substepId, this.productionOrdersSubstepId)
      .subscribe(async (print) => {
        this.intermediaryTagData = print;
        this.generateQRCodeData();
        this.print();
      });
  }
}
