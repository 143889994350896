import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { modalController } from '@ionic/core';
import { ProductSubstepsParametersService } from '../../services/product-substep-parameters/product-substep-parameters.service';

@Component({
  selector: 'app-confirm-disassociate-remove-modal',
  templateUrl: './confirm-disassociate-remove-modal.component.html',
  styleUrls: ['./confirm-disassociate-remove-modal.component.scss'],
})
export class ConfirmDisassociateRemoveModalComponent {
  productId: number;
  substepId: number;
  inputId: number;
  constructor(public productService: ProductSubstepsParametersService, public modalController: ModalController) {}

  async disassociateParameter() {
    try {
      const response = await this.productService.disassociateParameter(this.productId, this.substepId, this.inputId);
      this.modalController.dismiss({
        message: response.message,
        color: 'success',
      });
    } catch (res) {
      this.modalController.dismiss({
        message: res.error.message,
        color: 'danger',
      });
      return;
    }
  }

  async removeParameter() {
    try {
      const response = await this.productService.removeParameter(this.inputId);
      this.modalController.dismiss({
        message: response.message,
        color: 'success',
      });
    } catch (res) {
      this.modalController.dismiss({
        message: res.error.message,
        color: 'danger',
      });
      return;
    }
  }
}
