import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SearchComponent } from 'src/app/components/search-component/search.component';
import { DepositModel } from 'src/app/models/deposit.model';
import { InventoryService } from 'src/app/services/inventory/invetory.service';

@Component({
  selector: 'app-inventory-order',
  templateUrl: './inventory-order.page.html',
  styleUrls: ['./inventory-order.page.scss'],
})
export class InventoryOrderPage implements OnInit {
  inventoryOrderForm: any = null;
  selectedDeposit: DepositModel = null;
  units = null;
  disableWarehouseSearch = true;
  minDeliveryDate: string = new Date().toISOString().substring(0, 10);
  maxDeliveryDate = new Date().getFullYear() + 1 + '-12-31';

  constructor(private router: Router, private modalCtrl: ModalController, private inventoryService: InventoryService) {}

  ngOnInit() {
    this.inventoryOrderForm = new FormGroup({
      name: new FormControl('', Validators.required),
      depositId: new FormControl('', Validators.required),
      from: new FormControl('', [Validators.required]),
      to: new FormControl('', [Validators.required]),
      stickerInitialNumber: new FormControl('', [Validators.required, Validators.min(1)]),
      stickerFinalNumber: new FormControl('', [Validators.required, Validators.min(2)]),
      countingRounds: new FormControl('', [Validators.required, Validators.min(1), Validators.max(2)]),
    });

    this.listUnits();
  }

  backToInventoryList() {
    this.router.navigate(['/menu/inventory-list']);
  }

  submit() {
    this.inventoryService.postStockTakingOrder(this.inventoryOrderForm.value).subscribe(() => {
      this.backToInventoryList();
    });
  }

  async openSearchComponent() {
    const searchModal = await this.modalCtrl.create({
      component: SearchComponent,
      componentProps: {
        options: this.units,
        propertyToSearch: 'fullDescription',
        itemToSelect: 'Depósito',
      },
    });

    searchModal.onDidDismiss().then((deposit) => {
      if (!deposit.data?.id) {
        return;
      }
      this.inventoryOrderForm.patchValue({ depositId: deposit.data.id });
      this.selectedDeposit = deposit.data;
    });

    searchModal.present();
  }

  listUnits() {
    this.inventoryService.list().subscribe((units) => {
      this.units = units;
      this.disableWarehouseSearch = false;
    });
  }
}
