import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { MachineModel } from 'src/app/models/machine.model';
import { StepsService } from '../../services/steps/steps.service';

@Component({
  selector: 'app-steps-machine-modal',
  templateUrl: './steps-machine-modal.component.html',
  styleUrls: ['./steps-machine-modal.component.scss'],
})
export class StepsMachineModalComponent implements OnInit {
  formPayload: FormGroup;
  stepId: number;
  machine?: MachineModel;
  edit: boolean = false;

  constructor(
    public modalController: ModalController,
    public stepsService: StepsService,
    public toastController: ToastController,
  ) {}

  async createForm() {
    if (this.edit) {
      this.formPayload = new FormGroup({
        description: new FormControl(this.machine.description),
        code: new FormControl(this.machine.code),
        isActive: new FormControl(this.machine.isActive),
      });
    } else {
      this.formPayload = new FormGroup({
        stepId: new FormControl(this.stepId),
        description: new FormControl(null),
        code: new FormControl(null),
        isActive: new FormControl(false),
      });
    }
  }

  async submitCreate() {
    try {
      const response = await this.stepsService.createStepMachine(this.formPayload.value);
      this.closeModal(true);
    } catch (error) {
      this.closeModal(false);
    }
  }
  async submitEdit() {
    try {
      const response = await this.stepsService.editStepMachine(this.machine.id, this.formPayload.value);
      this.closeModal(true);
    } catch (error) {
      this.closeModal(false);
    }
  }

  async closeModal(reload: boolean) {
    await this.modalController.dismiss(reload);
  }

  async ngOnInit() {
    this.edit = this.machine !== null && this.machine !== undefined;
    await this.createForm();
  }
}
