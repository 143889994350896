import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaintListPage } from 'src/app/pages/fibers/paint/paint-list/paint-list.page';
import { AlertController, ModalController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/auth/authentication.service';
import { UserModel } from 'src/app/models/user-model';
@Component({
  selector: 'app-paintings-reserved',
  templateUrl: './paintings-reserved.component.html',
  styleUrls: ['./paintings-reserved.component.scss'],
})
export class PaintingsReservedComponent {
  @Output() reloadProductionOrder = new EventEmitter<number>();
  @Input() productionOrder: any;
  @Input() showPaintings = false;
  @Input() stepType: number;
  customColumnSize: number;
  userType: string;
  allowStep: number[] = [5, 4];

  constructor(
    private modalCtrl: ModalController,
    private productionOrdersService: ProductionOrdersService,
    private alertCtrl: AlertController,
    private authService: AuthenticateService,
    private router: Router,
  ) {
    this.authService.getUser().subscribe((user: UserModel) => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        this.userType = user.type;
      }
    });
  }

  async addPaintings(color: any, type: any) {
    const paitingsModal = await this.modalCtrl.create({
      component: PaintListPage,
      componentProps: {
        productionOrderId: this.productionOrder.id,
        color,
        type,
        sliceRequestLength: this.productionOrder.slicesRequest.length,
      },
    });
    paitingsModal.onDidDismiss().then((resp) => {
      if (resp.data) {
        this.reloadProductionOrder.emit(this.productionOrder.id);
      }
    });
    paitingsModal.present();
  }

  async removePainting(painting: any) {
    const removePaintingAlert = await this.alertCtrl.create({
      header: 'Confirmar',
      message: 'Deseja <strong>excluir</strong> essa pintura?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.showPaintings = false;
          },
        },
        {
          text: 'Excluir',
          handler: () => {
            this.showPaintings = false;
            this.productionOrdersService.removeItem(this.productionOrder.id, painting.id).subscribe((_) => {
              this.reloadProductionOrder.emit(this.productionOrder.id);
            });
          },
        },
      ],
    });
    await removePaintingAlert.present();
  }

  shouldShowRemoveButton(fiber: any): boolean {
    if (this.allowStep.includes(this.stepType)) {
      return true;
    } else {
      return fiber.canDeleteThisSubProduct && this.userType === 'admin';
    }
  }

  shouldShowAddButton(): boolean {
    if (this.allowStep.includes(this.stepType)) {
      return true;
    } else {
      return this.userType === 'admin';
    }
  }
}
