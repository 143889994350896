import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { genericPrint, column } from 'src/app/models/genericPrint.model';
import { SubstepsService } from 'src/app/services/substep/substep.service';
import { DateBrPipe } from '../../pipes/date/date-br.pipe';
import { tlTagMock } from './tl-tag-mock';

@Component({
  selector: 'app-tubo-loose-tag',
  templateUrl: './tubo-loose-tag.component.html',
  styleUrls: ['./tubo-loose-tag.component.scss'],
  providers: [DateBrPipe],
  encapsulation: ViewEncapsulation.None,
})
export class TuboLooseTagComponent implements OnInit {
  constructor(private substepService: SubstepsService, private dateBrPipe: DateBrPipe) {}

  @Input() productionOrderId: number;
  @Input() substepId: number;
  @Input() productionOrdersSubstepId: number;
  @Input() callWindowPrint = false;
  tagToPrint: genericPrint;
  qrCodeValue: string;
  qrCodeRow: number;
  statusOptions = ['APROVADO', 'NÃO APROVADO'];

  ngOnInit() {
    this.loadInfoToPrint();
    if (this.callWindowPrint) {
      setTimeout(() => {
        window.print();
      }, 3000);
    }
  }

  dateBr(date: string) {
    return this.dateBrPipe.transform(date, false);
  }

  loadInfoToPrint() {
    this.substepService
      .getPrint(this.productionOrderId, this.substepId, this.productionOrdersSubstepId)
      .subscribe(async (print) => {
        print.rows.forEach((row, index) => {
          row.columns.forEach((column) => {
            if (column.isQrCode) {
              this.qrCodeRow = index;
              this.qrCodeValue = String(column.value);
            }

            if (column.label === 'OBS.:') {
              column.value = String(column?.value).substring(0, 20);
            }
          });
        });
        this.tagToPrint = print;
      });
  }

  rowHeigth(row: { columns: Array<column> }) {
    const haveImage = row.columns.filter((column: column) => column.isLogo);
    if (haveImage.length) {
      return '50px';
    }

    let labelsPerColumn = 0;
    let columnPerRow = 0;
    row.columns.forEach((column: column) => {
      labelsPerColumn += Object.keys(column).length;
      columnPerRow++;
    });

    const isHeader = row.columns.filter((column: column) => column.isHeader);
    const isTableTitle = row.columns.filter((column: column) => column.isTableTitle);
    const infosPerColumn = labelsPerColumn / columnPerRow;

    if (infosPerColumn === 1 || isTableTitle.length || isHeader.length) {
      return '17px';
    }

    const hasApproval = row.columns.find((column: column) => this.statusOptions.includes(String(column?.value)));
    if (hasApproval) {
      return '30px';
    }

    return '40px';
  }

  setWidth(column: column) {
    const valueLength = String(column.value).length;
    if (valueLength >= 29 && valueLength < 39) {
      return 'auto';
    }
    if (valueLength !== 39) {
      return 'max-content';
    }
    if (valueLength === 39) {
      return '273px';
    }
  }

  setWidthRow(row: { columns: Array<column> }) {
    if (row.columns.length === 1) {
      return 'auto';
    }
  }

  size(column: column) {
    const hasApproval = this.statusOptions.includes(String(column?.value));
    const valueLength = String(column.value).length;
    if (column.label === 'DATA:' || hasApproval) {
      return '5';
    }
    if (column.isQrCode) {
      return 'auto';
    }
    if (column.isLogo || this.statusOptions.includes(String(column.value))) {
      return '4';
    }
    if (column.label === 'DESCRIÇÃO' || (valueLength >= 25 && valueLength <= 39)) {
      return 'auto';
    }

    return '3';
  }

  alignText(column: column) {
    if ((!column.label && column.value) || (column.label && !column.value) || column.isTableTitle) {
      return 'center';
    }
  }

  setFonteSize(column: column, index: number) {
    if (column?.label === 'DATA:') {
      return '14px';
    }
    if (index === 1) {
      return '12px';
    }
    if (this.statusOptions.includes(String(column.value))) {
      return '16px';
    }

    return '10px';
  }
}
