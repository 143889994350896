import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule) },
  { path: '', loadChildren: () => import('./pages/menu/menu/menu.module').then((m) => m.MenuPageModule) },
  { path: 'insert', loadChildren: () => import('./pages/fibers/insert/insert.module').then((m) => m.InsertPageModule) },
  {
    path: 'slice-form',
    loadChildren: () =>
      import('./pages/production-orders/slice-form/slice-form.module').then((m) => m.SliceFormPageModule),
  },
  {
    path: 'pallet-form',
    loadChildren: () =>
      import('./pages/production-orders/pallets/pallet-form/pallet-form.module').then((m) => m.PalletFormPageModule),
  },
  {
    path: 'drop-pallet-details',
    loadChildren: () =>
      import('./pages/production-orders/pallets/drop-pallet-details/drop-pallet-details.module').then(
        (m) => m.DropPalletDetailsPageModule,
      ),
  },
  {
    path: 'setup-details',
    loadChildren: () =>
      import('./pages/production-orders/setups/setup-details/setup-details.module').then(
        (m) => m.SetupDetailsPageModule,
      ),
  },
  {
    path: 'drops-list',
    loadChildren: () =>
      import('./pages/production-orders/drops/drops-list/drops-list.module').then((m) => m.DropsListPageModule),
  },
  {
    path: 'paint-list',
    loadChildren: () => import('./pages/fibers/paint/paint-list/paint-list.module').then((m) => m.PaintListPageModule),
  },
  {
    path: 'drops-measurement',
    loadChildren: () =>
      import('./pages/production-orders/drops/drops-measurement/drops-measurement.module').then(
        (m) => m.DropsMeasurementPageModule,
      ),
  },
  {
    path: 'paint-return',
    loadChildren: () =>
      import('./pages/fibers/paint/paint-return/paint-return.module').then((m) => m.PaintReturnPageModule),
  },
  {
    path: 'substep',
    loadChildren: () => import('./pages/production-orders/substep/substep.module').then((m) => m.SubstepPageModule),
  },
  {
    path: 'substep-details',
    loadChildren: () =>
      import('./pages/production-orders/substep-details/substep-details.module').then(
        (m) => m.SubstepDetailsPageModule,
      ),
  },
  {
    path: 'add-sub-product',
    loadChildren: () => import('./pages/add-sub-product/add-sub-product.module').then((m) => m.AddSubProductPageModule),
  },
  {
    path: 'inventory-form',
    loadChildren: () =>
      import('./pages/inventory/inventory-form/inventory-form.module').then((m) => m.InventoryFormPageModule),
  },
  {
    path: 'units',
    loadChildren: () => import('./pages/inventory/units/units.module').then((m) => m.UnitsPageModule),
  },
  {
    path: 'add-new-product',
    loadChildren: () =>
      import('./pages/config/steps/add-new-product/add-new-product.module').then((m) => m.AddNewProductPageModule),
  },
  {
    path: 'add-new-product-substep-parameters',
    loadChildren: () =>
      import('./pages/config/steps/add-new-product-substep-parameters/add-new-product-substep-parameters.module').then(
        (m) => m.AddNewProductSubstepParametersPageModule,
      ),
  },
  {
    path: 'add-new-product-substeps',
    loadChildren: () =>
      import('./pages/config/steps/add-new-product-substeps/add-new-product-substeps.module').then(
        (m) => m.AddNewProductSubstepsPageModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
