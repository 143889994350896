import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateBrPipe } from '../../pipes/date/date-br.pipe';
import { NavController } from '@ionic/angular';
import { ProductionOrdersService } from 'src/app/services/productionOrders/production-orders.service';
import { PackagesService } from 'src/app/services/packages/packages.service';
import { SubstepsService } from 'src/app/services/substep/substep.service';

@Component({
  selector: 'app-drop-label',
  templateUrl: './drop-label.component.html',
  styleUrls: ['./drop-label.component.scss'],
  providers: [DateBrPipe],
})
export class DropLabelComponent implements OnInit {
  @Input() dropLabelData: any;
  @Input() productionOrderId: number;
  @Input() substepId: number;
  @Input() productionOrdersSubstepId: number;
  @Output() response: any = new EventEmitter<string>();
  isTest = false;
  productionOrder: any;
  pckgs: any;
  packageOfDrop: any;
  qrCodeData: string;
  shouldShowLabel = false;

  constructor(
    private dateBrPipe: DateBrPipe,
    private productionOrderService: ProductionOrdersService,
    private packageService: PackagesService,
    private navCtrl: NavController,
    private substepService: SubstepsService,
  ) {}

  ngOnInit() {
    if (this.dropLabelData) {
      this.packageService.list().subscribe((pckgs) => {
        this.pckgs = pckgs;
        this.productionOrderService.get(this.dropLabelData.productionOrder.id).subscribe((op) => {
          this.productionOrder = op;
          this.packageOfDrop = this.pckgs.find((pck: any) => pck.id === this.productionOrder.package.id);
          this.generateQRCodeData();
          this.print();
        });
      });
    } else {
      this.loadInfoToPrint();
    }
    this.closeModalEvent();
  }

  closeModalEvent() {
    this.response.emit();
  }

  dateBr(date: any) {
    return this.dateBrPipe.transform(date, false);
  }

  print() {
    if (!this.isTest) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  isVivo() {
    if (this.dropLabelData?.productionOrder.client) {
      return this.dropLabelData?.productionOrder.client.toLowerCase().indexOf('vivo') >= 0;
    }
    return false;
  }

  generateQRCodeData() {
    this.qrCodeData = this.dropLabelData.product.qrcode;
    this.shouldShowLabel = true;
  }

  loadInfoToPrint() {
    this.substepService
      .getPrint(this.productionOrderId, this.substepId, this.productionOrdersSubstepId)
      .subscribe(async (print) => {
        this.dropLabelData = print;
        if (this.dropLabelData.drop.disapproveObservation) {
          this.dropLabelData.drop.disapproveObservation = this.dropLabelData.drop.disapproveObservation.substr(0, 20);
        }
        this.generateQRCodeData();
        this.packageService.list().subscribe((pckgs) => {
          this.pckgs = pckgs;
          this.packageOfDrop = this.pckgs.find((pck: any) => pck.id === this.productionOrder?.package.id);
        });
        this.print();
      });
  }
}
