import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Substep } from 'src/app/models/substep-model';
import { SubstepPage } from 'src/app/pages/production-orders/substep/substep.page';

@Component({
  selector: 'app-nested-substep',
  templateUrl: './nested-substep.component.html',
  styleUrls: ['./nested-substep.component.scss'],
})
export class NestedSubstepComponent {
  @Input() substep: Substep;
  @Input() productionOrderId: number;
  @Input() predecessorProductionOrderSubstep: number;
  @Input() isApproved: boolean;
  @Output() reload = new EventEmitter<any>();

  constructor(private modalCtrl: ModalController) {}

  async openModal() {
    const infos = {
      productionOrderId: this.productionOrderId,
      substepId: this.substep.substepId || this.substep.id,
      predecessorProductionOrderSubstep: this.predecessorProductionOrderSubstep,
    };

    const substepModal = await this.modalCtrl.create({
      component: SubstepPage,
      componentProps: infos,
      cssClass: 'substepPage',
    });
    substepModal.onDidDismiss().then(() => {
      this.reload.emit();
    });
    substepModal.present();
  }
}
