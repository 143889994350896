import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';
import { DepositModel } from 'src/app/models/deposit.model';
import { StockTakingOrder } from 'src/app/models/stockTakingOrder-model';
import { InventoryItem } from 'src/app/models/InventoryItem.model';
import { addCountItemPayload, ItemPayload } from 'src/app/models/inventory-item.model';
import { Unit } from 'src/app/models/unit.model';
import { Area } from 'src/app/models/area.model';
import { StickerNumber } from 'src/app/models/stickerNumber.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  constructor(private http: HttpClient) {}

  list(): Observable<DepositModel[]> {
    return this.http
      .get<DepositModel[]>(`${BackendConfig.depositsUrl()}`, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  postStockTakingOrder(stockTakingOrder: StockTakingOrder): Observable<any> {
    return this.http
      .post<StockTakingOrder>(BackendConfig.stockTakingOrdersUrl(), stockTakingOrder, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getInventoryList(): Observable<InventoryItem[]> {
    return this.http
      .get<InventoryItem[]>(BackendConfig.stockTakingOrdersUrl(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getStockTakingOrderById(id: number): Observable<StockTakingOrder> {
    return this.http
      .get<StockTakingOrder>(BackendConfig.stockTakingOrderId(id), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getStickerNumber(id: number, round: number): Observable<StickerNumber> {
    return this.http
      .get<StickerNumber>(BackendConfig.stickerNumber(id, round), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  updateStockTakingOrder(payload: StockTakingOrder): Observable<StockTakingOrder> {
    return this.http
      .put<StockTakingOrder>(BackendConfig.stockTakingOrderId(payload.id), payload, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  addItem(payload: ItemPayload): Observable<ItemPayload> {
    return this.http
      .post<ItemPayload>(
        BackendConfig.stockTakingOrderItem(payload.stockTakingOrderId),
        payload,
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  addCountItem(payload: addCountItemPayload, stockTakingOrderId: number, itemId: number): Observable<any> {
    return this.http
      .post<addCountItemPayload>(
        BackendConfig.stockTakingOrderCountItem(stockTakingOrderId, itemId),
        payload,
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  deleteItem(stockTakingOrderId: number, itemId: number, roundNumber: number): Observable<any> {
    return this.http
      .delete(
        BackendConfig.stockTakingOrderIdItemId(stockTakingOrderId, itemId, roundNumber),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  deleteStockTakingOrder(stockTakingOrderId: number): Observable<any> {
    return this.http
      .delete(BackendConfig.stockTakingOrderId(stockTakingOrderId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getReportStock(id: number): Observable<any> {
    return this.http
      .get<any>(BackendConfig.reportStock(id), {
        responseType: 'blob' as 'json',
        headers: BackendConfig.httpOptions().headers,
      })
      .pipe(catchError(BackendConfig.handleError));
  }

  editStatusFinished(id: number, status: boolean): Observable<any> {
    return this.http
      .patch<any>(BackendConfig.editStatusFinished(id), { isFinished: status }, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getUnits(): Observable<Unit[]> {
    return this.http
      .get<Unit[]>(BackendConfig.unit(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  saveUnit(payload: Unit): Observable<Unit> {
    return this.http
      .post<Unit>(BackendConfig.unit(), payload, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getAreasInUnit(id: number): Observable<any> {
    return this.http
      .get<any>(BackendConfig.areaInUnit(id), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getAreasNoRelated(id: number): Observable<Area[]> {
    return this.http
      .get<any>(BackendConfig.areaNoInUnit(id), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  saveArea(id: number, payload: Unit): Observable<any> {
    return this.http
      .post<Unit>(BackendConfig.saveArea(id), payload, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  relatedAreaToUnit(payload: any): Observable<any> {
    return this.http
      .post<Unit>(BackendConfig.depositsUrl(), payload, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  deleteDeposit(depositId: number) {
    return this.http
      .delete<string>(BackendConfig.depositId(depositId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  deleteUnits(unitId: number) {
    return this.http
      .delete<string>(BackendConfig.areaInUnit(unitId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  cancelSticker(stockTakingOrderId: number, stockTakingOrderItemId: number): Observable<{ message: string }> {
    return this.http
      .patch<{ message: string }>(
        BackendConfig.cancelSticker(stockTakingOrderId, stockTakingOrderItemId),
        null,
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }
}
