import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface ICheckList {
  id: number;
  descripition: string;
  checked?: boolean;
}

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
})
export class CheckListComponent implements OnInit {
  listToReturn: number[];
  listChecked: ICheckList[] = [];
  @Input() list: ICheckList[] = [];
  @Output('listToReturn') outputEvent = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {
    this.formatToCheckedList();
  }

  formatToCheckedList(): void {
    this.list.map((item: ICheckList) => {
      this.listChecked.push({ ...item, checked: false });
    });
  }

  getAllItemsChecked() {
    const itemsChecked: number[] = this.listChecked.filter((items) => items.checked).map((items) => items.id);
    return itemsChecked;
  }

  changeChecked() {
    const itemsChecked: number[] = this.getAllItemsChecked();
    this.outputEvent.emit(itemsChecked);
  }
}
