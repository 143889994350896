import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendConfig } from '../config/backend-config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Measurements } from 'src/app/models/measurements.model';

@Injectable({
  providedIn: 'root',
})
export class DropsService {
  delete(dropId: number) {
    return this.http
      .delete(BackendConfig.dropById(dropId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  constructor(private http: HttpClient) {}

  listDrops(paintingId: number) {
    return this.http
      .get(BackendConfig.drops(paintingId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  addPaintings(productionOrderId: number, paintings: any) {
    return this.http
      .patch(
        BackendConfig.addPaintingDrops(productionOrderId),
        this.serializePaintings(paintings),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  getDrop(dropId: number) {
    return this.http
      .get<any>(BackendConfig.dropById(dropId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getMeasurements(dropId: number): Observable<Measurements> {
    return this.http
      .get<Measurements>(BackendConfig.dropMeasurementsById(dropId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  addMeasure(dropId: number, measure: any) {
    return this.http.post(BackendConfig.dropMeasurementsById(dropId), measure, BackendConfig.httpOptions());
  }

  updateDrop(dropId: number, drop: any) {
    return this.http
      .put(BackendConfig.dropById(dropId), drop, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  deleteMeasurement(dropId: number, measurementId: number) {
    return this.http
      .delete(BackendConfig.measurementsById(dropId, measurementId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getDropLabel(dropId: number) {
    return this.http
      .get<any>(BackendConfig.dropLabelById(dropId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  private serializePaintings(paintings: any) {
    return { paintings: paintings.map((item) => ({ id: item.paintingId })) };
  }
}
