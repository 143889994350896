import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { InventoryListPageRoutingModule } from './inventory-list-routing.module';

import { InventoryListPage } from './inventory-list.page';
import { InventoryItemComponent } from '../../../components/inventory-item/inventory-item.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DateBrPipe } from 'src/app/pipes/date/date-br.pipe';

const routes: Routes = [
  {
    path: '',
    component: InventoryListPage,
  },
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InventoryListPageRoutingModule,
    IonicModule,
    RouterModule.forChild(routes),
    PipesModule,
  ],
  declarations: [InventoryListPage, InventoryItemComponent],
  providers: [DateBrPipe],
})
export class InventoryListPageModule {}
