import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-exclude-modal',
  templateUrl: './exclude-modal.component.html',
  styleUrls: ['./exclude-modal.component.scss'],
})
export class ExcludeModalComponent {
  name: string;
  constructor(public modalCtrl: ModalController) {}

  cancel() {
    return this.modalCtrl.dismiss({ response: false });
  }

  confirm() {
    return this.modalCtrl.dismiss({ response: true });
  }
}
