/* eslint-disable max-len */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Item } from 'src/app/models/inventory-item.model';
import { stringParser } from 'src/app/utils/stringParser';
import { AddItemComponent } from '../add-item/add-item.component';
import { QrcodeReaderComponent } from '../qrcode-reader/qrcode-reader.component';
import { InventoryService } from 'src/app/services/inventory/invetory.service';
import { StickerNumber } from 'src/app/models/stickerNumber.model';

@Component({
  selector: 'app-add-item-method',
  templateUrl: './add-item-method.component.html',
  styleUrls: ['./add-item-method.component.scss'],
})
export class AddItemMethodComponent implements OnInit {
  @ViewChild('codeInput') codeInput: ElementRef;
  @ViewChild('stickerInput') stickerInput: ElementRef;
  @Input() stockTakingOrderId: number;
  @Input() itemId: number;
  @Input() round = 1;
  @Input() stickerInitialNumber: number;
  @Input() stickerFinalNumber: number;
  @Input() items: Array<Item>;
  stickersOne: StickerNumber;
  stickersTwo: StickerNumber;
  stickersThree: StickerNumber;
  qrcodeContent = '';
  stickerContent = '';
  stickerNotAdded = null;

  constructor(
    private modalCtrl: ModalController,
    private inventoryService: InventoryService,
    public toastController: ToastController,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.stickerInput.nativeElement.focus();
    }, 500);
  }

  async closeModal(data?: string): Promise<void> {
    await this.modalCtrl.dismiss(data);
  }

  async goToManualMethod(): Promise<void> {
    await this.openManualModal();
  }

  async goToQRCode(): Promise<void> {
    await this.openQRCodeReader();
  }

  async openManualModal(data?: any): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AddItemComponent,
      componentProps: {
        stockTakingOrderId: this.stockTakingOrderId,
        itemId: this.itemId,
        round: this.round,
        stickerInitialNumber: this.stickerInitialNumber,
        stickerFinalNumber: this.stickerFinalNumber,
        anotherComponentInfo: data ?? undefined,
        stickerNumber: this.stickerContent,
      },
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.stickerInput.nativeElement.focus();
    });
  }

  async openQRCodeReader(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: QrcodeReaderComponent,
      componentProps: {
        stockTakingOrderId: this.stockTakingOrderId,
        stickerNumber: this.stickerContent,
      },
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.stickerInput.nativeElement.focus();
    });
  }

  onPaste(data: string): void {
    try {
      const value = stringParser(data);
      this.openManualModal(value);
      this.inputReset();
    } catch (error) {
      console.error(error);
      this.openToast('QRCode inválido', 'danger');
      this.inputReset();
    }
  }

  async onPasteSticker(data: string): Promise<void> {
    this.stickerContent = data;
    if (this.round === 3) {
      await this.verifyStickerRoundThree(data);
    }
    if (this.round === 2) {
      await this.verifyStickerRoundTwo(data);
    }
    if (this.round === 1) {
      await this.verifyStickerRoundOne(data);
    }
  }

  formatToManualModal(item: Item) {
    return {
      productCode: item.item.code,
      productDescription: item.item.description,
      registration: item.item.registration,
      lot: item.lot,
      packageId: item.package.id,
      quantity: String(item.quantity),
      unityId: item.item.unity.id,
    };
  }

  async verifyStickerRoundOne(data: string): Promise<void> {
    this.stickersOne = await this.getStickerNumber(this.stockTakingOrderId, this.round);
    const item = this.stickersOne['stickernumber'].find((item) => item.sticker === Number(data));
    if (!item) {
      const outOnRange = this.stickerInitialNumber <= Number(data) && Number(data) <= this.stickerFinalNumber;
      if (outOnRange) {
        setTimeout(() => {
          this.codeInput.nativeElement.focus();
        }, 500);
      } else {
        this.stickerOutRanged(data);
      }
    } else {
      this.stickerRepeat(data);
    }
  }

  async verifyStickerRoundTwo(data: string): Promise<void> {
    this.stickersTwo = await this.getStickerNumber(this.stockTakingOrderId, this.round);
    this.stickersOne = await this.getStickerNumber(this.stockTakingOrderId, 1);
    const stk1 = this.stickersOne['stickernumber'].find((item) => item.sticker === Number(data));
    if (stk1?.isCanceled) {
      this.openToast('Ficha cancelada', 'danger');
      this.inputReset();
      return;
    }
    const stk = this.stickersTwo['stickernumber'].find((item) => item.sticker === Number(data));
    if (!stk) {
      const item = this.items.find((item) => item.stickerNumber === Number(data));
      if (item) {
        this.itemId = item.item.id;
        await this.openManualModal(this.formatToManualModal(item));
        this.inputReset();
      } else {
        this.openToast(
          `A ficha ${data} não existe na lista de itens prontos para a 2° / 3° Contagem.Verifique se ele foi adicionado.`,
          'danger',
        );
        this.stickerNotAdded = data;
        this.inputReset();
      }
    } else {
      this.stickerRepeat(data);
    }
  }

  async verifyStickerRoundThree(data: string): Promise<void> {
    this.stickersThree = await this.getStickerNumber(this.stockTakingOrderId, this.round);
    this.stickersOne = await this.getStickerNumber(this.stockTakingOrderId, 1);
    const stk1 = this.stickersOne['stickernumber'].find((item) => item.sticker === Number(data));
    if (stk1?.isCanceled) {
      this.openToast('Ficha cancelada', 'danger');
      this.inputReset();
      return;
    }

    const stk3 = this.stickersThree['stickernumber'].find((item) => item.sticker === Number(data));
    if (!stk3) {
      const itemNeedThreeCount = this.items.filter((item) => item.status.name === 'Terceira contagem');
      const item = itemNeedThreeCount.find((item) => item.stickerNumber === Number(data));
      if (item) {
        this.itemId = item.item.id;
        await this.openManualModal(this.formatToManualModal(item));
        this.inputReset();
      } else {
        this.openToast(
          `A ficha ${data} não existe na lista de itens prontos para a 2° / 3° Contagem.Verifique se ele foi adicionado.`,
          'danger',
        );
        this.stickerNotAdded = data;
        this.inputReset();
      }
    } else {
      this.stickerRepeat(data);
    }
  }

  stickerOutRanged(data: string): void {
    this.stickerNotAdded = data;
    this.openToast(
      `A ficha ${this.stickerNotAdded} está fora do alcance selecionado.Verifique se ele foi adicionado corretamente ou altere o alcance das fichas neste inventario.`,
      'danger',
    );
    this.inputReset();
  }

  stickerRepeat(data: string): void {
    this.stickerNotAdded = data;
    this.openToast(
      `A ficha ${this.stickerNotAdded} já foi inserida neste inventário.Verifique se está lendo a ficha corretamente ou se a ficha já foi inspecionada anteriormente.`,
      'danger',
    );
    this.inputReset();
  }

  async getStickerNumber(id: number, round: number): Promise<StickerNumber> {
    const result = await this.inventoryService.getStickerNumber(id, round).pipe().toPromise();
    return result;
  }

  async openToast(message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      duration: 2000,
      position: 'top',
      message,
      color,
    });
    toast.present();
  }

  inputReset(): void {
    const form = document.querySelector('#resetInput') as HTMLButtonElement;
    form.click();
    this.stickerInput.nativeElement.focus();
  }
}
