import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { StepsService } from 'src/app/services/steps/steps.service';
import { FiberTypesService } from 'src/app/services/fibersTypes/fiber-types.service';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
})
export class ProductModalComponent implements OnInit {
  colors: any[] = [];
  fibersType: any[] = [];
  productForm: FormGroup;
  parentId: number = 0;
  steps: any[] = [];
  productsPayloadToSendEdit: Product = null;
  duplicate: boolean = false;

  constructor(
    public modalController: ModalController,
    public stepService: StepsService,
    public productsService: ProductsService,
    public fiberTypeService: FiberTypesService,
    public router: Router,
    public toastController: ToastController,
  ) {}

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
    });
    toast.present();
  }

  async listedColors(): Promise<void> {
    this.colors = await this.stepService.listColors();
    this.colors.unshift({ id: null, description: 'Selecione' });
  }
  async listedFiberTypes(): Promise<void> {
    this.fibersType = ((await this.fiberTypeService.list().toPromise()) as any).types;
  }
  async listSteps(): Promise<void> {
    this.steps = await this.stepService.list(false, false);
  }

  async ngOnInit() {
    this.listedColors();
    this.listedFiberTypes();
    this.listSteps();
    this.productForm = new FormGroup({
      description: new FormControl(this.productsPayloadToSendEdit?.description || '', [Validators.required]),
      fiberType: new FormControl(this.productsPayloadToSendEdit?.type.id || 1),
      productCode: new FormControl(this.productsPayloadToSendEdit?.slug || ''),
      sector: new FormControl(this.productsPayloadToSendEdit?.step.id || 1, [Validators.required]),
      anatel: new FormControl(this.productsPayloadToSendEdit?.anatelid || ''),
      barCode: new FormControl(this.productsPayloadToSendEdit?.barcode || ''),
      pesoLiquido: new FormControl(this.productsPayloadToSendEdit?.weight || null),
      pesoBruto: new FormControl(this.productsPayloadToSendEdit?.grossweight || null),
      processSheet: new FormControl(this.productsPayloadToSendEdit?.processsheet || ''),
      color: new FormControl(this.productsPayloadToSendEdit?.color.id || null),
      processSheetURL: new FormControl(this.productsPayloadToSendEdit?.processsheeturl || ''),
      hasPainting: new FormControl(this.productsPayloadToSendEdit?.haspaintings || false),
      technicalDescription: new FormControl(this.productsPayloadToSendEdit?.technicaldescription || ''),
      operationManualURL: new FormControl(this.productsPayloadToSendEdit?.operationmanualurl || ''),
      qrCode: new FormControl(this.productsPayloadToSendEdit?.qrcode || ''),
      finalProductDescription: new FormControl(this.productsPayloadToSendEdit?.finalproduct || ''),
      torsionLoss: new FormControl(this.productsPayloadToSendEdit?.torsionloss || null),
      duplicationslimit: new FormControl(this.productsPayloadToSendEdit?.duplicationslimit || null),
    });
  }

  closeModal(reaload = false) {
    this.modalController.dismiss(reaload);
  }

  generateProductPayload() {
    const productPayload = {
      parentProductId: this.parentId,
      description: this.productForm.value.description,
      type: { id: this.productForm.value.fiberType },
      slug: this.productForm.value.productCode,
      step: { id: this.productForm.value.sector },
      anatelid: this.productForm.value.anatel,
      barcode: this.productForm.value.barCode,
      weight: this.productForm.value.pesoLiquido,
      color: { id: this.productForm.value.color },
      qrcode: this.productForm.value.qrCode,
      haspaintings: this.productForm.value.hasPainting,
      technicaldescription: this.productForm.value.technicalDescription,
      operationmanualurl: this.productForm.value.operationManualURL,
      processsheet: this.productForm.value.processSheet,
      processsheeturl: this.productForm.value.processSheetURL,
      finalproduct: this.productForm.value.finalProductDescription,
      torsionloss: this.productForm.value.torsionLoss,
      grossweight: this.productForm.value.pesoBruto,
      duplicationslimit: this.productForm.value.duplicationslimit,
    };
    return productPayload;
  }

  async postProductsAndAssociateWithParentProduct() {
    try {
      if (!this.productForm.valid) return this.presentToast('Preencha pelo menos o campo descrição', 'danger');
      const product = this.generateProductPayload();

      let response;
      if (!this.productsPayloadToSendEdit) {
        response = await this.productsService.postProducts(product);
      } else {
        if (this.duplicate)
          response = await this.productsService.duplicateProduct(product, this.productsPayloadToSendEdit.id);
        else response = await this.productsService.putProducts(product, this.productsPayloadToSendEdit.id);
      }
      if (
        response.message === 'Produto cadastrado com sucesso!' ||
        response.message === 'Produto editado com sucesso!' ||
        response.message === 'Produto copiado com sucesso!'
      ) {
        await this.presentToast(response.message, 'success');
        this.closeModal(true);
        if (this.parentId) {
          await this.router.navigate([`menu/config/steps/products/${this.parentId}`]);
        }
        return;
      } else {
        this.presentToast('Erro inesperado ao cadastrar produto', 'danger');
      }
    } catch (e) {
      this.presentToast('Erro inesperado ao cadastrar produto', 'danger');
    }
  }
}
