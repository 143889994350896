import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BackendConfig } from '../config/backend-config';
import { IUnity } from 'src/app/models/unity.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryUnityService {
  constructor(private http: HttpClient) {}

  list(): Observable<IUnity[]> {
    return this.http
      .get<IUnity[]>(BackendConfig.inventoryUnity(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }
}
