import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable()
export class BackendConfig {
  private static _data = {
    backend: {
      dev: 'http://localhost:3000',
      stg: 'https://luigi-stg.finderup.com.br',
      prod: 'https://luigi.finderup.com.br',
    },
  };

  private static backendUrl() {
    return this._data.backend.stg;
  }

  public static token() {
    // eslint-disable-next-line max-len
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9hcHAiOiIxMjM0NTY3ODkwIiwidXNlciI6IkpvaG4gRG9lIn0.9v54sa6Hlw3SZ7h1EBhTX2pcAggvkG0D9VKoTjf5Axg';
  }

  public static httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: BackendConfig.token(),
      }),
    };
  }

  public static fiberTypesUrl() {
    return `${this.backendUrl()}/v1/fiberTypes`;
  }

  public static fiberTypesByStepUrl(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/types`;
  }

  public static productsUrl() {
    return `${this.backendUrl()}/v1/products`;
  }

  public static getSubProducts(productId: number, sliceRequestLength: number) {
    return `${this.backendUrl()}/v1/products/${productId}/stock?sliceRequestLength=${sliceRequestLength}`;
  }

  public static machinesUrl(stepId) {
    return `${this.backendUrl()}/v1/steps/${stepId}/machines?isActive=true`;
  }

  public static createVelocityUrl(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/machines`;
  }

  public static lastMachinesSetupUrl(machineId: number, productionOrderId: number) {
    return `${this.backendUrl()}/v1/machines/${machineId}/setups?productionOrderId=${productionOrderId}&latest=true`;
  }

  public static colorsUrl() {
    return `${this.backendUrl()}/v1/colors`;
  }

  public static stockUrl() {
    return `${this.backendUrl()}/v1/stock`;
  }
  public static getProductStatus(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/stocks`;
  }

  public static menuUrl(tracebilityRole: string, inventoryRole: string) {
    return `${this.backendUrl()}/v1/menu?traceabilityRole=${tracebilityRole}&inventoryRole=${inventoryRole}`;
  }

  public static packagesUrl() {
    return `${this.backendUrl()}/v1/packages`;
  }

  public static stockFiberUrl(fiberType: number) {
    return `${this.backendUrl()}/v2/stock?type=${fiberType}`;
  }

  public static stockByStep(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/stock`;
  }

  public static stockFiberDetailsUrl(colorId: number, fiberType: string) {
    return `${this.backendUrl()}/v1/colors/${colorId}/stock?type=${fiberType}`;
  }

  public static stockPaintingsAvailableUrl(colorId: number, fiberType: string) {
    return `${this.colorsUrl()}/${colorId}/paintings?status=available&type=${fiberType}`;
  }

  public static stockGenericPaintingsAvailableUrl(colorId: number, fiberType: string, sliceRequestLength: number) {
    return `${this.colorsUrl()}/${colorId}/paintings?status=available&type=${fiberType}&sliceRequestLength=${sliceRequestLength}`;
  }

  public static productionOrdersUrl() {
    return `${this.backendUrl()}/v1/productionOrders`;
  }

  public static productionOrdersSetupUrl(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/setups`;
  }

  public static productionOrdersRemoveSetupUrl(productionOrderId: number, setupId: number) {
    return `${this.productionOrdersSetupUrl(productionOrderId)}/${setupId}`;
  }

  public static productionOrdersSliceUrl(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/slices`;
  }

  public static productionOrdersRemoveSliceUrl(productionOrderId: number, sliceId: number) {
    return `${this.productionOrdersSliceUrl(productionOrderId)}/${sliceId}`;
  }

  public static productionOrdersPalletsUrl(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/pallets`;
  }

  public static productionOrderSubstep(productionOrderId: number, substepId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/substeps/${substepId}`;
  }

  public static productionOrderSubstepNested(
    productionOrderId: number,
    substepId: number,
    productionOrderSubstepId: number,
  ) {
    return `${this.productionOrdersIdUrl(
      productionOrderId,
    )}/substeps/${substepId}?productionOrdersSubstepId=${productionOrderSubstepId}`;
  }

  public static productionOrderSubstepDetails(
    productionOrderId: number,
    substepId: number,
    productionOrdersSubstepId: number,
  ) {
    return `${this.productionOrderSubstep(
      productionOrderId,
      substepId,
    )}/productionOrdersSubstep/${productionOrdersSubstepId}`;
  }

  public static listPredecessors(productionOrderId: number, productionOrdersSubstepId: number) {
    return `${this.productionOrdersIdUrl(
      productionOrderId,
    )}/productionOrderSubsteps/${productionOrdersSubstepId}/predecessors`;
  }

  public static updateSubstepLink(
    productionOrderId: number,
    productionOrderSubstepId,
    predecessorProductionOrderSubstepId,
  ) {
    return `${this.productionOrdersIdUrl(
      productionOrderId,
    )}/productionOrderSubstep/${productionOrderSubstepId}/newPredecessorProductionOrderSubstep/${predecessorProductionOrderSubstepId}`;
  }

  public static printGenericProductionOrder(
    productionOrderId: number,
    substepId: number,
    productionOrdersSubstepId: number,
  ) {
    return `${this.productionOrderSubstep(
      productionOrderId,
      substepId,
    )}/productionOrdersSubstep/${productionOrdersSubstepId}/print`;
  }

  public static getSubstepLastMachine(
    productionOrderId: number,
    substepId: number,
    latest: boolean,
    machineId: number,
  ) {
    return `${this.productionOrdersIdUrl(
      productionOrderId,
    )}/substeps/${substepId}/machines/${machineId}?latest=${latest}`;
  }
  public static getOnlyLastMachine(productionOrderId: number, substepId: number, machineId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/substeps/${substepId}/machines/${machineId}/reellot`;
  }
  public static deleteSubstepSetup(productionOrderId: number, substepId: number, productionOrdersSubstepId: number) {
    return `${this.productionOrdersIdUrl(
      productionOrderId,
    )}/substep/${substepId}/productionOrdersSubstep/${productionOrdersSubstepId}`;
  }

  public static productionOrdersIdUrl(id: number) {
    return `${this.productionOrdersUrl()}/${id}`;
  }

  public static productionOrdersByStep(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/productionOrders`;
  }

  public static productionOrdersByStepFilter(stepId: number, all: boolean, groupBy: boolean, externalCode: string) {
    return `${this.productionOrdersByStep(stepId)}?${all ? 'all=true' : 'all=false'}${
      groupBy ? '&groupBy=externalCode' : ''
    }${externalCode != null ? `&externalCode=${externalCode}` : ''}`;
  }

  public static productionOrdersPaintingUrl() {
    return `${this.productionOrdersUrl()}/steps/1`;
  }

  public static productionOrdersFibersUrl(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/fibers`;
  }

  public static productionOrdersItemIdUrl(productionOrderId: number, itemId: string) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/items/${itemId}`;
  }

  public static productionOrdersItemsItemIdUrl(productionOrderId: number, itemId: string) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/items/${itemId}`;
  }

  public static productionOrdersItemsIdUrl(id: number, itemId: string) {
    return `${this.productionOrdersItems(id)}/${itemId}`;
  }

  public static productionOrdersItems(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/items`;
  }

  public static fibers() {
    return `${this.backendUrl()}/v1/fibers`;
  }

  public static fibersId(fiberId: string) {
    return `${this.fibers()}/${fiberId}`;
  }

  public static paintings(paintingId: number) {
    return `${this.backendUrl()}/v1/paintings/${paintingId}`;
  }

  public static drops(paintingId: number) {
    return `${this.backendUrl()}/v1/drops?paintingId=${paintingId}`;
  }

  public static dropsDisapproved(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/drops?status=disapproved`;
  }

  public static addPaintingDrops(productionOrderId: number) {
    return `${this.productionOrdersIdUrl(productionOrderId)}/paintings`;
  }

  public static dropById(dropId: number) {
    return `${this.backendUrl()}/v1/drops/${dropId}`;
  }

  public static dropLabelById(dropId: number) {
    return `${this.dropById(dropId)}/label`;
  }

  public static dropMeasurementsById(dropId: number) {
    return `${this.dropById(dropId)}/measurement`;
  }

  public static measurementsById(dropId: number, measurementId: number) {
    return `${this.dropMeasurementsById(dropId)}/${measurementId}`;
  }

  public static dropPallet(palletId: number) {
    return `${this.backendUrl()}/v1/pallets/${palletId}/drops`;
  }

  public static pallets() {
    return `${this.backendUrl()}/v1/pallets`;
  }

  public static palletsId(palletId: number) {
    return `${this.pallets()}/${palletId}`;
  }

  public static setups() {
    return `${this.backendUrl()}/v1/setups`;
  }

  public static setupsId(setupId: number) {
    return `${this.setups()}/${setupId}`;
  }

  public static addReturnPaintings() {
    return `${this.backendUrl()}/v1/paintings/return`;
  }

  public static addReturnItems() {
    return `${this.backendUrl()}/v1/items/return`;
  }

  public static saveSubProducts(productionOrderId: number) {
    return `${this.productionOrdersUrl()}/${productionOrderId}/subProducts`;
  }

  public static searchForProductionOrder(stepId: number, externalCode: string) {
    return `${this.productionOrdersByStep(stepId)}?externalCode=${externalCode}&search=true`;
  }

  public static depositsUrl() {
    return `${this.backendUrl()}/v1/deposits`;
  }

  public static depositId(id: number) {
    return `${this.depositsUrl()}/${id}`;
  }

  public static stockTakingOrdersUrl() {
    return `${this.backendUrl()}/v1/stockTakingOrders`;
  }

  public static reportStock(id: number) {
    return `${this.stockTakingOrderId(id)}/report`;
  }

  public static editStatusFinished(id: number) {
    return `${this.stockTakingOrderId(id)}`;
  }

  public static stockTakingOrderId(id: number) {
    return `${this.stockTakingOrdersUrl()}/${id}`;
  }

  public static stockTakingOrderItem(stockTakingOrderId: number) {
    return `${this.stockTakingOrderId(stockTakingOrderId)}/items`;
  }

  public static stockTakingOrderCountItem(stockTakingOrderId: number, itemId: number) {
    return `${this.stockTakingOrderItem(stockTakingOrderId)}/${itemId}`;
  }

  public static stockTakingOrderIdItemId(stockTakingOrderId: number, itemId: number, roundNumber) {
    return `${this.stockTakingOrderId(stockTakingOrderId)}/items/${itemId}/rounds/${roundNumber}`;
  }

  public static createOpOfSubProducts(id: number) {
    return `${this.productionOrdersUrl()}/${id}/subproducts`;
  }

  public static shareProductionOrders(parentProductionOrderId: number) {
    return `${this.productionOrdersUrl()}/${parentProductionOrderId}/share`;
  }

  public static unit() {
    return `${this.backendUrl()}/v1/units`;
  }

  public static areaInUnit(id) {
    return `${this.unit()}/${id}`;
  }

  public static areaNoInUnit(id) {
    return `${this.unit()}/${id}/area?avaliable=true`;
  }

  public static saveArea(id) {
    return `${this.unit()}/${id}/area`;
  }

  public static inventoryPackages() {
    return `${this.backendUrl()}/v1/inventoryPackages`;
  }

  public static inventoryUnity() {
    return `${this.backendUrl()}/v1/inventoryUnity`;
  }

  public static stickerNumber(id: number, round: number) {
    return `${this.backendUrl()}/v1/stockTakingOrders/${id}/stickerNumbers/${round}`;
  }

  public static cancelSticker(stockTakingOrderId: number, stockTakingOrderItemId: number): string {
    return `${this.stockTakingOrderId(stockTakingOrderId)}/sticker/${stockTakingOrderItemId}`;
  }

  public static steps(addNaturalStep = false, isActive = false) {
    const stepsUrl = `${this.backendUrl()}/v1/steps`;

    if (isActive == true) {
      return addNaturalStep ? `${stepsUrl}?addNaturalStep=true&isActive=true` : `${stepsUrl}?isActive=true`;
    }

    return addNaturalStep ? `${stepsUrl}?addNaturalStep=true` : stepsUrl;
  }

  public static listStepTypes() {
    return `${this.backendUrl()}/v1/step-types`;
  }

  public static stepItemByLot(stepId: string, lot: string) {
    return `${this.backendUrl()}/v1/steps/${stepId}/items?inputValue=${lot}`;
  }

  public static stepItemByInput(stepId: string, inputValue: string, inputId?: number, isReport: boolean = false) {
    const inputIdFiltered = inputId ? inputId : 0;
    const reportParam = isReport ? `&isReport=${isReport}` : '';

    return `${this.backendUrl()}/v1/steps/${stepId}/items?inputId=${inputIdFiltered}&inputValue=${inputValue}${reportParam}`;
  }

  public static getItemLot(stepId: string, item: string) {
    return `${this.backendUrl()}/v1/steps/${stepId}/items/${encodeURIComponent(item)}`;
  }

  public static createStep() {
    return `${this.backendUrl()}/v1/steps`;
  }

  public static updateStep() {
    return `${this.backendUrl()}/v1/steps`;
  }
  public static deleteStep(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}`;
  }

  public static getStepDetails(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}`;
  }

  public static getStepProducts(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/products`;
  }

  public static getStepMachines(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/machines?isActive=true`;
  }

  public static getAllStepMachines(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/machines`;
  }

  public static getProductById(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}`;
  }
  public static getMachineById(machineId: number) {
    return `${this.backendUrl()}/v1/machines/${machineId}`;
  }

  public static getRecipes(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/recipe`;
  }

  public static getSubsteps(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps`;
  }
  public static getProductsSubstepsById(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/inputs`;
  }
  public static updateSubstepsInputs(productId: number, substepId: number, inputId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/inputs/${inputId}`;
  }

  public static getSubstepsById(substepId: number) {
    return `${this.backendUrl()}/v1/substeps/${substepId}`;
  }

  public static deleteSubSteps(id: number): string {
    return `${this.backendUrl()}/v1/substeps/${id}`;
  }

  public static getSubstepsInputById(productId: number, substepId: number, inputId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/inputs/${inputId}`;
  }

  public static handleError = (error: any) => {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
    }
    return throwError(error);
  };

  public static getProductsAvailable(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/available-recipe`;
  }

  public static patchProductsAvailable(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/recipe`;
  }

  public static postProducts() {
    return `${this.backendUrl()}/v1/products/`;
  }

  public static putProducts(id) {
    return `${this.backendUrl()}/v1/products/${id}`;
  }

  public static duplicateProduct(id) {
    return `${this.backendUrl()}/v1/products/${id}/copy`;
  }

  public static deleteProducts(id) {
    return `${this.backendUrl()}/v1/products/${id}`;
  }

  public static disassociateProducts(productId: number, subProductId: number): string {
    return `${this.backendUrl()}/v1/products/${productId}/recipe/${subProductId}`;
  }

  public static getProductsSubstepsParameters(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/available-inputs`;
  }
  public static getAvailableSubsteps(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/available-substeps`;
  }

  public static postProductsSubstepsParameters(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/inputs-associate`;
  }

  public static getProductMachines(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/machines`;
  }

  public static deleteSpeed(productId: number, machineId: number): string {
    return `${this.backendUrl()}/v1/products/${productId}/machines/${machineId}`;
  }

  public static getSubstepAvailable(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps?available=true`;
  }

  public static postSubstep(productId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps`;
  }

  public static createSubstepsLinkWithProduct() {
    return `${this.backendUrl()}/v1/substeps`;
  }

  public static getPredecessorSubsteps(substepId) {
    return `${this.backendUrl()}/v1/substeps/${substepId}/available-substeps`;
  }

  public static getSubstepById(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}`;
  }

  public static editSubstep() {
    return `${this.backendUrl()}/v1/substeps`;
  }

  public static deleteProductSubSteps(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}`;
  }
  public static disassociateParameter(productId: number, substepId: number, inputId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/inputs/${inputId}`;
  }
  public static postSubstepInput(productId: number, substepId: number) {
    return `${this.backendUrl()}/v1/products/${productId}/substeps/${substepId}/inputs`;
  }
  public static removeParameter(inputId: number) {
    return `${this.backendUrl()}/v1/inputs/${inputId}`;
  }
  public static createStepMachine() {
    return `${this.backendUrl()}/v1/machines`;
  }
  public static editStepMachine(machineId: number) {
    return `${this.backendUrl()}/v1/machines/${machineId}`;
  }
  public static deleteMachine(machineId: number) {
    return `${this.backendUrl()}/v1/machines/${machineId}`;
  }
  public static deleteFiberType(fiberTypeId: number) {
    return `${this.backendUrl()}/v1/fibertypes/${fiberTypeId}`;
  }

  public static updateFyberType(fiberTypeId: any) {
    return `${this.backendUrl()}/v1/fibertypes/${fiberTypeId}`;
  }

  public static getFiberTypeDetails(fiberTypeId: number) {
    return `${this.backendUrl()}/v1/fibertypes/${fiberTypeId}`;
  }

  public static getStepInputs(stepId: number) {
    return `${this.backendUrl()}/v1/steps/${stepId}/inputs`;
  }
  public static removeOneFiberId(fiberId: string) {
    return `${this.backendUrl()}/v1/fibers/${fiberId}`;
  }

  public static getPaintingAutomaticLot(id: number, itemId: string, machineId: number) {
    return `${this.backendUrl()}/v1/productionOrders/${id}/items/${itemId}/machine/${machineId}/lot`;
  }

  public static postSubstepFile() {
    return `${this.backendUrl()}/v1/upload/file`;
  }
}
