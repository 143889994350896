import { Injectable } from '@angular/core';
import { Ganon } from '@finderup/ganon';
import { Observable } from 'rxjs';
import environment from 'src/environments/environment';

@Injectable()
export class AuthenticateService {
  ganon = Ganon(window, {
    env: `${environment.ganon.environment}`,
    url: `${environment.ganon.ganonUrl}`,
    token: `${environment.ganon.ganonToken}`,
    app: `${environment.ganon.ganonApp}`,
  });

  constructor() {}

  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.ganon.loginWithEmailAndPassword(value.email, value.password).then(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }

  logoutUser() {
    return new Promise<void>((resolve) => {
      Ganon(window).logout();
      resolve();
    });
  }

  getUser() {
    return new Observable((observer) => {
      this.ganon.getLoggedUser().then((user) => observer.next(user));
    });
  }

  getAuthUser(): Observable<any> {
    return this.getUser();
  }
}
