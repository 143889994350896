import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BackendConfig } from '../config/backend-config';
import { IInventoryPackage } from 'src/app/models/inventoryPackage.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryPackageService {
  constructor(private http: HttpClient) {}

  list(): Observable<{ packages: IInventoryPackage[] }> {
    return this.http
      .get<{ packages: IInventoryPackage[] }>(BackendConfig.inventoryPackages(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }
}
