import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { MachineModel } from '../../models/machine.model';
import { MachinesService } from '../../services/machines/machines.service';

interface MachineProps {
  id: number;
  name: string;
}
interface MachinePropsWithDescription {
  id: number;
  description: string;
}
interface PayloadProps {
  machine: MachineProps;
  metersPerMinute: number;
}
@Component({
  selector: 'app-product-velocity-modal',
  templateUrl: './product-velocity-modal.component.html',
  styleUrls: ['./product-velocity-modal.component.scss'],
})
export class ProductVelocityModalComponent implements OnInit {
  productId: number = 0;
  stepId: number = 0;
  machines: MachineModel[] = [];
  payload: PayloadProps = {
    machine: {
      id: 0,
      name: '',
    },
    metersPerMinute: 0,
  };
  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    public machineService: MachinesService,
  ) {}

  async closeModal() {
    this.modalController.dismiss();
  }

  async getMachines() {
    const machines = await this.machineService.list(this.stepId);
    this.machines = machines;
  }

  async setMachine(machine: MachinePropsWithDescription) {
    this.payload.machine.id = machine.id;
    this.payload.machine.name = machine.description;
  }

  async setVelocity(velocity: number) {
    this.payload.metersPerMinute = Number(velocity);
  }
  async setProductId(productId: number) {
    this.productId = productId;
  }

  async submit() {
    try {
      await this.machineService.createVelocity(this.productId, this.payload);

      this.openToast('Velocidade cadastrada com sucesso', 'success');
      this.closeModal();
    } catch (err) {
      this.openToast(err?.error?.message || 'Erro', 'danger');
      this.closeModal();
    }
  }
  async openToast(message: string, color: string): Promise<void> {
    const toast = await this.toastController.create({
      duration: 2000,
      position: 'bottom',
      message,
      color,
    });
    toast.present();
  }
  async ngOnInit() {
    await this.getMachines();
    await this.setProductId(this.productId);
  }
}
