import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ExcludeModalComponent } from 'src/app/components/exclude-modal/exclude-modal.component';
import { ProductModalComponent } from 'src/app/components/product-modal/product-modal.component';
import { IStep } from 'src/app/models/step.model';
import { MachinesService } from 'src/app/services/machines/machines.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { StepsMachineModalComponent } from '../../../../components/steps-machine-modal/steps-machine-modal.component';
import { MachineModel } from './../../../../models/machine.model';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-details.page.html',
  styleUrls: ['./step-details.page.scss'],
})
export class StepDetailsPage implements OnInit {
  step: IStep;
  products: Product[];
  machines: MachineModel[];

  constructor(
    private stepsService: StepsService,
    public productService: ProductsService,
    public machineService: MachinesService,
    private route: ActivatedRoute,
    private router: Router,
    public modalController: ModalController,
    public alertController: AlertController,
    public toastController: ToastController,
  ) {}

  async ngOnInit() {
    await this.getAllStepMachines();
    await this.getStepProducts();
    await this.getStepDetails();
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
    });
    toast.present();
  }

  private getStepId() {
    return parseInt(this.route.snapshot.paramMap.get('id'));
  }

  async getStepDetails() {
    this.step = await this.stepsService.getStepDetails(this.getStepId());
  }

  async getStepProducts() {
    this.products = await this.stepsService.getStepProducts(this.getStepId());
  }
  async getAllStepMachines() {
    this.machines = await this.stepsService.getAllStepMachines(this.getStepId());
  }

  openProductsAvailable() {
    this.router.navigate([`menu/config/steps/${this.getStepId()}/products`]);
  }

  async openModal(component: any, breakPoint: number, componentProps?: any, cssClass?: string) {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: component,
      initialBreakpoint: breakPoint,
      componentProps,
      cssClass,
    });
    await modal.present();

    const client = await modal.onDidDismiss();

    return client;
  }

  async openModalToCreateANewProduct() {
    const client = await this.openModal(ProductModalComponent, 0.9);
    if (client?.data) this.getStepProducts();
  }

  async openModalToEditProduct(productId: number, duplicate = false) {
    const productsInformation: Product = await this.productService.getProductById(productId);
    const client = await this.openModal(ProductModalComponent, 0.9, {
      productsPayloadToSendEdit: productsInformation,
      duplicate,
    });

    if (client?.data) this.getStepProducts();
  }

  async presentAlert(id: number) {
    const modal = await this.openModal(ExcludeModalComponent, 0.9, undefined, 'exclude-modal');
    try {
      if (modal.data?.response) {
        await this.productService.deleteProducts(id);
        this.getStepProducts();
        this.presentToast('Produto deletado com sucesso', 'success');
      }
    } catch (err) {
      return this.presentToast(err?.error?.message || 'Erro ao deletar produto', 'danger');
    }
  }

  async presentAlertMachine(id: number) {
    const modal = await this.openModal(ExcludeModalComponent, 0.9, undefined, 'exclude-modal');
    try {
      if (modal.data?.response) {
        await this.machineService.deleteMachine(id);
        this.getAllStepMachines();
        this.presentToast('Maquina deletada com sucesso', 'success');
      }
    } catch (err) {
      return this.presentToast(err?.error?.message || 'Erro ao deletar a máquina', 'danger');
    }
  }

  async openCreateStepsMachineModal() {
    const client = await this.openModal(
      StepsMachineModalComponent,
      0.9,
      { stepId: this.getStepId() },
      'create-step-machine',
    );
    if (client?.data === true) {
      this.getAllStepMachines();
      this.presentToast('Máquina criada com sucesso', 'success');
    } else if (client.data === false) {
      this.presentToast('Erro ao criar máquina', 'danger');
    }
  }

  async openModalToEditMachine(machineId: number) {
    const machineInformation: MachineModel[] = await this.productService.getMachinesById(machineId);
    const client = await this.openModal(
      StepsMachineModalComponent,
      0.9,
      { machine: machineInformation },
      'create-step-machine',
    );
    if (client?.data) {
      this.getAllStepMachines();
      this.presentToast('Máquina editada com sucesso', 'success');
    } else if (client.data === false) {
      this.presentToast('Erro ao editar máquina', 'danger');
    }
  }
}
