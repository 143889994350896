import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IProductStatus } from 'src/app/models/IProductStatus';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
})
export class ProductListItemComponent {
  @Input() product: Product;
  @Input() showStep: boolean = true;
  @Input() isSubHeader: boolean = false;
  @Input() addNewProduct: boolean = false;
  @Input() showCheckBox: boolean;
  @Input() isStock: boolean;
  @Input() showStatus: boolean = false;
  @Output('checked') outputEvent = new EventEmitter<any>();
  checked: boolean;
  constructor(public router: Router) {}

  changeChecked() {
    this.outputEvent.emit({ product: this.product, add: this.checked });
  }

  openProductPage(productId: number) {
    if (this.showCheckBox) return;
    if (!this.isSubHeader && !this.isStock) {
      this.router.navigate([`menu/config/steps/products/${productId}`]);
    }
  }

  getStatus(status: string) {
    if (!this.product || !this.product.status) return {};
    return this.product.status.filter((item) => item.status === status)[0];
  }

  get approvedStatus(): IProductStatus {
    return this.getStatus('Aprovado');
  }

  get rejectedStatus(): IProductStatus {
    return this.getStatus('Reprovado');
  }

  get reservedStatus(): IProductStatus {
    return this.getStatus('Reservado');
  }
}
