import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { InventoryService } from 'src/app/services/inventory/invetory.service';

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss'],
})
export class AreaFormComponent implements OnInit {
  areaForm: any;
  @Input() unitId: number;

  constructor(
    private inventoryService: InventoryService,
    private toastController: ToastController,
    private modalCtrl: ModalController,
    private location: Location,
  ) {}

  ngOnInit() {
    this.areaForm = new FormGroup({
      code: new FormControl(null, [Validators.required, Validators.maxLength(4)]),
      description: new FormControl(null, [Validators.required]),
    });
  }

  generatePayload() {
    return {
      code: this.areaForm.value.code,
      description: this.areaForm.value.description,
    };
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async showErrorToastr(error: { error: { message: string } }) {
    const toast = await this.toastController.create({
      message: error.error.message,
      duration: 3000,
      color: 'danger',
    });

    await toast.present();
  }

  submit() {
    const payload = this.generatePayload();
    this.inventoryService.saveArea(this.unitId, payload).subscribe(
      () => {
        this.closeModal();
        this.location.back();
      },
      (error) => {
        console.error(error);
        this.showErrorToastr({ error: { message: 'Erro ao cadastrar unidade' } });
      },
    );
  }
}
