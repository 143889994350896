import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

import { BackendConfig } from '../config/backend-config';
import { IGetLotModel, ProductionOrder } from 'src/app/models/production-orders.model';
import { ProductionOrderBatch } from 'src/app/models/production-orders-batch.model';
import { Item } from 'src/app/models/item.model';
import { StepProductionOrders } from 'src/app/models/step-production-orders.model';
import { Substep } from 'src/app/models/substep-model';
import { fiber } from 'src/app/models/subProductList';
import { payloadSubProduct } from 'src/app/models/payloadSubproduct.model';

@Injectable({
  providedIn: 'root',
})
export class ProductionOrdersService {
  constructor(private http: HttpClient) {}

  get(id: number): Observable<ProductionOrder> {
    return this.http
      .get<ProductionOrder>(BackendConfig.productionOrdersUrl().concat(`/${id}`), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  list(): Observable<ProductionOrder[]> {
    return this.http
      .get<ProductionOrder[]>(BackendConfig.productionOrdersUrl(), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  listByStepId(stepId: number, all: boolean, groupBy: boolean, externalCode: string): Observable<StepProductionOrders> {
    return this.http.get<StepProductionOrders>(
      BackendConfig.productionOrdersByStepFilter(stepId, all, groupBy, externalCode),
      BackendConfig.httpOptions(),
    );
  }

  listPaintings(productionOrderId: number) {
    return this.http
      .get<any>(BackendConfig.productionOrdersItems(productionOrderId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  listDropsDisapproved(productionOrderId: number) {
    return this.http
      .get<any>(BackendConfig.dropsDisapproved(productionOrderId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  addSetup(productionOrderId: number, setup: any) {
    return this.http
      .post<any>(
        BackendConfig.productionOrdersSetupUrl(productionOrderId),
        this.serializeSetup(setup),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  addSlice(productionOrderId: number, slice: any) {
    return this.http.post<any>(
      BackendConfig.productionOrdersSliceUrl(productionOrderId),
      this.serializeSlice(slice),
      BackendConfig.httpOptions(),
    );
  }

  addPallet(productionOrderId: number, pallet: any) {
    return this.http.post<any>(
      BackendConfig.productionOrdersPalletsUrl(productionOrderId),
      pallet,
      BackendConfig.httpOptions(),
    );
  }

  add(productionOrder: ProductionOrderBatch, stepId: number): Observable<ProductionOrderBatch | void | any> {
    return this.http.post<ProductionOrderBatch>(
      BackendConfig.productionOrdersByStep(stepId),
      this.serializePost(productionOrder),
      BackendConfig.httpOptions(),
    );
  }

  update(productionOrder: ProductionOrder): Observable<ProductionOrder> {
    return this.http
      .put<ProductionOrder>(
        BackendConfig.productionOrdersIdUrl(productionOrder.id),
        this.serializePut(productionOrder),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  updateSliceOp(slice: { slices: { amount: number; length: number } }, id: number): Observable<any> {
    return this.http
      .patch(BackendConfig.productionOrdersIdUrl(id), slice, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  addPainting(productionOrderId: number, fiber: Item, paintDetails: any): Observable<ProductionOrder> {
    paintDetails.machineId = parseInt(paintDetails.machineId, 10);
    paintDetails.attenuation131 = parseFloat(paintDetails.attenuation131);
    paintDetails.attenuation155 = parseFloat(paintDetails.attenuation155);
    paintDetails.otdrLength = parseFloat(paintDetails.otdrLength);
    paintDetails.paintingLot = paintDetails.paintingLot === '' ? null : paintDetails.paintingLot;
    paintDetails.inkLot = paintDetails.inkLot === '' ? null : paintDetails.inkLot;
    return this.http.post<ProductionOrder>(
      BackendConfig.productionOrdersItemsIdUrl(productionOrderId, fiber.id),
      paintDetails,
      BackendConfig.httpOptions(),
    );
  }

  addDrop(productionOrderId: number, painting: Item, dropDetails: any): Observable<ProductionOrder> {
    dropDetails.machineId = parseInt(dropDetails.machineId, 10);
    dropDetails.attenuation131 = parseFloat(dropDetails.attenuation131);
    dropDetails.attenuation155 = parseFloat(dropDetails.attenuation155);
    dropDetails.otdrLength = parseFloat(dropDetails.otdrLength);
    dropDetails.bipartition = parseFloat(dropDetails.bipartition);
    dropDetails.endurance = parseFloat(dropDetails.endurance);
    dropDetails.width = parseFloat(dropDetails.width);
    dropDetails.height = parseFloat(dropDetails.height);
    dropDetails.springEffectResistance = parseFloat(dropDetails.springEffectResistance);
    return this.http.post<ProductionOrder>(
      BackendConfig.productionOrdersItemsIdUrl(productionOrderId, painting.id),
      dropDetails,
      BackendConfig.httpOptions(),
    );
  }

  delete(productionOrderId: number) {
    return this.http.delete(BackendConfig.productionOrdersIdUrl(productionOrderId), BackendConfig.httpOptions());
  }

  removeItem(productionOrderId: number, itemId: string) {
    return this.http.delete(
      BackendConfig.productionOrdersItemsItemIdUrl(productionOrderId, itemId),
      BackendConfig.httpOptions(),
    );
  }

  removeSetup(productionOrderId: number, setupId: number) {
    return this.http.delete(
      BackendConfig.productionOrdersRemoveSetupUrl(productionOrderId, setupId),
      BackendConfig.httpOptions(),
    );
  }

  removeSlice(productionOrderId: number, sliceId: number) {
    return this.http.delete(
      BackendConfig.productionOrdersRemoveSliceUrl(productionOrderId, sliceId),
      BackendConfig.httpOptions(),
    );
  }

  removePallet(palletId: number) {
    return this.http.delete(BackendConfig.palletsId(palletId), BackendConfig.httpOptions());
  }

  getSubstep(productionOrderId: number, substepId: number) {
    return this.http
      .get<Substep>(BackendConfig.productionOrderSubstep(productionOrderId, substepId), BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  getSubstepNested(productionOrderId: number, substepId: number, productionOrderSubstepId: number) {
    return this.http
      .get<Substep>(
        BackendConfig.productionOrderSubstepNested(productionOrderId, substepId, productionOrderSubstepId),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  private serializePost(productionOrder: ProductionOrderBatch) {
    const products = productionOrder.products.reduce((array, current) => {
      array.push({ id: current.id });
      return array;
    }, []);

    const productionOrderObject = {
      id: productionOrder.id,
      dueDate: moment(productionOrder.dueDate).format('YYYY-MM-DD').concat('T00:00:0000'),
      externalCode: productionOrder.externalCode,
      slices: {
        length: productionOrder.slicesRequest.length,
        amount: productionOrder.slicesRequest.amount,
      },
      rangeLengthFibers: productionOrder.rangeLengthFibers,
      attenuation131Range: productionOrder.attenuation131Range,
      attenuation155Range: productionOrder.attenuation155Range,
      products,
      client: productionOrder.client,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SAPCode: productionOrder.SAPCode,
      package: {
        id: productionOrder.packageId,
      },
      purchaseOrder: productionOrder.purchaseOrder,
      clientSAPCode: productionOrder.clientSAPCode,
      cableMark: productionOrder.cableMark,
    };

    return productionOrderObject;
  }

  private serializePut(productionOrder: ProductionOrder) {
    const productionOrderObject = {
      id: productionOrder.id,
      externalCode: productionOrder.externalCode,
      dueDate: moment(productionOrder.dueDate).format('YYYY-MM-DD').concat('T00:00:0000'),
      client: productionOrder.client,
      SAPCode: productionOrder.SAPCode,
      package: productionOrder.package,
      purchaseOrder: productionOrder.purchaseOrder,
      clientSAPCode: productionOrder.clientSAPCode,
      cableMark: productionOrder.cableMark,
    };
    return productionOrderObject;
  }

  private serializeSetup(setup: any) {
    setup.paintings = setup.paintings.map((item) => ({ id: item.id }));
    setup.slidingLot = setup.slidingLot ? setup.slidingLot : '';
    setup.masterLot = setup.masterLot ? setup.masterLot : '';
    return setup;
  }

  private serializeSlice(slice: any) {
    return {
      userId: slice.userId,
      quantity: slice.quantity,
    };
  }

  public saveSubProducts(productionOrderId: number, subProducts: Array<fiber>) {
    return this.http
      .patch<fiber[]>(BackendConfig.saveSubProducts(productionOrderId), subProducts, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  public searchProductionOrder(externalCode: string, stepId: number): Observable<ProductionOrder | ProductionOrder[]> {
    return this.http
      .get<ProductionOrder | ProductionOrder[]>(
        BackendConfig.searchForProductionOrder(stepId, externalCode),
        BackendConfig.httpOptions(),
      )
      .pipe(catchError(BackendConfig.handleError));
  }

  public createOpOfSubProducts(productionOrderId: number, payload: payloadSubProduct) {
    return this.http
      .post<any>(BackendConfig.createOpOfSubProducts(productionOrderId), payload, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  public shareProductionOrders(parentProductionOrderId: number, productionOrdersIds: Array<number>) {
    const payload = { productionOrderIds: [...productionOrdersIds] };
    return this.http
      .patch<any>(BackendConfig.shareProductionOrders(parentProductionOrderId), payload, BackendConfig.httpOptions())
      .pipe(catchError(BackendConfig.handleError));
  }

  public async getPaintingAutomaticLot(id: number, itemId: string, machineId: number): Promise<any> {
    return await lastValueFrom(
      this.http
        .get<IGetLotModel>(BackendConfig.getPaintingAutomaticLot(id, itemId, machineId), BackendConfig.httpOptions())
        .pipe(catchError(BackendConfig.handleError)),
    );
  }
}
