import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MachineModel } from 'src/app/models/machine.model';

@Component({
  selector: 'app-machine-list-item',
  templateUrl: './machine-list-item.component.html',
  styleUrls: ['./machine-list-item.component.scss'],
})
export class MachineListItemComponent {
  @Input() machine: MachineModel[];
  @Output('checked') outputEvent = new EventEmitter<any>();
  checked: boolean;
  constructor(public router: Router) {}

  changeChecked() {
    this.outputEvent.emit({ machine: this.machine, add: this.checked });
  }

  //rota para a tela de detalhes de machine
  openMachinePage(machineId: number) {
    this.router.navigate([`menu/config/steps/machines/${machineId}`]);
  }
}
