import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StepModalPageRoutingModule } from './step-modal-routing.module';

import { StepModalPage } from './step-modal.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, StepModalPageRoutingModule],
  declarations: [StepModalPage],
  exports: [StepModalPage],
})
export class StepModalPageModule {}
