import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { ItemPayload } from 'src/app/models/inventory-item.model';
import { IInventoryPackage } from 'src/app/models/inventoryPackage.model';
import { StickerNumber } from 'src/app/models/stickerNumber.model';
import { IUnity } from 'src/app/models/unity.model';
import { InventoryService } from 'src/app/services/inventory/invetory.service';
import { InventoryPackageService } from 'src/app/services/InventoryPackage/inventorypackage.service';
import { InventoryUnityService } from 'src/app/services/inventoryUnity/inventoryUnity.service';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
})
export class AddItemComponent implements OnInit {
  @Input() stockTakingOrderId: number;
  @Input() itemId: number;
  @Input() round = 1;
  @Input() stickerInitialNumber: number;
  @Input() stickerFinalNumber: number;
  @Input() anotherComponentInfo: any;
  @Input() stickerNumber: number | string;
  ionSelectPackageValue: number;
  ionSelectUnityValue: number;
  unitys: IUnity[];
  pkgs: Array<IInventoryPackage>;
  itemForm: FormGroup;
  stickers: StickerNumber;
  customAlertOptions: any = {
    cssClass: 'my-custom-interface-acond',
  };

  constructor(
    private packageService: InventoryPackageService,
    private inventoryService: InventoryService,
    private unityService: InventoryUnityService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.getInfo();
    const isDisabled = this.itemId ? true : false;
    const defaultFormState = { value: '', disabled: isDisabled };

    this.itemForm = new FormGroup({
      stickerNumber: new FormControl(defaultFormState, [
        Validators.required,
        Validators.min(this.stickerInitialNumber),
        Validators.max(this.stickerFinalNumber),
      ]),
      productCode: new FormControl(defaultFormState, [Validators.required]),
      productDescription: new FormControl(defaultFormState, [Validators.required]),
      registration: new FormControl(defaultFormState, []),
      lot: new FormControl(defaultFormState, []),
      packageId: new FormControl(defaultFormState, []),
      quantity: new FormControl('', [Validators.required, Validators.min(0)]),
      unityId: new FormControl(defaultFormState, [Validators.required]),
    });
  }

  async checkIfHasAnotherComponentInfo(): Promise<void> {
    if (this.anotherComponentInfo) {
      await this.setQrCodeValues(this.anotherComponentInfo);
    }
  }

  createPayload(): ItemPayload {
    const payload = this.itemForm.value;
    for (const key of Object.keys(payload)) {
      if (payload[key] === '') {
        payload[key] = null;
      }
    }

    const body = {
      stockTakingOrderId: this.stockTakingOrderId,
      round: this.round,
      unityid: payload.unityId,
      ...payload,
    };

    delete body.unityId;

    return body;
  }

  submit() {
    if (this.itemId) {
      this.addCountItem();
    } else {
      this.addItem();
    }
  }

  addCountItem() {
    this.inventoryService.addCountItem(this.createPayload(), this.stockTakingOrderId, this.itemId).subscribe(() => {
      this.closeModal();
    });
  }

  addItem() {
    this.inventoryService.addItem(this.createPayload()).subscribe(() => {
      this.closeModal();
    });
  }

  async getInfo(): Promise<void> {
    const pkgs = await this.packageService.list().pipe().toPromise();
    const untys = await this.unityService.list().pipe().toPromise();
    this.pkgs = pkgs.packages;
    this.unitys = untys;
    await this.checkIfHasAnotherComponentInfo();
    return;
  }

  async setValidators() {
    const value = this.itemForm.controls['registration'].value;
    if (value) {
      const lot = this.itemForm.get('lot');
      const packageId = this.itemForm.get('packageId');
      lot.setValidators([Validators.required]);
      packageId.setValidators([Validators.required]);
      lot.updateValueAndValidity();
      packageId.updateValueAndValidity();
    }

    if (!value) {
      const lot = this.itemForm.get('lot');
      const packageId = this.itemForm.get('packageId');
      lot.setValidators(null);
      packageId.setValidators(null);
      lot.updateValueAndValidity();
      packageId.updateValueAndValidity();
    }

    await this.setValidatorSticker();
  }

  async closeModal() {
    await this.modalCtrl.dismiss().catch((error) => {
      if (error.message === 'overlay does not exist trown') {
        return;
      }
    });
  }

  async setQrCodeValues(info: any) {
    let quantity = 0;
    let packageId: number;
    let unityId: number;
    if (typeof info.packageId === 'string') {
      const pkg = this.pkgs.find((pkg) => pkg.description.indexOf(info.packageId) !== -1);
      packageId = pkg ? pkg.id : null;
    } else {
      packageId = info.packageId;
    }

    if (info.unity && !info?.unityId) {
      const unt = this.unitys.find((unt) => unt.description.toLowerCase() === info.unity.toLowerCase());
      unityId = unt?.id;
    } else {
      unityId = info.unityId;
    }

    if (info?.quantity) {
      quantity = Number(info.quantity.replace('.', '').replace(',', '.'));
    }

    if (this.stickerNumber) {
      this.itemForm.patchValue({ stickerNumber: this.stickerNumber });
    } else {
      this.itemForm.patchValue({ stickerNumber: Number(info.stickerNumber) || this.itemForm.value.stickerNumber });
    }

    if (info.registration || this.itemForm.value.registration) {
      this.itemForm.patchValue({ quantity: quantity || this.itemForm.value.quantity });
    }

    this.itemForm.patchValue({ productCode: info.productCode || this.itemForm.value.productCode });
    this.itemForm.patchValue({ productDescription: info.productDescription || this.itemForm.value.productDescription });
    this.itemForm.patchValue({ registration: info.registration || this.itemForm.value.registration });
    this.itemForm.patchValue({ lot: info.lot || this.itemForm.value.lot });
    this.itemForm.patchValue({ unityId: unityId || this.itemForm.value.unityId });
    this.itemForm.patchValue({ packageId: packageId || this.itemForm.value.packageId });
    this.ionSelectUnityValue = unityId || this.itemForm.value.unityId;
    this.ionSelectPackageValue = packageId || this.itemForm.value.packageId;
    await this.setValidators();
  }

  selectPackage(packageDescription: string): void {
    const acond: IInventoryPackage = this.pkgs.find((pkg) => pkg.description.indexOf(packageDescription) !== -1);
    if (acond) {
      this.ionSelectPackageValue = acond?.id;
      this.itemForm.patchValue({ packageId: acond?.id });
    }
  }

  selectUnity(unityDescription: string): void {
    const unity: IUnity = this.unitys.find((unt) => unt.description.indexOf(unityDescription) !== -1);
    if (unity) {
      this.ionSelectUnityValue = unity?.id;
      this.itemForm.patchValue({ unityId: unity?.id });
    }
  }

  resetForm(): void {
    this.itemForm.reset();
    this.ionSelectPackageValue = null;
    this.ionSelectUnityValue = null;
  }

  async setValidatorSticker(): Promise<void> {
    const stiker = this.itemForm.controls['stickerNumber'].value;
    this.stickers = await this.getStickerNumber(this.stockTakingOrderId, this.round);
    const item = this.stickers['stickernumber'].find((item) => item.sticker === Number(stiker));

    if (item) {
      const stk = this.itemForm.get('stickerNumber');
      stk.setErrors({ incorrect: true });
    }
  }

  async getStickerNumber(id: number, round: number): Promise<StickerNumber> {
    const result = await this.inventoryService.getStickerNumber(id, round).pipe().toPromise();
    return result;
  }
}
