import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RecipesPageRoutingModule } from './recipes-routing.module';

import { RecipesPage } from './recipes.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { SubstepsService } from 'src/app/services/substep/substep.service';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RecipesPageRoutingModule, ComponentsModule],
  declarations: [RecipesPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SubstepsService],
})
export class RecipesPageModule {}
