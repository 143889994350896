import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { genericPrint } from 'src/app/models/genericPrint.model';
import { SubstepsService } from 'src/app/services/substep/substep.service';

@Component({
  selector: 'app-capa-tag-generic',
  templateUrl: './capa-tag-generic.component.html',
  styleUrls: ['./capa-tag-generic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapaTagGenericComponent implements OnInit {
  @Input() productionOrderId: number;
  @Input() substepId: number;
  @Input() productionOrdersSubstepId: number;
  @Input() callWindowPrint = false;
  tagToPrint: genericPrint;
  qrCodeValue = '';
  barCodeValue = '';
  anatelId = '';
  constructor(private substepService: SubstepsService) {}

  ngOnInit() {
    if (this.callWindowPrint) {
      setTimeout(() => {
        window.print();
      }, 3000);
    }
    this.loadInfoToPrint();
  }

  loadInfoToPrint() {
    this.substepService
      .getPrint(this.productionOrderId, this.substepId, this.productionOrdersSubstepId)
      .subscribe(async (print) => {
        this.tagToPrint = print;
        this.qrCodeValue = this.getQrCodeValue();
        this.barCodeValue = this.getBarCodeValue();
        this.anatelId = this.getAnatelId();
        this.tagToPrint.rows = this.tagToPrint.rows.filter((row) => this.isBodyContent(row.columns[0]));
        this.truncObsText();
      });
  }

  setSize(length: number): number {
    if (length === 1) {
      return 11;
    } else {
      return 3;
    }
  }

  isLastLine(row: any): boolean {
    const lastIndex = this.tagToPrint.rows.length - 1;
    return row === this.tagToPrint.rows[lastIndex];
  }

  isBodyContent(firstContent: any): boolean {
    return !firstContent.isQrCode && !firstContent.isBarCode && !firstContent.isAnatelLogo;
  }

  getQrCodeValue(): any {
    let qrCodeValueFetched = null;
    this.tagToPrint.rows.forEach((row) => {
      if (row.columns[0].isQrCode) {
        qrCodeValueFetched = row.columns[0].value;
      }
    });

    return qrCodeValueFetched;
  }

  getBarCodeValue(): any {
    let barCodeValueFetched = null;
    this.tagToPrint.rows.forEach((row) => {
      if (row.columns[0].isBarCode) {
        barCodeValueFetched = row.columns[0].value;
      }
    });

    return barCodeValueFetched;
  }

  getAnatelId(): string {
    let anatelIdFetched = 'N/A';
    this.tagToPrint.rows.forEach((row) => {
      if (row.columns[0].isAnatelLogo && row.columns[0].value) {
        anatelIdFetched = row.columns[0]?.value.toString();
      }
    });

    return anatelIdFetched;
  }

  truncObsText() {
    this.tagToPrint.rows.forEach((row) => {
      if (row.columns[0].label === 'OBS.:') {
        row.columns[0].value = String(row.columns[0]?.value).substring(0, 20);
      }
    });
  }
}
