import { ComponentsModule } from 'src/app/components/components.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StepDetailsPageRoutingModule } from './step-details-routing.module';

import { StepDetailsPage } from './step-details.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, StepDetailsPageRoutingModule, ComponentsModule],
  declarations: [StepDetailsPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StepDetailsPageModule {}
