import { Component, OnInit, Input } from '@angular/core';
import { Item } from 'src/app/models/item.model';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StockService } from 'src/app/services/stock/stock.service';
import { DropsService } from 'src/app/services/drops/drops.service';

@Component({
  selector: 'app-paint-list',
  templateUrl: './paint-list.page.html',
  styleUrls: ['./paint-list.page.scss'],
})
export class PaintListPage implements OnInit {
  paintings: any;
  @Input() productionOrderId: number;
  @Input() color: any;
  @Input() type: any;
  @Input() sliceRequestLength: number;
  paintingsSelected: any[];
  isLoading: boolean;
  totalLengthSelectedPaintings: number;

  constructor(
    private router: Router,
    private modalController: ModalController,
    public stockService: StockService,
    public dropsService: DropsService,
  ) {
    this.isLoading = true;
    this.totalLengthSelectedPaintings = 0;
  }

  ngOnInit() {
    if (this.sliceRequestLength) {
      this.stockService
        .getGenericPaintingsAvailable(this.color.id, this.type.id, this.sliceRequestLength)
        .subscribe((paintings) => (this.paintings = paintings));
    } else {
      this.stockService
        .getPaintingsAvailable(this.color.id, this.type.id)
        .subscribe((paintings) => (this.paintings = paintings));
    }
    this.paintingsSelected = new Array<Item>();
    this.isLoading = false;
  }

  submit() {
    this.dropsService.addPaintings(this.productionOrderId, this.paintingsSelected).subscribe((_) => {
      this.closeModal(this.productionOrderId);
    });
  }

  closeModal(productionOrderId) {
    this.modalController.dismiss(productionOrderId);
  }

  changeToggle(painting: any) {
    let positionPaintingToRemove = -1;
    this.paintingsSelected.filter((paintingSelected, index) => {
      if (paintingSelected.paintingId === painting.paintingId) {
        positionPaintingToRemove = index;
        return true;
      }
      return false;
    });

    if (positionPaintingToRemove > -1) {
      this.totalLengthSelectedPaintings -= painting.length;
      this.paintingsSelected.splice(positionPaintingToRemove, 1);
    } else {
      this.totalLengthSelectedPaintings += painting.length;
      this.paintingsSelected.push(painting);
    }
  }
}
